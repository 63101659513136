import { useContext, useEffect } from 'react'
import { SecurityContext } from '../context/security-context'
import {
  ApolloQueryResult,
  LazyQueryResult,
  useLazyQuery as useApolloLazyQuery,
  useQuery as useApolloQuery,
} from '@apollo/client'
import { DocumentNode } from 'graphql'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { OperationVariables } from '@apollo/client/core/types'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { AlertManager } from '../context/alert-manager'
import { OrganizationContext } from '../context/organization-context'
import { ProjectContext } from '../context/project-context'
import { omitUndefined } from '../utils/utils'

type UseQueryResult<T, Variables> = {
  loading: boolean
  data: T | undefined
  refetch: (variables?: Partial<Variables>) => Promise<ApolloQueryResult<T>>
  error?: Error
}

type UseQuery = <
  Data = any,
  Variables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<Data, Variables>,
  options?: QueryHookOptions<Data, Variables>,
) => UseQueryResult<Data, Variables>

const useQuery: UseQuery = (query, options?) => {
  const { token } = useContext(SecurityContext)
  const { membership } = useContext(OrganizationContext)
  const { project } = useContext(ProjectContext)
  const { handleError } = useContext(AlertManager)
  const { loading, data, error, refetch } = useApolloQuery(query, {
    ...options,
    notifyOnNetworkStatusChange: true,
    context: {
      headers: omitUndefined({
        'x-auth': token ? `Bearer ${token}` : undefined,
        'x-org-id': membership?.organization?.id,
        'x-project-id': project?.id,
      }),
    },
  })

  useEffect(() => {
    if (error) handleError(error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return { loading, data, refetch, error }
}

type UseLazyQuery = <
  Data = any,
  Variables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<Data, Variables>,
  options?: QueryHookOptions<Data, Variables>,
) => [
  (variables: Variables) => Promise<LazyQueryResult<Data, Variables>>,
  UseQueryResult<Data, Variables>,
]

export const useLazyQuery: UseLazyQuery = (query, options?) => {
  const { token } = useContext(SecurityContext)
  const { membership } = useContext(OrganizationContext)
  const { project } = useContext(ProjectContext)
  const { handleError } = useContext(AlertManager)
  const [fetch, { loading, data, error, refetch }] = useApolloLazyQuery(query, {
    ...options,
    notifyOnNetworkStatusChange: true,
    context: {
      headers: omitUndefined({
        'x-auth': token ? `Bearer ${token}` : undefined,
        'x-org-id': membership?.organization?.id,
        'x-project-id': project?.id,
      }),
    },
  })
  useEffect(() => {
    if (error) handleError(error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return [fetch, { loading, data, refetch, error }]
}

export default useQuery
