import React, { useContext } from 'react'
import styled from 'styled-components'
import { Col, Empty, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import Button from '../atoms/button'
import TimeLabel from '../molecules/time-label'
import { ToolExecutionStatuses } from '../../types/tool'
import { useLatestToolExecutions } from '../../hooks/tool/useToolExecution'
import {
  CancelledIcon,
  FailedIcon,
  InProgressIcon,
  PopoutIcon,
  SuccessIcon,
} from '../atoms/icon'
import { ProjectContext } from '../../context/project-context'
import Panel from './panel'

const Container = styled(Panel)`
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  gap: 24px;
`

const ToolExecutionCard = styled.a`
  display: block;
  padding: 14px 0;
  transition: padding 0.3s ease;
  border-bottom: 1px solid #242735;
  :hover {
    border-bottom: 1px solid #242735;
    background: rgba(137, 150, 218, 0.1);
    padding: 14px 4px;
    cursor: pointer;
  }
`

const noExecutions = (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description="Launch a tool, it will appear here"
  />
)

export const iconByStatus: Record<ToolExecutionStatuses, React.ReactNode> = {
  [ToolExecutionStatuses.Completed]: <SuccessIcon />,
  [ToolExecutionStatuses.Failed]: <FailedIcon />,
  [ToolExecutionStatuses.Queued]: <InProgressIcon />,
  [ToolExecutionStatuses.Running]: <InProgressIcon />,
  [ToolExecutionStatuses.Cancelled]: <CancelledIcon />,
}

const ToolExecutionList: React.FC = () => {
  const { loading, executions } = useLatestToolExecutions(10)
  const { project } = useContext(ProjectContext)

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={4}>Used in project</Title>
        </Col>
        <Col>
          {!loading && executions.length > 0 && (
            <Button
              analyticsId="used_in_project_view_activity"
              icon={<PopoutIcon style={{ fontSize: 20 }} />}
              noBackground
              href={`/tools/activity?pro=${project?.id}&too=${executions[0].tool.id}`}
            />
          )}
        </Col>
      </Row>
      <div>
        {executions.length > 0
          ? executions.map(execution => (
              <ToolExecutionCard
                key={execution.id}
                href={`/tools/activity?pro=${project?.id}&too=${execution.tool.id}&tei=${execution.id}`}
              >
                <Row gutter={16}>
                  <Col span={2}>{iconByStatus[execution.status]}</Col>
                  <Col span={22}>
                    <Title level={4}>{execution.tool.name}</Title>
                    <TimeLabel timestamp={execution.updatedAt} />
                  </Col>
                </Row>
              </ToolExecutionCard>
            ))
          : noExecutions}
      </div>
    </Container>
  )
}
export default ToolExecutionList
