export const noop = () => {}

export const encodeParams = (params: Record<string, any>) =>
  Object.entries(params)
    .filter(([_, value]) => !!value || typeof value === 'boolean')
    .map(([key, value]) => [
      key,
      Array.isArray(value) ? value.join(',') : value,
    ])
    .map(([key, value]) => [key, value].map(encodeURIComponent).join('='))
    .join('&')

export const capitalize = (val: string) =>
  val.charAt(0).toUpperCase() + val.slice(1)

export const humanize = (val: string) => capitalize(val).replaceAll('_', ' ')

export const extensionFrom = (file: File) => file.name.split('.').pop()

export const omitUndefined = (obj: any): any =>
  Object.entries(obj)
    .filter(([_, v]) => v !== undefined)
    .reduce((toObject, [k, v]) => ({ ...toObject, [k]: v }), {})
