import { gql } from '@apollo/client'
import useMutation from '../useMutation'
import { Page } from '../../types/pageable'
import { ToolExecution, ToolExecutionFilter } from '../../types/tool'
import useQuery, { useLazyQuery } from '../useQuery'
import { useEffect, useState } from 'react'

const createToolExecutionMutation = gql`
  mutation CreateToolExecution(
    $toolId: String!
    $input: String!
    $name: String
    $parentExecutionId: String
  ) {
    createToolExecution(
      toolId: $toolId
      input: $input
      name: $name
      parentExecutionId: $parentExecutionId
    ) {
      id
      name
      status
      toolId
      input
      output
      parentToolExecutionId
      createdBy
      createdAt
      updatedAt
    }
  }
`

const latestToolExecutions = gql`
  query latestToolExecutions($limit: Int!) {
    latestToolExecutions(limit: $limit) {
      id
      tool {
        name
        id
      }
      status
      updatedAt
    }
  }
`

const toolExecutions = gql`
  query ToolExecutions($filter: ToolExecutionsFilterInput!, $toolId: String!) {
    toolExecutions(filter: $filter, toolId: $toolId) {
      data {
        id
        name
        status
        toolId
        input
        output
        parentToolExecutionId
        createdBy
        createdAt
        updatedAt
      }
      previous
      next
    }
  }
`

export const useCreateToolExecution = () => {
  const [mutate] = useMutation<{ createToolExecution: ToolExecution }>(
    createToolExecutionMutation,
  )

  return (
    toolId: string,
    input: Record<string, unknown> | string,
    name?: string,
    parentExecutionId?: string,
  ) => {
    const finalInput = typeof input === 'string' ? input : JSON.stringify(input)
    return mutate({
      variables: {
        toolId: toolId,
        input: finalInput,
        name: name !== '' ? name : undefined,
        parentExecutionId: parentExecutionId,
      },
    }).then(result => result.data!.createToolExecution)
  }
}

export const useLatestToolExecutions = (limit: number) => {
  const { loading, data, refetch } = useQuery<{
    latestToolExecutions: ToolExecution[]
  }>(latestToolExecutions, { variables: { limit } })

  return {
    loading,
    executions: data?.latestToolExecutions ?? [],
    refetch,
  }
}

export const useToolExecutions = (
  toolId?: string,
  filter: ToolExecutionFilter = {},
) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Page<ToolExecution>>()
  const [fetch] = useLazyQuery<{ toolExecutions: Page<ToolExecution> }>(
    toolExecutions,
  )

  const refetch = () => {
    setLoading(true)
    fetch({ variables: { filter, toolId } })
      .then(res => setData(res.data?.toolExecutions))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (toolId) refetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolId, JSON.stringify(filter)])

  return { loading, page: data, refetch }
}
