import React from 'react'
import { Redirect } from 'react-router-dom'
import { RouteDefinition } from './route-helper'
import { BoxIcon, GearIcon } from '../components/atoms/icon'
import OrganizationSettingsPage from '../components/pages/organization-settings'
import { AlertManagerProvider } from '../context/alert-manager'
import LoginPage from '../components/pages/login'
import OrganizationIdentity from '../components/pages/organization-identity'
import OrganizationMembers from '../components/pages/organization-members'
import OrganizationMemberDetailsPage from '../components/pages/organization-member-details'
import OrganizationConnections from '../components/pages/organization-connections'
import OrganizationNewConnectionPage from '../components/pages/organization-connection-new'
import OrganizationConnectionDetailsPage from '../components/pages/organization-connection-details'
import OrganizationNewConnectionAwsPage from '../components/pages/organization-connection-new-aws'
import OrganizationNewConnectionGcpPage from '../components/pages/organization-connection-new-gcp'
import InvitePage from '../components/pages/invite'
import NotFoundPage from '../components/pages/not-found'
import OrganizationProjectEdit from '../components/pages/organization-projects-edit'
import OrganizationProjects from '../components/pages/organization-projects'
import OrganizationNewProjectPage from '../components/pages/organization-projects-new'
import ToolsPage from '../components/pages/tool-list'
import ToolExecutionsActivity from '../components/pages/tool-execution-activity'
import { shouldHaveOneOf } from './guard/role-aware-guard'
import { Role } from '../types/role'
import OrganizationBilling from '../components/pages/organization-billing'

export const InvitationsRoute: RouteDefinition = {
  path: '/invitations',
  component: NotFoundPage,
  children: [{ path: '/:id', component: InvitePage }],
}

export const OrganizationSettingsSubRoutes: RouteDefinition[] = [
  {
    path: '/overview',
    label: 'Overview',
    component: OrganizationIdentity,
  },
  {
    path: '/projects',
    label: 'Projects',
    component: OrganizationProjects,
    children: [
      {
        path: '/new',
        component: OrganizationNewProjectPage,
      },
      { path: '/:id', component: OrganizationProjectEdit },
    ],
  },
  {
    path: '/members',
    label: 'Members',
    component: OrganizationMembers,
    children: [{ path: '/:id', component: OrganizationMemberDetailsPage }],
  },
  {
    path: '/connections',
    label: 'Connections',
    component: OrganizationConnections,
    children: [
      {
        path: '/new',
        component: OrganizationNewConnectionPage,
      },
      {
        path: '/aws',
        component: OrganizationNewConnectionAwsPage,
      },
      {
        path: '/gcp',
        component: OrganizationNewConnectionGcpPage,
      },
      { path: '/:id', component: OrganizationConnectionDetailsPage },
    ],
  },
  {
    path: '/billing',
    label: 'Billing',
    component: OrganizationBilling,
  },
]

export const MainRoutes: RouteDefinition[] = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/tools" />,
  },
  {
    path: '/tools',
    label: 'Toolbox',
    icon: BoxIcon,
    component: ToolsPage,
    children: [{ path: '/activity', component: ToolExecutionsActivity }],
  },
  {
    path: '/organization',
    label: 'Organization',
    icon: GearIcon,
    component: OrganizationSettingsPage,
    guard: shouldHaveOneOf(Role.Owner, Role.Admin),
    children: OrganizationSettingsSubRoutes.map(route => ({
      ...route,
      nested: true,
    })),
  },
]

export const AuthenticationRoutes: RouteDefinition[] = [
  {
    path: '',
    component: () => (
      <AlertManagerProvider overlay={true}>
        <LoginPage />
      </AlertManagerProvider>
    ),
  },
]
