import { useContext, useEffect } from 'react'
import { SecurityContext } from '../context/security-context'
import {
  DefaultContext,
  useMutation as useApolloMutation,
} from '@apollo/client'
import { DocumentNode } from 'graphql'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import { FetchResult } from '@apollo/client/link/core'
import { MutationOptions } from '@apollo/client/core/watchQueryOptions'
import { OperationVariables } from '@apollo/client/core/types'
import { AlertManager } from '../context/alert-manager'
import { OrganizationContext } from '../context/organization-context'
import { ProjectContext } from '../context/project-context'
import { omitUndefined } from '../utils/utils'

type MutateCallback<Data, Variables> = (
  options?: MutationFunctionOptions<Data, Variables>,
) => Promise<FetchResult<Data>>

type UseMutationHelper<Data, Variables> = [
  MutateCallback<Data, Variables>,
  boolean,
]

type UseMutation = <Data = any, Variables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<Data, Variables>,
  options?: MutationOptions<Data, Variables>,
) => UseMutationHelper<Data, Variables>

const useMutation: UseMutation = (mutation, options?) => {
  const { token } = useContext(SecurityContext)
  const { membership } = useContext(OrganizationContext)
  const { project } = useContext(ProjectContext)
  const { handleError } = useContext(AlertManager)
  const [mutate, { loading, error }] = useApolloMutation(mutation, {
    ...options,
    context: {
      headers: omitUndefined({
        'x-auth': token ? `Bearer ${token}` : undefined,
        'x-org-id': membership?.organization?.id,
        'x-project-id': project?.id,
      }),
    } as DefaultContext,
  })
  useEffect(() => {
    if (error) handleError(error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return [mutate, loading]
}

export default useMutation
