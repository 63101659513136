import styled from 'styled-components'

export const TwoColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 48px;
`
export const TwoColumnsLeftContainer = styled.div`
  flex: 0.65;
`
export const TwoColumnsRightContainer = styled.div`
  flex: 0.35;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`
