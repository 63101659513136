import styled from 'styled-components'
import { Table as AntTable } from 'antd'

const Table = styled(AntTable)`
  tr.ant-table-placeholder {
    color: rgb(224, 222, 232);
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: rgba(0, 0, 0, 0.1);
  }
`

export default Table
