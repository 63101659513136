export type Media = {
  url: string
}

export type MediaCategory = 'default' | 'avatars'

export enum MediaCategories {
  Default = 'default',
  Avatar = 'avatars',
}
