import './App.less'
import React from 'react'
import { SecurityContextProvider } from './context/security-context'
import client from './graphql/apollo-client'
import { AppContextProvider } from './context/application-context'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import MainRouter from './routes/main-router'
import { AlertManagerProvider } from './context/alert-manager'
import initTranslations from './i18n'

initTranslations()

const App = () => (
  <AlertManagerProvider overlay={true}>
    <ApolloProvider client={client}>
      <AppContextProvider>
        <SecurityContextProvider>
          <BrowserRouter>
            <MainRouter />
          </BrowserRouter>
        </SecurityContextProvider>
      </AppContextProvider>
    </ApolloProvider>
  </AlertManagerProvider>
)

export default App
