import React from 'react'
import styled from 'styled-components'
import { ToolExecution, ToolExecutionStatuses } from '../../types/tool'
import { Col, Row } from 'antd'
import { RerunIcon } from '../atoms/icon'
import Title from 'antd/lib/typography/Title'

type Props = {
  execution: ToolExecution
  onClickRerun: (execution: ToolExecution) => void
}

const PopupAction = styled.div`
  :hover {
    opacity: 0.9;
    cursor: pointer;
  }
`

const ExecutionPopupContent: React.FC<Props> = ({
  execution,
  onClickRerun,
}) => {
  const rerunnable: boolean =
    execution.status === ToolExecutionStatuses.Completed ||
    execution.status === ToolExecutionStatuses.Failed ||
    execution.status === ToolExecutionStatuses.Cancelled

  return (
    <>
      {rerunnable ? (
        <PopupAction
          onClick={event => {
            event.stopPropagation()
            onClickRerun(execution)
          }}
        >
          <Row>
            <Col>
              <RerunIcon style={{ paddingRight: 8 }} />
            </Col>
            <Title level={5}>re-run tool</Title>
          </Row>
        </PopupAction>
      ) : (
        <Title level={5}>no action available</Title>
      )}
    </>
  )
}

export default ExecutionPopupContent
