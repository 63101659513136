import React, { PropsWithChildren, useState } from 'react'
import { capitalize, noop } from '../utils/utils'
import { Alert as AntAlert } from 'antd'
import useInterval from '../hooks/useInterval'
import styled from 'styled-components'
import { ErrorBoundary } from 'react-error-boundary'

type AlertType = 'info' | 'error' | 'warning'
type Context = {
  alert: (msg: string, type: AlertType) => void
  handleError: (error: Error) => undefined
  clear: () => void
}

export const AlertManager = React.createContext<Context>({
  alert: noop,
  handleError: (_: Error) => undefined,
  clear: noop,
})

type Props = PropsWithChildren & {
  overlay?: boolean
}
type FallbackComponentProps = {
  error: Error
  resetErrorBoundary: () => void
}

const AlertContainer = styled.div<{ overlay: boolean }>`
  width: 100%;
  position: ${props => (props.overlay ? 'absolute' : 'relative')};
  top: 0;
  z-index: 1000;
  padding: 0.25em;
`

export const AlertManagerProvider: React.FC<Props> = ({
  overlay,
  children,
}) => {
  const [message, setMessage] = useState<string>()
  const [type, setType] = useState<AlertType>()
  const open = !!message && !!type

  const alert = (msg: string, type: AlertType) => {
    setMessage(msg)
    setType(type)
  }
  const clear = () => {
    setMessage(undefined)
    setType(undefined)
  }
  const handleError = (error: Error) => {
    alert(error.message, 'error')
    return undefined
  }

  useInterval(clear, 20000)

  const context = { alert, clear, handleError }

  type AlertProps = {
    onClose: () => void
  }
  const Alert: React.FC<AlertProps> = ({ onClose }) => (
    <>
      {open && (
        <AlertContainer overlay={overlay ?? false}>
          <AntAlert
            message={`${capitalize(type)} - ${message}`}
            type={type}
            showIcon
            closable
            onClose={onClose}
          />
        </AlertContainer>
      )}
    </>
  )
  const FallbackComponent: React.FC<FallbackComponentProps> = ({
    error,
    resetErrorBoundary,
  }) => {
    handleError(error)
    return (
      <Alert
        onClose={() => {
          clear()
          resetErrorBoundary()
        }}
      />
    )
  }

  return (
    <AlertManager.Provider value={context}>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Alert onClose={clear} />
        {children}
      </ErrorBoundary>
    </AlertManager.Provider>
  )
}
