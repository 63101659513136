import styled from 'styled-components'
import { AcceptIcon, RejectIcon } from '../atoms/icon'
import { InputLabel } from '../atoms/input'
import { Space } from 'antd'
import Title from 'antd/lib/typography/Title'

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`

const ButtonContainer = styled.div`
  cursor: pointer;
  svg {
    font-size: 20px;
  }
`

type ButtonProps = {
  onClick: () => void
}

export const AcceptButton: React.FC<ButtonProps> = ({ onClick }) => (
  <ButtonContainer onClick={onClick}>
    <AcceptIcon />
  </ButtonContainer>
)

export const RejectButton: React.FC<ButtonProps> = ({ onClick }) => (
  <ButtonContainer onClick={onClick}>
    <RejectIcon />
  </ButtonContainer>
)

type IteractionLabelProp = {
  title: string
  valueUpdated?: boolean
  onAccept: () => void
  onCancel: () => void
  useLargeLabel?: boolean
}

const IterationLabel: React.FC<IteractionLabelProp> = ({
  title,
  valueUpdated,
  onAccept,
  onCancel,
  useLargeLabel = false,
}) => (
  <LabelContainer>
    {useLargeLabel ? (
      <Title level={3}>{title}</Title>
    ) : (
      <InputLabel>{title}</InputLabel>
    )}
    {valueUpdated && (
      <Space>
        <AcceptButton onClick={onAccept} />
        <RejectButton onClick={onCancel} />
      </Space>
    )}
  </LabelContainer>
)

export default IterationLabel
