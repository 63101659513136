import { useEffect, useLayoutEffect, useRef } from 'react'

const useInterval = (callback: () => void, delay: number | undefined) => {
  const savedCallback = useRef(callback)

  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!delay && delay !== 0) return

    const interval = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(interval)
  }, [delay])
}

export default useInterval
