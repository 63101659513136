import { message, Upload } from 'antd'
import { UploadChangeParam } from 'antd/es/upload'
import React from 'react'

type Props = {
  name: string
  label: string
  url?: string
  onUrlChange: (url: string) => void
  onFileChange: (file?: File) => void
  disabled?: boolean
}

const beforeUpload = (file: File) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('Only jpeg and png files are supported.')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

const getBase64 = (img: File, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

const ImageUpload: React.FC<Props> = ({
  name,
  label,
  url,
  onUrlChange,
  onFileChange,
  disabled,
}) => (
  <Upload
    name={name}
    listType="picture-card"
    showUploadList={false}
    beforeUpload={beforeUpload}
    onChange={(info: UploadChangeParam) => {
      getBase64(info.file.originFileObj as File, onUrlChange)
      onFileChange(info.file.originFileObj)
    }}
    disabled={disabled}
  >
    {url ? (
      <img
        src={url}
        alt="avatar"
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          borderRadius: '8px',
        }}
      />
    ) : (
      label
    )}
  </Upload>
)

export default ImageUpload
