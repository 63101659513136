import { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import { Link } from 'react-router-dom'
import React from 'react'
import { Breadcrumb as AntBreadcrumb } from 'antd'

type Props = {
  routes: Route[]
}
const itemRender = (
  route: Route,
  params: any,
  routes: Array<Route>,
  paths: Array<string>,
) => {
  const last = routes.indexOf(route) === routes.length - 1
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
  )
}

const Breadcrumb: React.FC<Props> = ({ routes }) => (
  <AntBreadcrumb itemRender={itemRender} routes={routes} />
)

export default Breadcrumb
