import i18n from 'i18next'

import { initReactI18next } from 'react-i18next'
import en from './en'

const initTranslations = () =>
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: { translation: en },
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: true,
      },
    })

export default initTranslations
