import React, { useState } from 'react'
import useInput from '../../hooks/useInput'
import { Page, PageContent, PageHeader } from '../templates/page'
import Breadcrumb from '../molecules/breadcrumb'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import styled from 'styled-components'
import { Col, Row, Space, Switch, Typography } from 'antd'
import { TerminalIcon } from '../atoms/icon'
import { t } from 'i18next'
import Button from '../atoms/button'
import Spacer from '../atoms/spacer'
import CodeBlock from '../atoms/codeblock'
import Header from '../atoms/header'
import {
  Input,
  InputLabel,
  InputLabelContainer,
  PasswordInput,
} from '../atoms/input'
import Select from '../molecules/select'
import { Trans } from 'react-i18next'
import { useBoolean } from '../../hooks/useBoolean'
import { useHistory } from 'react-router-dom'
import {
  useAwsRegions,
  useCreateConnection,
} from '../../hooks/organization/useConnections'
import { ConnectionProviders } from '../../types/connection'

const { Title, Paragraph, Link } = Typography

const Container = styled(PageContent)`
  height: auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  background: rgba(0, 0, 0, 0.25);
`

const AWSInstructions = () => (
  <Typography>
    <Title level={2}>{t('aws_new_step_one')}</Title>
    <Spacer size="small" />
    <Paragraph>
      <Trans
        i18nKey="aws_new_signup"
        components={{
          1: <Link href="https://aws.amazon.com/free/" target="_blank" />,
        }}
      />
    </Paragraph>
    <Paragraph>
      <Trans
        i18nKey="aws_new_management_console"
        components={{
          1: <Link href="https://aws.amazon.com/console/" target="_blank" />,
        }}
      />
    </Paragraph>
    <Spacer size="medium" />
    <Title level={2}>{t('aws_new_step_two')}</Title>
    <Spacer size="small" />
    <ul>
      <li>
        <Trans
          i18nKey="aws_new_iam"
          components={{
            1: (
              <Link
                href="https://console.aws.amazon.com/iam/"
                target="_blank"
              />
            ),
          }}
        />
      </li>
      <li>{t('aws_new_navigate_users')}</li>
      <li>{t('aws_new_add_user')}</li>
      <li>{t('aws_new_username')}</li>
      <li>{t('aws_new_permissions')}</li>
      <li>{t('aws_new_add_permissions')}</li>
      <li>
        {t('aws_new_admin_access')}
        <ul>
          <li>{t('aws_new_admin_provision')}</li>
          <li>{t('aws_new_admin_deploy')}</li>
        </ul>
      </li>
      <li>{t('aws_new_tags')}</li>
      <li>{t('aws_new_create_user')}</li>
    </ul>
    <Spacer size="medium" />
    <Title level={2}>{t('aws_new_step_three')}</Title>
    <Spacer size="small" />
    <ul>
      <li>{t('aws_new_account')}</li>
      <li>{t('aws_new_security_credentials')}</li>
      <li>{t('aws_new_access_key')}</li>
      <li>{t('aws_new_access_key_practices')}</li>
      <li>{t('aws_new_access_key_tags')}</li>
      <li>{t('aws_new_access_key_confirmation')}</li>
      <li>{t('aws_new_download_access_key')}</li>
    </ul>
  </Typography>
)

const TerminalMode = () => {
  const script = `#!/bin/bash
# Set variables
USER_NAME="cogo-service-account"
POLICY_ARN="arn:aws:iam::aws:policy/AdministratorAccess"

# Create IAM user
aws iam create-user --user-name "$USER_NAME"

# Attach policies to the user
aws iam attach-user-policy --user-name "$USER_NAME" --policy-arn "$POLICY_ARN"

# Create access keys for the user
ACCESS_KEYS=$(aws iam create-access-key --user-name "$USER_NAME")

# Extract the access key details
echo "$ACCESS_KEYS" | jq -r '.AccessKey.AccessKeyId'
echo "$ACCESS_KEYS" | jq -r '.AccessKey.SecretAccessKey'`

  return <CodeBlock>{script}</CodeBlock>
}

const OrganizationNewConnectionAwsPage = () => {
  useDocumentTitle('Settings - New connection - AWS')

  const { data } = useAwsRegions()
  const [isTerminal, toggleIsTerminal] = useBoolean()
  const [name, setName] = useInput<string>('')
  const [accessKey, setAccessKey] = useInput<string>('')
  const [secretAccessKey, setSecretAccessKey] = useInput<string>('')
  const [region, setRegion] = useState('us-east-1')
  const createConnection = useCreateConnection()
  const { push } = useHistory()

  const regionOptions = data?.map(region => ({
    name: region,
    value: region,
  }))

  const canCreate = name && accessKey && secretAccessKey && region

  const create = () => {
    createConnection({
      name: name!,
      provider: ConnectionProviders.Aws,
      credentials: JSON.stringify({
        region,
        accessKey,
        secretAccessKey,
      }),
    }).then(result => {
      if (result) push(`/organization/connections/${result.id}`)
    })
  }

  return (
    <Page>
      <PageHeader>
        <Breadcrumb
          routes={[
            { path: '../', breadcrumbName: 'Organization' },
            {
              path: '../connections',
              breadcrumbName: 'Connections',
            },
            { path: '/', breadcrumbName: 'Amazon Web Services' },
          ]}
        />
      </PageHeader>
      <Container>
        <Header>
          <Space direction="horizontal" align="center">
            <TerminalIcon
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'whitesmoke',
                fontSize: '24px',
              }}
            />
            <Switch onChange={toggleIsTerminal} />
          </Space>
          <Button
            analyticsId={`create_connection_${ConnectionProviders.Aws}`}
            label="create"
            onClick={create}
            disabled={!canCreate}
          />
        </Header>
        <Spacer size="small" />
        {isTerminal ? <TerminalMode /> : <AWSInstructions />}
        <Space direction="vertical">
          <Row gutter={[16, 16]} style={{ width: '100%' }}>
            <Col span={12}>
              <InputLabelContainer>
                <InputLabel>Name</InputLabel>
                <Input
                  value={name}
                  placeholder="AWS Connection"
                  onChange={setName}
                  required
                />
              </InputLabelContainer>
            </Col>
            <Col span={6}>
              <InputLabelContainer>
                <InputLabel>Region</InputLabel>
                <Select
                  value={region}
                  options={regionOptions}
                  onChange={val => {
                    setRegion(val as string)
                  }}
                />
              </InputLabelContainer>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ width: '100%' }}>
            <Col span={12}>
              <InputLabelContainer>
                <InputLabel>Access Key ID</InputLabel>
                <Input
                  value={accessKey}
                  placeholder="ABCDEFGHIJKLMNOPQRST"
                  onChange={setAccessKey}
                  required
                />
              </InputLabelContainer>
            </Col>
            <Col span={12}>
              <InputLabelContainer>
                <InputLabel>Secret Access Key</InputLabel>
                <PasswordInput
                  value={secretAccessKey}
                  placeholder="abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ1234"
                  onChange={setSecretAccessKey}
                  required
                />
              </InputLabelContainer>
            </Col>
          </Row>
        </Space>
      </Container>
    </Page>
  )
}

export default OrganizationNewConnectionAwsPage
