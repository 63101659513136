import React, { useEffect, useState } from 'react'
import { Page, PageContent, PageHeader } from '../templates/page'
import { useHistory, useParams } from 'react-router-dom'
import Breadcrumb from '../molecules/breadcrumb'
import {
  useArchiveConnection,
  useAwsRegions,
  useConnectionById,
  useEditConnection,
} from '../../hooks/organization/useConnections'
import { useBoolean } from '../../hooks/useBoolean'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import Button from '../atoms/button'
import { Col, Row, Space } from 'antd'
import DeleteButton from '../atoms/delete-button'
import Select from '../molecules/select'
import {
  TwoColumnsContainer,
  TwoColumnsLeftContainer,
  TwoColumnsRightContainer,
} from '../molecules/two-columns'
import {
  Input,
  InputLabel,
  InputLabelContainer,
  PasswordInput,
} from '../atoms/input'
import { Label, LabelValueContainer } from '../atoms/typography'
import Panel from '../organism/panel'
import {
  AwsCredentials,
  ConnectionProvider,
  ConnectionProviders,
  OrganizationConnection,
} from '../../types/connection'
import { formatDateAtTime } from '../../utils/time'
import { isEqual } from 'lodash'

type Props = {
  connection?: OrganizationConnection
  setConnection: React.Dispatch<
    React.SetStateAction<OrganizationConnection | undefined>
  >
}

const AwsInputPanel: React.FC<Props> = ({ connection, setConnection }) => {
  const { data } = useAwsRegions()
  const regionOptions = data?.map(region => ({
    name: region,
    value: region,
  }))
  const credentials: AwsCredentials = JSON.parse(connection?.credentials!)

  return (
    <Panel>
      <Row gutter={12}>
        <Col span={12}>
          <InputLabelContainer>
            <InputLabel>Name</InputLabel>
            <Input
              value={connection?.name}
              placeholder="Organization Connection"
              onChange={e => {
                setConnection({
                  ...connection!,
                  name: e.target.value,
                })
              }}
              allowClear
              required
            />
          </InputLabelContainer>
        </Col>
        <Col span={12}>
          <InputLabelContainer>
            <InputLabel>Region</InputLabel>
            <Select
              value={credentials.region}
              options={regionOptions}
              onChange={val => {
                setConnection({
                  ...connection!,
                  credentials: JSON.stringify({
                    ...credentials,
                    region: val as string,
                  }),
                })
              }}
            />
          </InputLabelContainer>
        </Col>
      </Row>
      <InputLabelContainer>
        <InputLabel>Access Key ID</InputLabel>
        <PasswordInput
          value={credentials.accessKey}
          placeholder="ABCDEFGHIJKLMNOPQRST"
          onChange={e => {
            setConnection({
              ...connection!,
              credentials: JSON.stringify({
                ...credentials,
                accessKey: e.target.value,
              }),
            })
          }}
          allowClear
          required
        />
      </InputLabelContainer>
      <InputLabelContainer>
        <InputLabel>Secret Access Key</InputLabel>
        <PasswordInput
          value={credentials.secretAccessKey}
          placeholder="abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ1234"
          onChange={e => {
            setConnection({
              ...connection!,
              credentials: JSON.stringify({
                ...credentials,
                secretAccessKey: e.target.value,
              }),
            })
          }}
          allowClear
          required
        />
      </InputLabelContainer>
    </Panel>
  )
}

const GithubInputPanel: React.FC<Props> = ({ connection, setConnection }) => {
  return (
    <Panel>
      <InputLabelContainer>
        <InputLabel>Name</InputLabel>
        <Input
          value={connection?.name}
          placeholder="Organization Connection"
          onChange={e => {
            setConnection({
              ...connection!,
              name: e.target.value,
            })
          }}
          allowClear
          required
        />
      </InputLabelContainer>
    </Panel>
  )
}

const GcpInputPanel: React.FC<Props> = ({ connection, setConnection }) => {
  return (
    <Panel>
      <InputLabelContainer>
        <InputLabel>Name</InputLabel>
        <Input
          value={connection?.name}
          placeholder="Organization Connection"
          onChange={e => {
            setConnection({
              ...connection!,
              name: e.target.value,
            })
          }}
          allowClear
          required
        />
      </InputLabelContainer>
      <InputLabelContainer>
        <InputLabel>Credentials</InputLabel>
        <PasswordInput
          value={connection?.credentials}
          placeholder="Google Cloud Credentials"
          onChange={e => {
            setConnection({
              ...connection!,
              credentials: e.target.value,
            })
          }}
          allowClear
          required
        />
      </InputLabelContainer>
    </Panel>
  )
}

const OrganizationConnectionDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const { loading, data, refetch } = useConnectionById(id)
  const [connection, setConnection] = useState<OrganizationConnection>()
  const [xhrPending, toggleXhrPending] = useBoolean()
  const edit = useEditConnection()
  const archiveConnection = useArchiveConnection()
  const { replace } = useHistory()
  useDocumentTitle(`Settings - Connections - ${data?.name ?? id}`, {
    skip: !data,
  })

  const connectionData =
    data?.provider === ConnectionProviders.Gcp ||
    data?.provider === ConnectionProviders.Github
      ? {
          ...data,
          credentials: window.atob(data.credentials),
        }
      : data

  useEffect(() => {
    if (data) {
      setConnection(connectionData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const canSave = !xhrPending && !isEqual(connection, connectionData)

  const save = () => {
    if (canSave) {
      toggleXhrPending()
      edit({
        id,
        name: connection?.name,
        credentials: connection?.credentials,
      })
        .then(refetch)
        .finally(toggleXhrPending)
    }
  }

  const archive = () => {
    if (
      window.confirm(
        `Do you really want to archive connection ${data?.name}? This cannot be undone.`,
      )
    )
      archiveConnection(id).then(result => {
        if (result) replace('/organization/connections')
      })
  }

  const ProviderPanels: Record<ConnectionProvider, React.ReactNode> = {
    GITHUB: (
      <GithubInputPanel connection={connection} setConnection={setConnection} />
    ),
    AWS: (
      <AwsInputPanel connection={connection} setConnection={setConnection} />
    ),
    GCP: (
      <GcpInputPanel connection={connection} setConnection={setConnection} />
    ),
  }

  return (
    <Page xhrPending={xhrPending || loading}>
      <PageHeader>
        <Breadcrumb
          routes={[
            { path: '../../organization', breadcrumbName: 'Organization' },
            { path: '/connections', breadcrumbName: 'Connections' },
            { path: id, breadcrumbName: data?.name ?? id },
          ]}
        />
        <Space direction="horizontal" align="end" size="large">
          <DeleteButton onClick={archive} />
          <Button
            analyticsId={`update_connection_${connection?.provider}`}
            label="save"
            onClick={save}
            disabled={!canSave}
          />
        </Space>
      </PageHeader>
      <PageContent>
        <TwoColumnsContainer>
          <TwoColumnsLeftContainer>
            {ProviderPanels[connection?.provider!]}
          </TwoColumnsLeftContainer>
          <TwoColumnsRightContainer>
            <Panel>
              <LabelValueContainer>
                <Label>Status</Label>
                <p>{connection?.status}</p>
              </LabelValueContainer>
              <LabelValueContainer>
                <Label>Created</Label>
                <p>{formatDateAtTime(connection?.createdAt!)}</p>
              </LabelValueContainer>
              <LabelValueContainer>
                <Label>Updated</Label>
                <p>{formatDateAtTime(connection?.updatedAt!)}</p>
              </LabelValueContainer>
            </Panel>
          </TwoColumnsRightContainer>
        </TwoColumnsContainer>
      </PageContent>
    </Page>
  )
}

export default OrganizationConnectionDetailsPage
