import React, { useEffect, useState } from 'react'
import useDebounce from '../../hooks/useDebounce'
import { useQueryParam } from '../../hooks/useQueryParam'
import {
  ConnectionProvider,
  ConnectionProviders,
  ConnectionStatus,
  ConnectionStatuses,
  OrganizationConnection,
} from '../../types/connection'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import Spacer from '../atoms/spacer'
import Loading from '../atoms/loading'
import Button from '../atoms/button'
import { Space, Typography } from 'antd'
import { Input } from '../atoms/input'
import { SearchOutlined } from '@ant-design/icons'
import Select from '../molecules/select'
import { humanize } from '../../utils/utils'
import Table from '../organism/table'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useHistory } from 'react-router-dom'
import { useOrganizationConnections } from '../../hooks/organization/useConnections'
import moment from 'moment-timezone'
import { ProviderIcons } from '../atoms/icon'
import styled from 'styled-components'
import Header from '../atoms/header'

const TableIcon = styled.div`
  svg {
    font-size: 2.5rem;
    color: whitesmoke;
  }
`

const OrganizationConnections = () => {
  const { push } = useHistory()
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce<string>(query, 300)
  const [after, setAfter] = useState<number>()
  const [provider, setProvider] = useQueryParam<ConnectionProvider>('pro')
  const [status, setStatus] = useQueryParam<ConnectionStatus>(
    'sta',
    ConnectionStatuses.Active,
  )
  const [connections, setConnections] = useState<OrganizationConnection[]>([])

  useDocumentTitle('Settings - Connections')
  const { loading, page } = useOrganizationConnections({
    query: debouncedQuery,
    status,
    provider,
    after,
  })
  const hasNextPage = (page?.next ?? 0) > 0
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setAfter(page?.next),
    disabled: !loading && !page,
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    if (page) {
      const data = page?.data ?? []
      setConnections(prev => (after ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    setAfter(undefined)
  }, [debouncedQuery, status, provider])

  return (
    <>
      <Header>
        <Space direction="horizontal" align="center">
          <Input
            prefix={<SearchOutlined />}
            value={query}
            onChange={event => setQuery(event.target.value)}
            allowClear
          />
          <Select
            placeholder="Provider"
            value={provider}
            allowClear
            onChange={val => setProvider(val as ConnectionProvider)}
            options={Object.values(ConnectionProviders).map(value => ({
              label: humanize(value),
              value,
            }))}
          />
          <Select
            placeholder="Status"
            value={status}
            allowClear
            onChange={val => setStatus(val as ConnectionStatus)}
            options={Object.values(ConnectionStatuses).map(value => ({
              label: humanize(value),
              value,
            }))}
          />
        </Space>
        <Button
          analyticsId="create_connection"
          label="+ Add connections"
          href="/organization/connections/new"
        />
      </Header>
      <Spacer size="large" />
      <Table
        rowKey={item => (item as OrganizationConnection).id}
        columns={[
          {
            render: (item: OrganizationConnection) => {
              const Icon = ProviderIcons[item.provider]
              return (
                <TableIcon>
                  <Icon />
                </TableIcon>
              )
            },
            width: '2.5rem',
          },
          { title: 'Name', dataIndex: 'name' },
          { title: 'Status', dataIndex: 'status' },
          {
            title: 'Since',
            // @ts-ignore
            render: (_, item: OrganizationConnection) => (
              <Typography.Paragraph>
                {moment(item.updatedAt).fromNow()}
              </Typography.Paragraph>
            ),
          },
        ]}
        dataSource={connections}
        onRow={({ id }: any) => ({
          style: { cursor: 'pointer' },
          onClick: () => push(`/organization/connections/${id}`),
        })}
        pagination={false}
      />

      {hasNextPage && <div ref={sentryRef} />}
      {loading && connections.length > 0 && (
        <Spacer>
          <Loading />
        </Spacer>
      )}
    </>
  )
}

export default OrganizationConnections
