import { Auditable } from './auditable'
import { Competitor } from './competitor'
import { PageableRequestFilter } from './pageable'

export type ProjectStatus = 'ACTIVE' | 'ARCHIVED'

export enum ProjectStatuses {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export type Project = {
  id: string
  orgId: string
  name: string
  currentVersion: number
  description?: string
  website?: string
  domain?: string
  avatar?: string
  problem?: string
  solution?: string
  headline?: string
  marketType?: string
  marketIndustry?: string
  marketTrend?: string
  marketCustomerSegmentation?: string
  marketGrowth?: string
  marketTam?: string
  marketDetails?: string
  neededHumanResources?: string
  canBeBootstrapped?: string
  fundingMinAmount?: string
  fundingAvg?: string
  timing?: string
  status: ProjectStatus
} & Auditable

export type NewProject = {
  id: string
  name: string
  description: string
  autoAnalysis: boolean
}

export type ProjectsFilter = PageableRequestFilter

export type ProjectUpdate = {
  id: string
  name?: string
  description?: string
  website?: string
  domain?: string
  avatar?: string
  problem?: string
  solution?: string
  headline?: string
  marketType?: string
  marketIndustry?: string
  marketTrend?: string
  marketCustomerSegmentation?: string
  marketGrowth?: string
  marketTam?: string
  marketDetails?: string
  neededHumanResources?: string
  canBeBootstrapped?: string
  fundingMinAmount?: string
  fundingAvg?: string
  timing?: string
  status?: ProjectStatus
}

export type Palette = {
  id: { projectId: string; name: string; projectVersion: number }
  primary: string
  accent?: string
  background?: string
} & Auditable

export type PaletteUpdate = {
  projectId: string
  name: string
  projectVersion: number
  primary?: string
  accent?: string
  background?: string
  createIteration: boolean
}

export type PalettesFilter = {
  projectId: string
  projectVersion: number
} & PageableRequestFilter

export type ProjectIteration = {
  id: { version: number; projectId: string }
  orgId: string
  name: string
  createdBy: string
  query?: string
  description?: string
  website?: string
  domain?: string
  avatar?: string
  problem?: string
  solution?: string
  headline?: string
  marketType?: string
  marketIndustry?: string
  marketTrend?: string
  marketCustomerSegmentation?: string
  marketGrowth?: string
  marketTam?: string
  marketDetails?: string
  neededHumanResources?: string
  canBeBootstrapped?: string
  fundingMinAmount?: string
  fundingAvg?: string
  timing?: string
} & Auditable

export type RestoreProjectIteration = {
  projectId: string
  version: number
}

export type ProjectIterationsFilter = {
  projectId: string
} & PageableRequestFilter

export type PartialChanges = {
  avatar?: string
  name?: string
  headline?: string
  description?: string
  problem?: string
  solution?: string
  marketType?: string
  marketIndustry?: string
  marketDetails?: string
  marketCustomerSegmentation?: string
  marketTrend?: string
  marketGrowth?: string
  marketTam?: string
  competitors?: Competitor[]
  darkPalette?: PalettePartialChanges
  lightPalette?: PalettePartialChanges
}

export type PalettePartialChanges = {
  primary?: string
  accent?: string
  background?: string
}
