import { Col, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { PropsWithChildren } from 'react'
import FullScreenLayout from './full-screen-layout'
import styled from 'styled-components'

type Props = PropsWithChildren & {
  image: string
  title: string
}

const FullScreenRow = styled(Row)`
  height: 100vh;
`
const ContentContainer = styled(Col)`
  height: 50vh;
  width: 100%;
  padding: 24px;
`
const ImgContainer = styled.img`
  width: 27vw;
  display: block;
  margin: 0 auto;
`
const OnboardingContainer: React.FC<Props> = ({ image, title, children }) => (
  <FullScreenLayout>
    <FullScreenRow align="middle" justify="center">
      <ContentContainer span={12}>
        <ImgContainer src={image} alt="onboarding-animation" />
      </ContentContainer>
      <ContentContainer span={12}>
        <Row>
          <Title>{title}</Title>
        </Row>
        {children}
      </ContentContainer>
    </FullScreenRow>
  </FullScreenLayout>
)

export default OnboardingContainer
