import { useContext, useEffect, useState } from 'react'
import OnboardingContainer from '../templates/onboarding'
import Img from '../../assets/onboarding-img-login.svg'
import { useParams } from 'react-router-dom'
import { Space, Typography } from 'antd'
import Spacer from '../atoms/spacer'
import Button from '../atoms/button'
import { GoogleSignIn } from '../atoms/google-sign-in-button'
import XhrPending from '../atoms/xhr-pending'
import {
  useAcceptOrganizationInvite,
  useDeclineOrganizationInvite,
  useInvitation,
} from '../../hooks/organization/useOrganization'
import { SecurityContext } from '../../context/security-context'
import { t } from 'i18next'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const InvitePage = () => {
  useDocumentTitle('Invitation')
  const { account, login, logout } = useContext(SecurityContext)
  const { id } = useParams<{ id: string }>()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const acceptInvite = useAcceptOrganizationInvite()
  const declineInvite = useDeclineOrganizationInvite()
  const { loading, data, error } = useInvitation(id)

  useEffect(() => {
    if (data) {
      setName(data.organization.name)
      setEmail(data.id.identifier)
    }
  }, [data])

  const handleAccept = () => {
    acceptInvite(id, {
      identifier: data?.id.identifier!,
      name: data?.name ?? account?.name!!,
      email: data?.id.identifier!,
    }).then(result => {
      if (result) {
        // the React router has no other route to go to,
        // so we need to route to the home page via the window
        window.location.pathname = '/'
      }
    })
  }

  const handleDecline = () => {
    declineInvite(id).then(result => {
      if (result) {
        // the React router has no other route to go to,
        // so we need to route to the home page via the window
        window.location.pathname = '/'
      }
    })
  }

  return (
    <XhrPending pending={loading}>
      <OnboardingContainer
        image={Img}
        title={
          error
            ? 'Invitation not found'
            : email === data?.id.identifier || !data
            ? `You have been invited to ${name}`
            : 'Invitation intended for a different account'
        }
      >
        <Spacer size="large" />
        <Space direction="vertical" size="large" style={{ maxWidth: '20vw' }}>
          {error ? (
            <>
              <Typography.Text>
                {t('invitations_invalid_expired')}
              </Typography.Text>
              <Typography.Text>{t('invitations_use_correct')}</Typography.Text>
            </>
          ) : !data ? (
            <>
              <Typography.Text>{t('invitations_signin')}</Typography.Text>
              <GoogleSignIn onClick={login} />
              <Button
                analyticsId="decline_invite_anonymous"
                onClick={handleDecline}
                type="default"
                label="decline"
              />
            </>
          ) : email === data?.id.identifier ? (
            <>
              <Typography.Text>
                Your account will{' '}
                <Typography.Text italic>{email}</Typography.Text> be linked to
                this account.
              </Typography.Text>
              <Space
                direction="horizontal"
                style={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <Button
                  analyticsId="decline_invite"
                  onClick={handleDecline}
                  type="default"
                  label="decline"
                />
                <Button
                  analyticsId="accept_invite"
                  onClick={handleAccept}
                  label="accept"
                />
              </Space>
            </>
          ) : (
            <>
              <Typography.Text>{t('invitations_logout')}</Typography.Text>
              <Button
                analyticsId="logout_invite"
                onClick={logout}
                label="logout"
              />
            </>
          )}
        </Space>
      </OnboardingContainer>
    </XhrPending>
  )
}

export default InvitePage
