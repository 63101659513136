import { useState } from 'react'

type Inputable = HTMLInputElement | HTMLTextAreaElement

type UseInput<T> = [
  T,
  (e: React.ChangeEvent<Inputable>) => void,
  (value: T) => void,
]

const useInput = <T>(initialValue?: T): UseInput<T | undefined> => {
  const [value, setValue] = useState<T | undefined>(initialValue)

  const handleInputChange = (e: React.ChangeEvent<Inputable>) => {
    setValue(e.target.value as unknown as T)
  }

  return [value, handleInputChange, setValue]
}

export default useInput
