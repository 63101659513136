import React from 'react'
import {
  prefixChildrenPaths,
  RouteDefinition,
  toSwitch,
} from '../../routes/route-helper'
import { Tabs } from 'antd'
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'

type Props = {
  routes: RouteDefinition[]
}

const RouteAwareTabs: React.FC<Props> = ({ routes }) => {
  const { url, path } = useRouteMatch()
  const firstPath = routes[0].path
  const { push, location } = useHistory()
  const { pathname } = location
  const activePath = pathname.replace(url, '') ?? firstPath

  return (
    <>
      <Tabs defaultActiveKey={activePath} onChange={key => push(url + key)}>
        {routes.map(route => (
          <Tabs.TabPane key={route.path} tab={route.label} />
        ))}
      </Tabs>
      {toSwitch([
        ...prefixChildrenPaths(path, routes),
        {
          path: '',
          component: () => <Redirect to={url + firstPath} />,
        },
      ])}
    </>
  )
}

export default RouteAwareTabs
