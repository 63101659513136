import { Media, MediaCategories, MediaCategory } from '../types/media'
import { useContext } from 'react'
import { AppContext } from '../context/application-context'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const useFileUpload = () => {
  const { firebase } = useContext(AppContext)

  const storage = getStorage(firebase!!)

  const upload = (
    file: File,
    filename: string,
    category: MediaCategory = MediaCategories.Default,
  ): Promise<Media> =>
    uploadBytes(ref(storage, `${category}/${filename}`), file)
      .then(snapshot => getDownloadURL(snapshot.ref))
      .then(url => ({ url } as Media))
  return { upload }
}

export default useFileUpload
