import React from 'react'
import styled from 'styled-components'
import { ToolExecutionLogEntry } from '../../types/tool'
import Log from './log'

type Props = {
  logs: ToolExecutionLogEntry[]
}

const Container = styled.div`
  padding: 6px;
  width: 100%;
`

const LogsPanel: React.FC<Props> = ({ logs }) => (
  <Container>
    {logs.map((log: ToolExecutionLogEntry, index: number) => (
      <Log log={log} key={index} />
    ))}
  </Container>
)

export default LogsPanel
