import React from 'react'
import styled from 'styled-components'
import { Col, Row, Tooltip } from 'antd'
import Title from 'antd/lib/typography/Title'
import { RestoreIcon, ViewIcon } from '../atoms/icon'
import Button from '../atoms/button'
import DateLabel from '../molecules/date-label'
import { ProjectIteration } from '../../types/project'

type Props = {
  iteration: ProjectIteration
  isCurrentIteration: boolean
  isActiveVersion: boolean
  onClickRestore: () => void
  onClickView: () => void
}

const Container = styled.div<{ isActiveVersion: boolean }>`
  width: 100%;
  display: flex;
  padding: 18px;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
  border-radius: 10px;
  background: #161825;
  ${props =>
    props.isActiveVersion &&
    `
    border: solid #8996da;
  `}
`

const CurrentLabel = styled.div`
  display: flex;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 10px;
  background: #8996da;
`

const tooltipStyle = {
  background: '#242735',
  fontSize: '12px',
}

const ProjectIterationCard: React.FC<Props> = ({
  iteration,
  isCurrentIteration,
  isActiveVersion,
  onClickRestore,
  onClickView,
}) => (
  <Container isActiveVersion={isActiveVersion}>
    <Row justify="space-between">
      <Col>
        <DateLabel timestamp={iteration.createdAt} />
        <Col>
          <Title level={5} style={{ fontWeight: '', color: '#9B9FAC' }}>
            {iteration.createdBy}
          </Title>
        </Col>
      </Col>
      <Col>
        {isCurrentIteration ? (
          <CurrentLabel>
            <Title level={5} style={{ fontWeight: 'normal' }}>
              current version
            </Title>
          </CurrentLabel>
        ) : (
          <Row gutter={16}>
            <Tooltip title="View changes" overlayInnerStyle={tooltipStyle}>
              <Col>
                <Button
                  analyticsId="view_iteration_changes"
                  icon={<ViewIcon />}
                  noBackground
                  onClick={onClickView}
                />
              </Col>
            </Tooltip>
            <Tooltip title="Restore changes" overlayInnerStyle={tooltipStyle}>
              <Col>
                <Button
                  analyticsId="restore_iteration_changes"
                  icon={<RestoreIcon />}
                  noBackground
                  onClick={onClickRestore}
                />
              </Col>
            </Tooltip>
          </Row>
        )}
      </Col>
    </Row>
    <Row>
      <Title level={5}>{iteration.query ?? 'Manual update'}</Title>
    </Row>
  </Container>
)

export default ProjectIterationCard
