import styled from 'styled-components'
import { Typography } from 'antd'
import { TextProps } from 'antd/lib/typography/Text'
import React from 'react'

export const Label = styled(Typography.Text)`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #8996da;
`

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
`

export const SmallP = styled(Typography.Text)`
  font-size: 14px;
`

type EditableTextProps = TextProps & {
  onChangeText: (val: string) => void
  placeholder?: string
}
const EditableTextPlaceholder = styled.span`
  color: #8d8c8c;
  font-style: italic;
`
export const EditableText: React.FC<EditableTextProps> = ({
  onChangeText,
  placeholder,
  editable,
  ...props
}) => (
  <Typography.Text
    {...props}
    editable={
      editable && {
        onChange: onChangeText,
        triggerType: ['icon', 'text'],
      }
    }
  >
    {props.children ? (
      props.children
    ) : (
      <EditableTextPlaceholder>{placeholder}</EditableTextPlaceholder>
    )}
  </Typography.Text>
)
