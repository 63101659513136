import styled from 'styled-components'

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
`
export const SocialItemContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
  :hover > .anticon {
    color: white;
  }
`
