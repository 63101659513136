import React from 'react'
import { Drawer } from 'antd'
import ExecutionDrawerHeader from './tool-execution-drawer-header'
import XhrPending from '../atoms/xhr-pending'
import DynamicForm from './form'
import { Tool } from '../../types/tool'
import { FormField, FormFieldType } from '../../types/form-field'
import { FormInstance } from 'antd/lib/form'
import _ from 'lodash'

type Props = {
  executingTool: Tool | undefined
  executionName: string
  onExecutionNameChange: (name: string) => void
  onExecutionFormSubmit: () => void
  onCloseExecutionForm: () => void
  xhrPending: boolean
  loading: boolean
  formFields: FormField[]
  formRef?: React.RefObject<FormInstance>
  formState: Record<string, unknown>
  requiredPlanSections: string
  onFormStateChange: (state: Record<string, unknown>) => void
  onClickRunExecution: (values: Record<string, unknown>) => void
}

const ExecutionDrawer: React.FC<Props> = ({
  executingTool,
  executionName,
  onExecutionNameChange,
  onExecutionFormSubmit,
  onCloseExecutionForm,
  xhrPending,
  loading,
  formFields,
  formRef,
  formState,
  requiredPlanSections,
  onFormStateChange,
  onClickRunExecution,
}) => (
  <Drawer
    width="50vw"
    placement="right"
    zIndex={1040}
    closable={false}
    onClose={onCloseExecutionForm}
    open={!!executingTool}
    title={
      <ExecutionDrawerHeader
        executingTool={executingTool}
        executionName={executionName}
        setExecutionName={onExecutionNameChange}
        handleExecutionFormSubmit={onExecutionFormSubmit}
      />
    }
  >
    <XhrPending pending={xhrPending}>
      <DynamicForm
        formId={executingTool?.identifier ?? 'unknown'}
        loading={loading && !formFields.length}
        fields={formFields}
        requiredPlanSections={requiredPlanSections}
        formRef={formRef}
        onSubmit={onClickRunExecution}
        onChange={onFormStateChange}
        initialValues={
          _.isEmpty(formState)
            ? formFields.reduce((acc, field) => {
                const value =
                  field.type === FormFieldType.MultiSelect
                    ? JSON.parse(field.value)
                    : field.value
                return { ...acc, [field.id]: value }
              }, {})
            : formState
        }
      />
    </XhrPending>
  </Drawer>
)

export default ExecutionDrawer
