import React, { useContext, useState } from 'react'
import { Page, PageContent, PageHeader } from '../templates/page'
import Panel from '../organism/panel'
import {
  Input,
  InputLabel,
  InputLabelContainer,
  Textarea,
} from '../atoms/input'
import { Col, Row, Space } from 'antd'
import { useParams } from 'react-router-dom'
import Select from '../molecules/select'
import Breadcrumb from '../molecules/breadcrumb'
import Button from '../atoms/button'
import { LinkedInIcon, TwitterIcon } from '../atoms/icon'
import { EditableText } from '../atoms/typography'
import {
  useEditMember,
  useMemberById,
} from '../../hooks/organization/useOrganization'
import {
  TwoColumnsContainer,
  TwoColumnsLeftContainer,
  TwoColumnsRightContainer,
} from '../molecules/two-columns'
import {
  IconPrefixedContainer,
  SocialsPanelContainer,
} from '../molecules/socials-panel'
import {
  OrganizationMemberRoles,
  OrganizationMemberStatuses,
} from '../../types/organization'
import { OrganizationContext } from '../../context/organization-context'
import { useBoolean } from '../../hooks/useBoolean'
import { extensionFrom, omitUndefined } from '../../utils/utils'
import { isEqual } from 'lodash'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import useFileUpload from '../../hooks/useFileUpload'
import moment from 'moment-timezone'
import { MediaCategories } from '../../types/media'
import ImageUpload from '../molecules/img-upload'
import useInput from '../../hooks/useInput'

const roleOptions = Object.values(OrganizationMemberRoles).map(role => {
  return { value: role, label: role }
})
const statusOptions = Object.values(OrganizationMemberStatuses).map(role => {
  return { value: role, label: role }
})

const OrganizationMemberDetailsPage = () => {
  const { id } = useParams<{ id: string }>()
  const { membership } = useContext(OrganizationContext)
  const { loading, data, refetch } = useMemberById(
    membership!.organization.id,
    id,
  )
  const [xhrPending, toggleXhrPending] = useBoolean()
  const [name, setName] = useInput<string>()
  const [avatar, setAvatar] = useState<string>()
  const [avatarFile, setAvatarFile] = useState<File>()
  const [title, setTitle] = useInput<string>()
  const [bio, setBio] = useInput<string>()
  const [role, setRole] = useState<OrganizationMemberRoles>()
  const [status, setStatus] = useState<OrganizationMemberStatuses>()
  const [twitter, setTwitter] = useState<string>()
  const [linkedin, setLinkedin] = useState<string>()
  const edit = useEditMember()
  const { upload } = useFileUpload()
  useDocumentTitle(`Settings - Members - ${data?.name}`, {
    skip: !data,
  })

  const isAdmin =
    membership!.role === OrganizationMemberRoles.Admin ||
    membership!.role === OrganizationMemberRoles.Owner

  const canEdit =
    membership!.role !== OrganizationMemberRoles.Viewer &&
    (isAdmin || id === membership!.id.identifier)

  const updatable = {
    ...data,
    ...omitUndefined({
      name,
      avatar,
      title,
      bio,
      role,
      status,
      twitter,
      linkedin,
    }),
  }
  const hasChanged = !isEqual(updatable, data) || avatarFile
  const canSave = !xhrPending && hasChanged
  const save = () => {
    if (canSave) {
      const uploadNewLogoIfAny = () =>
        avatarFile
          ? upload(
              avatarFile,
              ['avatar', data?.organization?.id, data?.id, moment.now()].join(
                '_',
              ) + `.${extensionFrom(avatarFile)}`,
              MediaCategories.Avatar,
            ).then(({ url }) => url)
          : Promise.resolve(membership?.avatar)
      toggleXhrPending()
      uploadNewLogoIfAny().then(avatarUrl =>
        edit({
          orgId: membership!.organization.id,
          identifier: id,
          name,
          avatar: avatarUrl,
          role,
          status,
          title,
          bio,
          linkedin,
          twitter,
        })
          .then(() => {
            if (avatarFile) {
              setAvatar(undefined)
              setAvatarFile(undefined)
            }
            refetch()
          })
          .finally(toggleXhrPending),
      )
    }
  }

  return (
    <Page xhrPending={xhrPending || loading}>
      <PageHeader>
        <Breadcrumb
          routes={[
            { path: '../../organization', breadcrumbName: 'Organization' },
            { path: '/members', breadcrumbName: 'Members' },
            { path: id, breadcrumbName: name ?? data?.name ?? id },
          ]}
        />
        <div>
          <Button
            analyticsId="update_organization_member"
            label="save"
            onClick={save}
            disabled={!canSave}
          />
        </div>
      </PageHeader>
      <PageContent>
        <TwoColumnsContainer>
          <TwoColumnsLeftContainer>
            <Panel>
              <Row>
                <Col flex="auto">
                  <ImageUpload
                    url={avatar ?? data?.avatar}
                    label="Avatar"
                    name="organization-member-avatar"
                    onUrlChange={setAvatar}
                    onFileChange={setAvatarFile}
                    disabled={!canEdit || !isAdmin}
                  />
                </Col>
                <Col span={20}>
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                  >
                    <InputLabelContainer>
                      <InputLabel>Name</InputLabel>
                      <Input
                        value={name ?? data?.name}
                        placeholder="David Chen"
                        readOnly={!canEdit || !isAdmin}
                        onChange={setName}
                        allowClear
                        required
                      />
                    </InputLabelContainer>
                    <InputLabelContainer>
                      <InputLabel>Title</InputLabel>
                      <Input
                        value={title ?? data?.title}
                        placeholder="Co-founder & Visionary"
                        readOnly={!canEdit || !isAdmin}
                        onChange={setTitle}
                        allowClear
                        required
                      />
                    </InputLabelContainer>
                    <InputLabelContainer>
                      <InputLabel>Bio</InputLabel>
                      <Textarea
                        value={bio ?? data?.bio}
                        placeholder="A passionate entrepreneur with a knack for innovation."
                        onChange={setBio}
                        readOnly={!canEdit}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        allowClear
                      />
                    </InputLabelContainer>
                  </Space>
                </Col>
              </Row>
            </Panel>
          </TwoColumnsLeftContainer>
          <TwoColumnsRightContainer>
            <Panel>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
              >
                <InputLabelContainer>
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={role ?? data?.role}
                    dropdownStyle={
                      !canEdit || !isAdmin ? { display: 'none' } : undefined
                    }
                    options={roleOptions}
                    onChange={val => setRole(val as OrganizationMemberRoles)}
                  />
                </InputLabelContainer>

                <InputLabelContainer>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status ?? data?.status}
                    dropdownStyle={
                      !canEdit || !isAdmin ? { display: 'none' } : undefined
                    }
                    options={statusOptions}
                    onChange={val =>
                      setStatus(val as OrganizationMemberStatuses)
                    }
                  />
                </InputLabelContainer>
              </Space>
            </Panel>
            <Panel>
              <SocialsPanelContainer>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{ width: '100%' }}
                >
                  <IconPrefixedContainer>
                    <LinkedInIcon />
                    <EditableText
                      onChangeText={setLinkedin}
                      placeholder={'LinkedIn'}
                      editable={canEdit}
                    >
                      {linkedin ?? data?.linkedin}
                    </EditableText>
                  </IconPrefixedContainer>
                </Row>
                <Row
                  align="middle"
                  justify="space-between"
                  style={{ width: '100%' }}
                >
                  <IconPrefixedContainer>
                    <TwitterIcon />
                    <EditableText
                      onChangeText={setTwitter}
                      placeholder={'Twitter'}
                      editable={canEdit}
                    >
                      {twitter ?? data?.twitter}
                    </EditableText>
                  </IconPrefixedContainer>
                </Row>
              </SocialsPanelContainer>
            </Panel>
          </TwoColumnsRightContainer>
        </TwoColumnsContainer>
      </PageContent>
    </Page>
  )
}

export default OrganizationMemberDetailsPage
