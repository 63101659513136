import React from 'react'
import logo from '../../assets/logo.svg'

type Props = {
  size?: string
}

const Logo: React.FC<Props> = ({ size = '29vw' }) => (
  <img src={logo} width={size} alt="logo" />
)

export default Logo
