import React from 'react'
import { Tool } from '../../types/tool'
import { Col, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import Button from '../atoms/button'
import { RocketIcon } from '../atoms/icon'
import ShowMoreLessText from '../molecules/view-more-less'
import Spacer from '../atoms/spacer'

type Props = {
  executingTool: Tool | undefined
  executionName: string
  setExecutionName: (name: string) => void
  handleExecutionFormSubmit: () => void
}

const ExecutionDrawerHeader: React.FC<Props> = ({
  executingTool,
  executionName,
  setExecutionName,
  handleExecutionFormSubmit,
}) => (
  <>
    <Row justify="space-between">
      <Col>
        <Row gutter={12} align="middle">
          <Col>
            <Title level={2}>
              {'#' + ((executingTool?.projectExecutionsCount ?? 0) + 1)}
            </Title>
          </Col>
          <Col>
            <Title
              level={2}
              editable={{
                tooltip:
                  'Customize the execution name for easier identification.',
                onChange: name => setExecutionName(name),
              }}
            >
              {executionName}
            </Title>
          </Col>
        </Row>
        <Spacer size="small" />
        <Row>
          <Title level={3}>{executingTool?.name}</Title>
        </Row>
      </Col>
      <Col>
        <Button
          analyticsId={`execution_drawer_launch_tool_${executingTool?.identifier}`}
          onClick={handleExecutionFormSubmit}
          icon={<RocketIcon style={{ fontSize: 16, color: 'black' }} />}
          label="launch"
          darkLabel
        />
      </Col>
    </Row>
    <Spacer size="medium" />
    {executingTool?.description && (
      <ShowMoreLessText text={executingTool.description} />
    )}
  </>
)

export default ExecutionDrawerHeader
