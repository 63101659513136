import styled from 'styled-components'
import React from 'react'
import { Col, Row, Typography } from 'antd'
import Button from '../atoms/button'
import ShowMoreLessText from '../molecules/view-more-less'
import { Tool, ToolStatus } from '../../types/tool'
import Title from 'antd/lib/typography/Title'
import {
  DownloadIcon,
  RocketIcon,
  StopWatchIcon,
  VerifiedBadge,
  LockIcon,
} from '../atoms/icon'
import moment from 'moment-timezone'
import Panel from './panel'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

type Props = {
  tool: Tool
  onClickLaunch: (tool: Tool) => void
  atPlanLevel: boolean
}

const MetricContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
`
const LaunchButtonContainer = styled.div`
  width: 70px;
  flex-shrink: 0;
  border-radius: 10px;
`
const LaunchButton = styled(Button)`
  &:disabled {
    background-color: #1d2031;
  }
`

const StatusBadge = styled.div`
  padding: 2px 9px;
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid #7f828c;
`

const ToolCard: React.FC<Props> = ({ tool, onClickLaunch, atPlanLevel }) => (
  <Panel>
    <Row justify="space-between">
      <Col>
        <Title level={3}>{tool.name}</Title>
        <Row align="middle" gutter={8}>
          <Col>
            <Typography.Title level={5} style={{ fontWeight: 'normal' }}>
              By {tool.developer.name}
            </Typography.Title>
          </Col>
          <Col>
            {tool.developer.verified && (
              <VerifiedBadge style={{ fontSize: 15 }} />
            )}
          </Col>
        </Row>
      </Col>
      {tool.status !== ToolStatus.ACTIVE ? (
        <Col>
          <StatusBadge>{t(`tool_status_${tool.status}`)}</StatusBadge>
        </Col>
      ) : null}
    </Row>
    {tool.description && (
      <ShowMoreLessText text={tool.description} charLimit={190} />
    )}
    <Row justify="space-between" align="middle">
      <MetricContainer>
        <DownloadIcon style={{ fontSize: 20 }} />
        <div>{tool.executionsCount}</div>
      </MetricContainer>
      <MetricContainer>
        <StopWatchIcon style={{ fontSize: 20 }} />
        <div>
          {moment.duration(tool.avgExecutionTimeMs, 'milliseconds').humanize()}{' '}
          to deploy
        </div>
      </MetricContainer>
      <Col>
        <LaunchButtonContainer>
          <LaunchButton
            analyticsId={`open_launch_form_${tool.identifier}`}
            icon={
              atPlanLevel ? (
                <RocketIcon
                  style={{
                    margin: '6px 0',
                    fontSize: 20,
                    color: `${
                      tool.status === ToolStatus.COMING_SOON || !atPlanLevel
                        ? '#7F828C'
                        : 'rgb(22 24 37)'
                    }`,
                  }}
                />
              ) : (
                <LockIcon
                  style={{
                    margin: '6px 0',
                    fontSize: 20,
                    color: '#7F828C',
                  }}
                />
              )
            }
            disabled={tool.status === ToolStatus.COMING_SOON || !atPlanLevel}
            onClick={() => onClickLaunch(tool)}
            tooltip={
              !atPlanLevel ? (
                <Trans
                  i18nKey="upgrade_plan"
                  components={{
                    1: (
                      <Typography.Link href="/organization/billing?plan=team" />
                    ),
                  }}
                />
              ) : null
            }
          />
        </LaunchButtonContainer>
      </Col>
    </Row>
  </Panel>
)
export default ToolCard
