import React from 'react'
import moment from 'moment-timezone'
import { Typography } from 'antd'

type Props = {
  timestamp: Date
}

const DateLabel: React.FC<Props> = ({ timestamp }) => {
  return (
    <Typography.Title level={5} style={{ fontWeight: 'bold' }}>
      {moment(timestamp).format('MMMM D, h:mm A')}
    </Typography.Title>
  )
}

export default DateLabel
