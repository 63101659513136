import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { Project } from '../types/project'
import { noop } from '../utils/utils'
import { useQueryParam } from '../hooks/useQueryParam'
import { useOrganizationProjects } from '../hooks/project/useProject'
import LoadingPage from '../components/pages/loading'
import ProjectSeedPage from '../components/pages/organization-project-seed'

type ProjectContextProps = {
  projects: Project[]
  project?: Project
  refetch: () => Promise<void>
  setProjectId: (value: string | undefined) => void
}

export const ProjectContext = React.createContext<ProjectContextProps>({
  projects: [],
  refetch: Promise.reject,
  setProjectId: noop,
})

export const ProjectContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [projectId, setProjectId] = useQueryParam<string>('pro')
  const { loading, page, refetch } = useOrganizationProjects({})
  const projects = page?.data!
  const [project, setProject] = useState<Project>()
  const hasProjects = projects?.length > 0

  const projectById: Record<string, Project> = useMemo(
    () =>
      projects?.reduce(
        (byOrgId, item) => ({ ...byOrgId, [item.id]: item }),
        {},
      ),
    [projects],
  )

  useEffect(() => {
    if (!project && hasProjects)
      setProject(projectById[projectId] ?? projects[0])
    else if (project) {
      setProject(projectById[project.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects])

  useEffect(() => {
    if (projectId && hasProjects) {
      refetch().then(() => {
        setProject(projectById[projectId])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  return loading || (hasProjects && !project) ? (
    <LoadingPage />
  ) : hasProjects ? (
    <ProjectContext.Provider
      value={{ projects, project, refetch, setProjectId }}
    >
      {children}
    </ProjectContext.Provider>
  ) : (
    <ProjectSeedPage onSuccess={refetch} />
  )
}
