import { gql } from '@apollo/client'
import useQuery from '../useQuery'
import { Page } from '../../types/pageable'
import {
  NewProject,
  Palette,
  PalettesFilter,
  PaletteUpdate,
  Project,
  ProjectIteration,
  ProjectIterationsFilter,
  ProjectsFilter,
  ProjectUpdate,
  RestoreProjectIteration,
} from '../../types/project'
import useMutation from '../useMutation'
import { noop } from '../../utils/utils'

const createProjectMutation = gql`
  mutation CreateProject($project: NewProjectInput!) {
    createProject(project: $project) {
      id
    }
  }
`

const projectByIdQuery = gql`
  query ProjectById($identifier: String!) {
    projectById(identifier: $identifier) {
      id
      name
      currentVersion
      description
      avatar
      problem
      solution
      headline
      website
      domain
      marketType
      marketIndustry
      marketTrend
      marketCustomerSegmentation
      marketGrowth
      marketTam
      marketDetails
      neededHumanResources
      canBeBootstrapped
      fundingMinAmount
      fundingAvg
      timing
      status
    }
  }
`

const projectsQuery = gql`
  query OrganizationProjects($filter: ProjectFilterInput!) {
    projects(filter: $filter) {
      data {
        id
        name
        currentVersion
        description
        avatar
        problem
        solution
        headline
        website
        domain
        marketType
        marketIndustry
        marketTrend
        marketCustomerSegmentation
        marketGrowth
        marketTam
        marketDetails
        neededHumanResources
        canBeBootstrapped
        fundingMinAmount
        fundingAvg
        timing
        status
        createdAt
        updatedAt
      }
      next
      previous
    }
  }
`

const editMutation = gql`
  mutation EditProject($project: ProjectUpdateInput!, $competitorVersion: Int) {
    editProject(project: $project, competitorVersion: $competitorVersion) {
      id
      name
    }
  }
`

const restoreIterationMutation = gql`
  mutation RestoreProjectIteration($iteration: RestoreProjectIterationInput!) {
    restoreProjectIteration(iteration: $iteration) {
      id
      name
      currentVersion
    }
  }
`

const palettesQuery = gql`
  query ProjectPalettes($filter: PalettesFilterInput!) {
    projectPalettes(filter: $filter) {
      data {
        id {
          name
          projectVersion
        }
        primary
        accent
        background
        createdAt
        updatedAt
      }
      next
      previous
    }
  }
`

const editPaletteMutation = gql`
  mutation EditProjectPalette($palette: PaletteUpdateInput!) {
    editProjectPalette(palette: $palette) {
      id {
        name
        projectVersion
      }
      primary
      accent
      background
      createdAt
      updatedAt
    }
  }
`

const projectIterationsQuery = gql`
  query ProjectIterations($filter: ProjectIterationFilterInput!) {
    projectIterations(filter: $filter) {
      data {
        id {
          version
          projectId
        }
        name
        createdBy
        query
        description
        avatar
        problem
        solution
        headline
        website
        domain
        marketType
        marketIndustry
        marketTrend
        marketCustomerSegmentation
        marketGrowth
        marketTam
        marketDetails
        neededHumanResources
        canBeBootstrapped
        fundingMinAmount
        fundingAvg
        timing
        createdAt
        updatedAt
      }
      next
      previous
    }
  }
`

const createProjectIterationFromPromptMutation = gql`
  mutation CreateProjectIterationFromPrompt(
    $projectId: String!
    $fromVersion: Int!
    $prompt: String!
  ) {
    createProjectIterationFromPrompt(
      projectId: $projectId
      fromVersion: $fromVersion
      prompt: $prompt
    ) {
      id {
        version
        projectId
      }
      name
      createdBy
      query
      description
      avatar
      problem
      solution
      headline
      website
      domain
      marketType
      marketIndustry
      marketTrend
      marketCustomerSegmentation
      marketGrowth
      marketTam
      marketDetails
      neededHumanResources
      canBeBootstrapped
      fundingMinAmount
      fundingAvg
      timing
      createdAt
      updatedAt
    }
  }
`

export const useProjectById = (identifier: string) => {
  const { loading, data, refetch } = useQuery<{
    projectById: Project
  }>(projectByIdQuery, { variables: { identifier } })

  return { loading, data: data?.projectById, refetch: () => refetch() }
}

export const useOrganizationProjects = (filter: ProjectsFilter) => {
  const { loading, data, refetch } = useQuery<{
    projects: Page<Project>
  }>(projectsQuery, { variables: { filter } })

  return { loading, page: data?.projects, refetch: () => refetch().then(noop) }
}

export const useCreateProject = () => {
  const [mutate] = useMutation<{ createProject: Project }>(
    createProjectMutation,
  )

  return (project: NewProject) =>
    mutate({ variables: { project } }).then(
      result => result.data!.createProject,
    )
}

export const useEditProject = () => {
  const [mutate] = useMutation<{ editProject: Project }>(editMutation)

  return (project: ProjectUpdate, competitorVersion?: number) =>
    mutate({ variables: { project, competitorVersion } }).then(
      result => result.data!.editProject,
    )
}

export const useRestoreIterationMutation = () => {
  const [mutate] = useMutation<{ project: Project }>(restoreIterationMutation)

  return (iteration: RestoreProjectIteration) =>
    mutate({ variables: { iteration } }).then(result => result.data!.project)
}

export const usePalettes = (filter: PalettesFilter) => {
  const { loading, data, refetch } = useQuery<{
    projectPalettes: Page<Palette>
  }>(palettesQuery, { variables: { filter } })

  return {
    loading,
    page: data?.projectPalettes,
    refetch: () => refetch().then(noop),
  }
}

export const useEditPalette = () => {
  const [mutate] = useMutation<{ editProjectPalette: Palette }>(
    editPaletteMutation,
  )

  return (palette: PaletteUpdate) =>
    mutate({ variables: { palette } }).then(
      result => result.data!.editProjectPalette,
    )
}

export const useProjectIterations = (filter: ProjectIterationsFilter) => {
  const { loading, data, refetch } = useQuery<{
    projectIterations: Page<ProjectIteration>
  }>(projectIterationsQuery, { variables: { filter } })

  return {
    loading,
    page: data?.projectIterations,
    refetch: () => refetch().then(noop),
  }
}

export const useCreateProjectIterationFromPrompt = () => {
  const [mutate] = useMutation<{
    createProjectIterationFromPrompt: ProjectIteration
  }>(createProjectIterationFromPromptMutation)

  return (projectId: string, fromVersion: number, prompt: string) =>
    mutate({ variables: { projectId, fromVersion, prompt } }).then(
      result => result.data!.createProjectIterationFromPrompt,
    )
}
