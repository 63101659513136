import React, { useEffect } from 'react'
import { Page, PageContent, PageHeader } from '../templates/page'
import Breadcrumb from '../molecules/breadcrumb'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import styled from 'styled-components'
import { ConnectionProvider } from '../../types/connection'
import { ProviderIcons } from '../atoms/icon'
import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
import { useBoolean } from '../../hooks/useBoolean'
import { useCreateGithubConnection } from '../../hooks/organization/useConnections'

const Container = styled(PageContent)`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  background: rgba(0, 0, 0, 0.25);
`
const ProviderButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
`

const ProviderButton = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;
  border-radius: 0.625rem;
  border: 1px solid #8996da;
  cursor: pointer;
  color: white;
  font-weight: 700;
  svg {
    font-size: 2.5rem;
    color: whitesmoke;
  }
  :hover {
    background: rgba(77, 84, 121, 0.25);
  }
`

const OrganizationNewConnectionPage = () => {
  useDocumentTitle('Settings - New connection')
  const [xhrPending, toggleXhrPending] = useBoolean()
  const { push, replace } = useHistory()
  const createGithubConnection = useCreateGithubConnection()

  const handleAuthorization = async (code: string, installationId: string) => {
    toggleXhrPending()
    createGithubConnection(code, installationId)
      .then(result => {
        if (result) replace(`/organization/connections/${result.id}`)
      })
      .finally(toggleXhrPending)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    const installationId = urlParams.get('installation_id')
    if (code && installationId) {
      handleAuthorization(code, installationId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const authorizeCogoGitHubApp = () => {
    const redirectUrl = window.location.origin + '/organization/connections/new'
    window.location.href = `https://github.com/apps/cogo-tools?redirect_uri=${redirectUrl}`
  }

  const connect = (provider: ConnectionProvider) => {
    if (provider === 'GITHUB') {
      authorizeCogoGitHubApp()
    } else if (provider === 'AWS') {
      push('/organization/connections/aws')
    } else if (provider === 'GCP') {
      push('/organization/connections/gcp')
    }
  }

  return (
    <Page xhrPending={xhrPending}>
      <PageHeader>
        <Breadcrumb
          routes={[
            { path: '../../organization', breadcrumbName: 'Organization' },
            { path: '/connections', breadcrumbName: 'Connections' },
            { path: '/new', breadcrumbName: 'New' },
          ]}
        />
      </PageHeader>
      <Container>
        <ProviderButtonsContainer>
          {Object.entries(ProviderIcons).map(([provider, Icon]) => (
            <ProviderButton
              key={provider}
              onClick={() => connect(provider as ConnectionProvider)}
            >
              <Icon key={provider} />
              <div>{t(`connection_provider_${provider}`)}</div>
            </ProviderButton>
          ))}
        </ProviderButtonsContainer>
      </Container>
    </Page>
  )
}

export default OrganizationNewConnectionPage
