import { Input as AntInput } from 'antd'
import styled from 'styled-components'
import { Label } from './typography'

export const Input = styled(AntInput)<{ $highlight?: boolean }>`
  padding: 8px;
  ${props => props.$highlight && `border: 1px solid #8996da;`}
`

export const PasswordInput = styled(AntInput.Password)`
  padding: 8px;
`

export const Textarea = styled(AntInput.TextArea)<{ $highlight?: boolean }>`
  padding: 8px;
  ${props => props.$highlight && `border: 1px solid #8996da;`}
`
export const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: inherit;
  gap: 8px;
`

export const InputLabel = styled(Label)``
