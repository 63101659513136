import React from 'react'
import styled from 'styled-components'
import { ToolExecutionArtifact } from '../../types/tool'
import { Col, Empty } from 'antd'
import Artifact from './artifact'

type Props = {
  artifacts: ToolExecutionArtifact[]
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px;
  gap: 36px;
`

const NoArtifactContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const ArtifactsPanel: React.FC<Props> = ({ artifacts }) => (
  <Container>
    {artifacts.length > 0 ? (
      artifacts.map((artifact: ToolExecutionArtifact, index: number) => (
        <Col>
          <Artifact key={index} artifact={artifact} />
        </Col>
      ))
    ) : (
      <NoArtifactContainer>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No artifacts have been produced."
        />
      </NoArtifactContainer>
    )}
  </Container>
)

export default ArtifactsPanel
