import { useState } from 'react'

type UseBoolean = [boolean, () => void, (val: boolean) => void]

export const useBoolean = (initialValue = false): UseBoolean => {
  const [aBoolean, setBoolean] = useState(initialValue)

  const toggle = () => setBoolean(prevState => !prevState)

  return [aBoolean, toggle, setBoolean]
}
