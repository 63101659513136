import React from 'react'
import moment from 'moment-timezone'
import Title from 'antd/lib/typography/Title'

type Props = {
  timestamp: Date
  executorName?: string
}

const TimeLabel: React.FC<Props> = ({ timestamp, executorName }) => {
  const label = executorName
    ? `${moment(timestamp).fromNow()} by ${executorName}`
    : moment(timestamp).fromNow()
  return (
    <Title level={5} style={{ fontWeight: 'normal', margin: 0 }}>
      {label}
    </Title>
  )
}

export default TimeLabel
