import React from 'react'
import styled from 'styled-components'
import { ArtifactTypes, ToolExecutionArtifact } from '../../types/tool'
import { PopoutIcon, PresentationIcon, RepositoryIcon } from '../atoms/icon'
import Title from 'antd/lib/typography/Title'

type Props = {
  artifact: ToolExecutionArtifact
}

const Container = styled.a`
  display: flex;
  align-self: stretch;
  gap: 14px;
`

const iconStyle = { fontSize: 20 }
export const iconByArtifactType: Record<ArtifactTypes, React.ReactNode> = {
  [ArtifactTypes.Presentation]: <PresentationIcon style={iconStyle} />,
  [ArtifactTypes.Repository]: <RepositoryIcon style={iconStyle} />,
  [ArtifactTypes.External]: <PopoutIcon style={iconStyle} />,
}

const Artifact: React.FC<Props> = ({ artifact }) => (
  <Container href={artifact.url} target="_blank">
    {iconByArtifactType[artifact.type]}
    <Title level={4}>{artifact.name}</Title>
  </Container>
)

export default Artifact
