export enum FormFieldType {
  Text = 'TEXT',
  Number = 'NUMBER',
  Select = 'SELECT',
  MultiSelect = 'MULTISELECT',
  Information = 'INFORMATION',
  Radio = 'RADIO',
  Checkbox = 'CHECKBOX',
}

export enum TextInputKind {
  Text = 'TEXT',
  TextArea = 'TEXTAREA',
  Email = 'EMAIL',
  File = 'FILE',
  Url = 'URL',
  Password = 'PASSWORD',
  Color = 'COLOR',
}

export enum InfoLevel {
  Warning = 'WARNING',
}

export type Option<T> = {
  label: string
  value: T
}

export type FormField = {
  id: string
  type: FormFieldType
  label: string
  value: string
  required: boolean
  description?: string
  dependsOn?: string
  section?: string
  repeatableGroup?: string
  requiredPlanLevel?: number
}

export type Information = FormField & {
  informationLevel?: InfoLevel
}

export type TextInput = FormField & {
  value?: string
  regexValidation?: string
  kind?: TextInputKind
}

export type NumberInput = FormField & {
  value?: number
  min?: number
  max?: number
}

export type SelectInput<T> = FormField & {
  options: Option<T>[]
  selectedValue?: T
}

export type RadioInput<T> = FormField & {
  options: Option<T>[]
}
