import { gql } from '@apollo/client'
import useQuery from '../useQuery'
import { Competitor } from '../../types/competitor'

const query = gql`
  query Competitors($projectId: String!, $projectVersion: Int!) {
    competitors(projectId: $projectId, projectVersion: $projectVersion) {
      id
      name
      website
      competitiveAdvantage
      keyDifferentiator
      projectVersion
    }
  }
`

const useCompetitors = (projectId: string, projectVersion: number) => {
  const { loading, data } = useQuery<{
    competitors: Competitor[]
  }>(query, { variables: { projectId, projectVersion } })

  return { loading, competitors: data?.competitors ?? [] }
}

export default useCompetitors
