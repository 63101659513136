import { Result } from 'antd'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const NotFoundPage = () => {
  useDocumentTitle('Not found')
  return (
    <Result
      status="404"
      title="Nothing to see here"
      subTitle="We can't find the page you're looking for."
    />
  )
}

export default NotFoundPage
