import React from 'react'
import useInput from '../../hooks/useInput'
import { NewConnectionPageContainer, Page, PageHeader } from '../templates/page'
import Breadcrumb from '../molecules/breadcrumb'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { Space, Switch, Typography } from 'antd'
import { TerminalIcon } from '../atoms/icon'
import { t } from 'i18next'
import Button from '../atoms/button'
import Spacer from '../atoms/spacer'
import CodeBlock from '../atoms/codeblock'
import {
  Input,
  InputLabel,
  InputLabelContainer,
  Textarea,
} from '../atoms/input'
import { Trans } from 'react-i18next'
import { useBoolean } from '../../hooks/useBoolean'
import { useHistory } from 'react-router-dom'
import { useCreateConnection } from '../../hooks/organization/useConnections'
import { ConnectionProviders } from '../../types/connection'
import Header from '../atoms/header'

const { Title, Link } = Typography

const GCPInstructions = () => (
  <Typography>
    <Title level={2}>{t('gcp_new_step_one')}</Title>
    <Spacer size="small" />
    <ul>
      <li>
        <Trans
          i18nKey="gcp_new_sign_in"
          components={{
            1: (
              <Link href="https://console.cloud.google.com/" target="_blank" />
            ),
          }}
        />
      </li>
      <li>
        {t('gcp_new_project_selection')}
        <ul>
          <li>{t('gcp_new_project_creation')}</li>
          <li>{t('gcp_new_project_details')}</li>
          <li>
            <Trans
              i18nKey="gcp_new_billing_setup"
              components={{
                1: (
                  <Link
                    href="https://console.cloud.google.com/billing/linkedaccount"
                    target="_blank"
                  />
                ),
              }}
            />
          </li>
          <li>{t('gcp_new_dashboard')}</li>
        </ul>
      </li>
    </ul>
    <Spacer size="medium" />
    <Title level={2}>{t('gcp_new_step_two')}</Title>
    <Spacer size="small" />
    <ul>
      <li>
        <Trans
          i18nKey="gcp_enable_resource_manager_api"
          components={{
            1: (
              <Link
                href="https://console.cloud.google.com/marketplace/product/google/cloudresourcemanager.googleapis.com"
                target="_blank"
              />
            ),
          }}
        />
      </li>
      <li>
        <Trans
          i18nKey="gcp_enable_service_usage_api"
          components={{
            1: (
              <Link
                href="https://console.cloud.google.com/apis/library/serviceusage.googleapis.com"
                target="_blank"
              />
            ),
          }}
        />
      </li>
    </ul>
    <Spacer size="medium" />
    <Title level={2}>{t('gcp_new_step_three')}</Title>
    <Spacer size="small" />
    <ul>
      <li>
        <Trans
          i18nKey="gcp_new_navigate_service_account"
          components={{
            1: (
              <Link
                href="https://console.cloud.google.com/iam-admin/serviceaccounts"
                target="_blank"
              />
            ),
          }}
        />
      </li>
      <li>{t('gcp_new_create_service_account')}</li>
      <li>{t('gcp_new_service_account_name')}</li>
      <li>{t('gcp_new_create_continue')}</li>
      <li>{t('gcp_new_grant_role')}</li>
      <li>{t('gcp_new_grant_user_access')}</li>
      <li>{t('gcp_new_done')}</li>
    </ul>
    <Spacer size="medium" />
    <Title level={2}>{t('gcp_new_step_four')}</Title>
    <Spacer size="small" />
    <ul>
      <li>{t('gcp_new_select_account')}</li>
      <li>{t('gcp_new_keys_tab')}</li>
      <li>{t('gcp_new_add_key')}</li>
      <li>{t('gcp_new_key_json')}</li>
    </ul>
  </Typography>
)

const TerminalMode = () => {
  const script = `#!/bin/bash

# Define variables
SERVICE_ACCOUNT_NAME="cogo-service-account"
PROJECT_ID="your-project-id"  # Replace with your GCP project ID

# Enable the Cloud Resource Manager API
gcloud services enable cloudresourcemanager.googleapis.com

# Create a new service account
gcloud iam service-accounts create "$SERVICE_ACCOUNT_NAME" --display-name="Cogo Service Account"

# Add "Editor" role to the service account
gcloud projects add-iam-policy-binding "$PROJECT_ID" \
  --member="serviceAccount:$SERVICE_ACCOUNT_NAME@$PROJECT_ID.iam.gserviceaccount.com" \
  --role="roles/editor"

# Add "Security Admin" role to the service account
gcloud projects add-iam-policy-binding "$PROJECT_ID" \
  --member="serviceAccount:$SERVICE_ACCOUNT_NAME@$PROJECT_ID.iam.gserviceaccount.com" \
  --role="roles/iam.securityAdmin"

# Create an access key for the service account
gcloud iam service-accounts keys create key.json \
  --iam-account="$SERVICE_ACCOUNT_NAME@$PROJECT_ID.iam.gserviceaccount.com"

# Print the key to the screen
cat key.json

# Clean up: Delete the generated key file
rm key.json`

  return <CodeBlock>{script}</CodeBlock>
}

const OrganizationNewConnectionGcpPage = () => {
  useDocumentTitle('Settings - New connection - GCP')

  const [isTerminal, toggleIsTerminal] = useBoolean()
  const [name, setName] = useInput<string>('')
  const [credentials, setCredentials] = useInput<string>('')
  const createConnection = useCreateConnection()
  const { push } = useHistory()

  const canCreate = name && credentials

  const create = () => {
    createConnection({
      name: name!,
      provider: ConnectionProviders.Gcp,
      credentials: credentials!,
    }).then(result => {
      if (result) push(`/organization/connections/${result.id}`)
    })
  }

  return (
    <Page>
      <PageHeader>
        <Breadcrumb
          routes={[
            { path: '../', breadcrumbName: 'Organization' },
            {
              path: '../connections',
              breadcrumbName: 'Connections',
            },
            { path: '/', breadcrumbName: 'Google Cloud' },
          ]}
        />
      </PageHeader>
      <NewConnectionPageContainer>
        <Header>
          <Space direction="horizontal" align="center">
            <TerminalIcon
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'whitesmoke',
                fontSize: '24px',
              }}
            />
            <Switch onChange={toggleIsTerminal} />
          </Space>
          <Button
            analyticsId={`create_connection_${ConnectionProviders.Gcp}`}
            label="create"
            onClick={create}
            disabled={!canCreate}
          />
        </Header>
        <Spacer size="small" />
        {isTerminal ? <TerminalMode /> : <GCPInstructions />}
        <Space direction="vertical">
          <InputLabelContainer style={{ width: '25%' }}>
            <InputLabel>Name</InputLabel>
            <Input
              value={name}
              placeholder="GCP Connection"
              onChange={setName}
              required
            />
          </InputLabelContainer>
          <InputLabelContainer>
            <InputLabel>Credentials</InputLabel>
            <Textarea
              value={credentials}
              placeholder="Google Cloud Credentials JSON"
              onChange={setCredentials}
              autoSize={{ minRows: 20 }}
              style={{ fontFamily: 'monospace' }}
            />
          </InputLabelContainer>
        </Space>
      </NewConnectionPageContainer>
    </Page>
  )
}

export default OrganizationNewConnectionGcpPage
