import React from 'react'
import { ToolExecutionLogEntry } from '../../types/tool'
import { Col, Row, Typography } from 'antd'
import ShowMoreLessText from '../molecules/view-more-less'

type Props = {
  log: ToolExecutionLogEntry
}

const Log: React.FC<Props> = ({ log }) => (
  <Row style={{ fontSize: '14px' }}>
    <Col span={7}>
      <Typography.Text>{log.timestamp}</Typography.Text>
    </Col>
    <Col span={2}>
      <Typography.Text>{log.level}</Typography.Text>
    </Col>
    <Col span={15}>
      <ShowMoreLessText text={log.message} charLimit={150} />
    </Col>
  </Row>
)

export default Log
