import React, { PropsWithChildren } from 'react'

type SpacerSize = 'small' | 'medium' | 'large'

type SpacerDirection = 'horizontal' | 'vertical' | 'none'

const spacerSizeMapping: Record<SpacerSize, string> = {
  small: '8px',
  medium: '18px',
  large: '38px',
}

const getMargin = (size: SpacerSize, direction: SpacerDirection) => {
  const pixel = spacerSizeMapping[size]
  switch (direction) {
    case 'horizontal':
      return { margin: `${pixel} 0` }
    case 'vertical':
      return { margin: `0 ${pixel}` }
    case 'none':
    default:
      return { margin: pixel }
  }
}

type SpacerProps = PropsWithChildren & {
  size?: SpacerSize
  direction?: SpacerDirection
}

const Spacer: React.FC<SpacerProps> = ({
  size = 'medium',
  direction = 'horizontal',
  children,
}) => <div style={getMargin(size, direction)}>{children}</div>

export default Spacer
