import styled from 'styled-components'
import Title from 'antd/lib/typography/Title'
import React, { HTMLAttributes, PropsWithChildren } from 'react'
import { Skeleton } from 'antd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 12px;
`

type Props = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    title?: string
    loading?: boolean
  }
>
const Panel: React.FC<Props> = ({ title, loading, children, ...rest }) => (
  <Container {...rest}>
    {title && <Title level={3}>{title}</Title>}
    {loading ? <Skeleton /> : children}
  </Container>
)
export default Panel
