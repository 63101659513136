import { useContext, useMemo } from 'react'
import { OrganizationContext } from '../context/organization-context'
import { Role } from '../types/role'

const useRoleChecker = (strict: boolean, ...allowedRoles: Role[]) => {
  const { membership } = useContext(OrganizationContext)

  return useMemo(
    () =>
      strict
        ? allowedRoles.every(role => membership?.role === role)
        : allowedRoles.some(role => membership?.role === role),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [membership, allowedRoles],
  )
}
export default useRoleChecker
