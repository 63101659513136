import Panel from './panel'
import React from 'react'
import { Label, LabelValueContainer } from '../atoms/typography'
import { Col, Row } from 'antd'
import Button from '../atoms/button'
import { PresentationIcon, RocketIcon, UsersIcon } from '../atoms/icon'

type Props = {
  onClickLaunchMvp: () => void
  onClickGeneratePitchDeck: () => void
  onClickInviteMember: () => void
}

const ProjectActionsPanel: React.FC<Props> = ({
  onClickLaunchMvp,
  onClickGeneratePitchDeck,
  onClickInviteMember,
}) => (
  <Panel title="Take actions">
    <Row gutter={22} justify="space-between">
      <Col span={8}>
        <LabelValueContainer>
          <Label>Build a MVP</Label>
          <p>
            Cogo instantly scaffolds your software, providing a powerful
            toolbox. Receive contextual artifacts tailored to your project in no
            time.
          </p>
        </LabelValueContainer>
        <Button
          analyticsId="project_actions_launch_mvp"
          icon={<RocketIcon style={{ color: 'black' }} />}
          label="Launch your MVP"
          onClick={onClickLaunchMvp}
        />
      </Col>
      <Col span={8}>
        <LabelValueContainer>
          <Label>Generate a Pitch Deck</Label>
          <p>
            Using this Market Analysis, generate a pitch deck to convey your
            vision, attract backers, provide a roadmap, and validate your idea.
          </p>
        </LabelValueContainer>
        <Button
          analyticsId="project_actions_generate_pitch_deck"
          icon={<PresentationIcon style={{ color: 'black' }} />}
          label="Generate a Pitch Deck"
          onClick={onClickGeneratePitchDeck}
        />
      </Col>
      <Col span={8}>
        <LabelValueContainer>
          <Label>Build your team</Label>
          <p>
            Building solo is tough. Bring in a team member and amplify your
            vision together. Two minds are better than one.
          </p>
        </LabelValueContainer>
        <Button
          analyticsId="project_actions_invite_member"
          icon={<UsersIcon style={{ color: 'black' }} />}
          label="Invite a team member"
          onClick={onClickInviteMember}
        />
      </Col>
    </Row>
  </Panel>
)

export default ProjectActionsPanel
