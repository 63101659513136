import { useLazyQuery } from '../useQuery'
import { gql } from '@apollo/client'
import { useEffect, useState } from 'react'
import { FormField } from '../../types/form-field'

const query = gql`
  query ToolExecutionFormFields($identifier: String!, $jsonState: String!) {
    toolExecutionFormFields(identifier: $identifier, jsonState: $jsonState) {
      id
      type
      label
      value
      description
      section
      repeatableGroup
      required
      dependsOn
      requiredPlanLevel
      ... on TextInput {
        regexValidation
        kind
      }
      ... on NumberInput {
        min
        max
      }
      ... on SelectInput {
        options {
          label
          value
        }
      }
      ... on MultiSelectInput {
        options {
          label
          value
        }
      }
      ... on Information {
        informationLevel
      }
      ... on RadioInput {
        options {
          label
          value
        }
      }
    }
  }
`

const requiredPlanBySectionQuery = gql`
  query RequiredPlanLevelBySection($identifier: String!) {
    requiredPlanLevelBySection(identifier: $identifier)
  }
`

export const useToolExecutionFormFields = (
  identifier?: String,
  currentState: Record<string, unknown> = {},
) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<FormField[]>()
  const [fetch] = useLazyQuery<{ toolExecutionFormFields: FormField[] }>(query)
  const jsonState = JSON.stringify(currentState)

  useEffect(() => {
    if (identifier) {
      setLoading(true)
      fetch({ variables: { identifier, jsonState } })
        .then(res => setData(res.data?.toolExecutionFormFields))
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier, jsonState])

  const reset = () => setData(undefined)

  return { loading, formFields: data ?? [], resetFormFields: reset }
}

export const useRequiredPlanLevelBySection = (identifier?: string) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<string>()
  const [fetch] = useLazyQuery<{ requiredPlanLevelBySection: string }>(
    requiredPlanBySectionQuery,
  )

  useEffect(() => {
    if (identifier) {
      setLoading(true)
      fetch({ variables: { identifier } })
        .then(res => setData(res.data?.requiredPlanLevelBySection))
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier])
  const reset = () => setData(undefined)
  return {
    loading,
    requiredPlanSections: data ?? '{}',
    resetRequiredPlanLevels: reset,
  }
}
