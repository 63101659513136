import { gql } from '@apollo/client'
import useQuery from '../useQuery'
import useMutation from '../useMutation'
import { Page } from '../../types/pageable'
import {
  ConnectionUpdate,
  ConnectionsFilter,
  NewConnection,
  OrganizationConnection,
} from '../../types/connection'

const query = gql`
  query OrganizationConnections($filter: ConnectionsFilterInput!) {
    organizationConnections(filter: $filter) {
      data {
        id
        name
        provider
        status
        createdAt
        updatedAt
      }
      next
      previous
    }
  }
`

const awsRegionsQuery = gql`
  query AwsRegions {
    awsRegions
  }
`
const createConnectionMutation = gql`
  mutation CreateConnection($connection: NewConnectionInput!) {
    createConnection(new: $connection) {
      id
      name
      credentials
      provider
      status
    }
  }
`
const createGithubConnectionMutation = gql`
  mutation CreateGithubConnection($code: String!, $installationId: String!) {
    createGithubConnection(code: $code, installationId: $installationId) {
      id
      name
      credentials
      provider
      status
    }
  }
`
const connectionByIdQuery = gql`
  query ConnectionById($id: String!) {
    connectionById(id: $id) {
      id
      name
      credentials
      provider
      status
      createdAt
      updatedAt
    }
  }
`
const editConnectionMutation = gql`
  mutation EditConnection($connection: OrganizationConnectionUpdateInput!) {
    editConnection(connection: $connection) {
      id
      name
      credentials
      provider
      status
      createdAt
      updatedAt
    }
  }
`
const archiveConnectionMutation = gql`
  mutation ArchiveConnection($id: String!) {
    archiveConnection(id: $id)
  }
`

export const useOrganizationConnections = (filter: ConnectionsFilter) => {
  const { loading, data, refetch } = useQuery<{
    organizationConnections: Page<OrganizationConnection>
  }>(query, { variables: { filter } })

  return { loading, page: data?.organizationConnections, refetch }
}

export const useAwsRegions = () => {
  const { loading, data } = useQuery<{ awsRegions: String[] }>(awsRegionsQuery)

  return { loading, data: data?.awsRegions }
}

export const useCreateConnection = () => {
  const [mutate] = useMutation<{ createConnection: OrganizationConnection }>(
    createConnectionMutation,
  )

  return (connection: NewConnection) =>
    mutate({ variables: { connection } }).then(
      result => result.data!.createConnection,
    )
}

export const useConnectionById = (id: string) => {
  const { loading, data, refetch } = useQuery<{
    connectionById: OrganizationConnection
  }>(connectionByIdQuery, { variables: { id } })

  return { loading, data: data?.connectionById, refetch: () => refetch() }
}

export const useCreateGithubConnection = () => {
  const [mutate] = useMutation<{
    createGithubConnection: OrganizationConnection
  }>(createGithubConnectionMutation)

  return (code: string, installationId: String) =>
    mutate({ variables: { code, installationId } }).then(
      result => result.data!.createGithubConnection,
    )
}

export const useEditConnection = () => {
  const [mutate] = useMutation<{
    editConnection: OrganizationConnection
  }>(editConnectionMutation)

  return (connection: ConnectionUpdate) =>
    mutate({ variables: { connection } }).then(
      result => result.data!.editConnection,
    )
}

export const useArchiveConnection = () => {
  const [mutate] = useMutation<{
    archiveConnection: boolean
  }>(archiveConnectionMutation)

  return (id: string) =>
    mutate({ variables: { id } }).then(result => result.data!.archiveConnection)
}
