import Select from '../molecules/select'
import { Avatar, Col, Divider, Row, Space, Typography } from 'antd'
import Button from '../atoms/button'
import { InboxOutlined } from '@ant-design/icons'
import React from 'react'
import { Project } from '../../types/project'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const OptionContainer = styled(Space)`
  width: 100%;

  .ant-space-item {
    width: 100%;
  }

  a {
    opacity: 0;
    color: white;
    transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
  }

  :hover {
    a {
      opacity: 1;
    }
  }
`

type Props = {
  projects: Project[]
  project?: Project
  onProjectChange: (id: string) => void
}

const ProjectSelector: React.FC<Props> = ({
  project,
  projects,
  onProjectChange,
}) => (
  <Select
    value={project?.id}
    onChange={val => onProjectChange(val as string)}
    style={{ width: '100%' }}
    dropdownRender={menu => (
      <>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <Button
          analyticsId="create_new_project"
          label="+ create new project"
          type="text"
          href="/organization/projects/new"
        />
      </>
    )}
    options={projects.map(project => ({
      value: project.id,
      label: (
        <OptionContainer direction="horizontal">
          <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            <Col>
              <Space direction="horizontal">
                <Avatar
                  size="small"
                  src={project?.avatar}
                  icon={<InboxOutlined />}
                />
                <Typography.Title level={4}>{project?.name}</Typography.Title>
              </Space>
            </Col>
            <Col>
              <Link
                to={`/organization/projects/${project.id}`}
                onClick={e => e.stopPropagation()}
              >
                view
              </Link>
            </Col>
          </Row>
        </OptionContainer>
      ),
    }))}
  />
)

export default ProjectSelector
