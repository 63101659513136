import moment from 'moment-timezone'

const LOCAL_TIMEZONE = moment.tz.guess()

export const formatTimestamp = (timestamp: Date): string =>
  moment(timestamp).tz(LOCAL_TIMEZONE).format('MMM D YYYY h:mm A z')

export const formatDate = (timestamp: Date | string): string =>
  moment(timestamp).tz(LOCAL_TIMEZONE).format('MMM D YYYY')

export const formatDateAtTime = (timestamp: Date): string =>
  moment(timestamp).format('MMMM DD, YYYY [at] hh:mm:ss A')
