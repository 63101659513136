import { gql } from '@apollo/client'
import useMutation from '../useMutation'
import { noop } from '../../utils/utils'
import {
  NewAccountInput,
  NewOrganization,
  NewOrganizationMemberInput,
  Organization,
  OrganizationMember,
  OrganizationMembersFilter,
  OrganizationMemberUpdate,
  OrganizationUpdate,
} from '../../types/organization'
import useQuery from '../useQuery'
import { Page } from '../../types/pageable'
import { PlanUsage } from '../../types/plan'

const createMutation = gql`
  mutation CreateOrganization($organization: NewOrganizationInput!) {
    createOrganization(organization: $organization) {
      id
      name
    }
  }
`

const editMutation = gql`
  mutation EditOrganization($organization: OrganizationUpdateInput!) {
    editOrganization(organization: $organization) {
      id
      name
    }
  }
`

const billingSessionQuery = gql`
  query BillingSession {
    billingSession
  }
`

const planUsageQuery = gql`
  query PlanUsage {
    planUsage {
      id
      organizationId
      usageMonth
      usageYear
      seats
      projects
      projectAnalysisUpdates
      githubConnections
      cloudConnections
      toolExecutions
      createdAt
      updatedAt
    }
  }
`

const membersQuery = gql`
  query OrganizationMembers($filter: OrganizationMembersFilterInput!) {
    organizationMembers(filter: $filter) {
      data {
        id {
          identifier
        }
        role
        status
        title
        bio
        linkedin
        twitter
        createdAt
        updatedAt
      }
      next
      previous
    }
  }
`
const memberIdQuery = gql`
  query MemberById($identifier: String!) {
    memberById(identifier: $identifier) {
      name
      avatar
      title
      bio
      status
      role
      linkedin
      twitter
    }
  }
`

const invitationQuery = gql`
  query Invitation($token: String!) {
    invitation(token: $token) {
      id {
        identifier
      }
      organization {
        name
      }
    }
  }
`

const editMemberMutation = gql`
  mutation EditMemberMutation($member: OrganizationMemberUpdateInput!) {
    editMember(member: $member) {
      id {
        identifier
      }
      role
      status
      bio
      twitter
      createdAt
      updatedAt
    }
  }
`

const inviteMemberMutation = gql`
  mutation InviteMember($member: NewOrganizationMemberInput!) {
    inviteMember(member: $member)
  }
`

const acceptOrganizationInviteMutation = gql`
  mutation AcceptOrganizationInvite(
    $token: String!
    $account: NewAccountInput
  ) {
    acceptOrganizationInvite(token: $token, account: $account)
  }
`

const declineOrganizationInviteMutation = gql`
  mutation DeclineOrganizationInvite($token: String!) {
    declineOrganizationInvite(token: $token)
  }
`

export const useCreateOrganization = () => {
  const [mutate] = useMutation<{ createOrganization: Organization }>(
    createMutation,
  )

  return (organization: NewOrganization) =>
    mutate({ variables: { organization } }).then(() => noop())
}

export const useEditOrganization = () => {
  const [mutate] = useMutation<{ editOrganization: Organization }>(editMutation)

  return (organization: OrganizationUpdate) =>
    mutate({ variables: { organization } }).then(() => noop())
}

export const useBillingSession = () => {
  const { loading, data, refetch } = useQuery<{ billingSession: string }>(
    billingSessionQuery,
  )

  return { loading, data: data?.billingSession, refetch }
}

export const usePlanUsage = () => {
  const { loading, data, refetch } = useQuery<{ planUsage: PlanUsage }>(
    planUsageQuery,
  )

  return { loading, data: data?.planUsage, refetch }
}

export const useEditMember = () => {
  const [mutate] = useMutation<{ editMember: OrganizationMember }>(
    editMemberMutation,
  )

  return (member: OrganizationMemberUpdate) =>
    mutate({ variables: { member } }).then(() => noop())
}

export const useOrganizationMembers = (filter: OrganizationMembersFilter) => {
  const { loading, data, refetch } = useQuery<{
    organizationMembers: Page<OrganizationMember>
  }>(membersQuery, { variables: { filter } })

  return { loading, page: data?.organizationMembers, refetch }
}

export const useMemberById = (orgId: string, identifier: string) => {
  const { loading, data, refetch } = useQuery<{
    memberById: OrganizationMember
  }>(memberIdQuery, { variables: { orgId, identifier } })

  return { loading, data: data?.memberById, refetch: () => refetch() }
}

export const useInvitation = (token: string) => {
  const { loading, data, refetch, error } = useQuery<{
    invitation: OrganizationMember
  }>(invitationQuery, { variables: { token } })

  return { loading, data: data?.invitation, refetch: () => refetch(), error }
}

export const useInviteMember = () => {
  const [mutate] = useMutation<{ inviteMember: boolean }>(inviteMemberMutation)

  return (member: NewOrganizationMemberInput) =>
    mutate({ variables: { member } }).then(result => result.data!.inviteMember)
}

export const useAcceptOrganizationInvite = () => {
  const [mutate] = useMutation<{ acceptOrganizationInvite: boolean }>(
    acceptOrganizationInviteMutation,
  )

  return (token: string, account: NewAccountInput) =>
    mutate({ variables: { token, account } }).then(
      result => result.data!.acceptOrganizationInvite,
    )
}

export const useDeclineOrganizationInvite = () => {
  const [mutate] = useMutation<{ declineOrganizationInvite: boolean }>(
    declineOrganizationInviteMutation,
  )

  return (token: string) =>
    mutate({ variables: { token } }).then(
      result => result.data!.declineOrganizationInvite,
    )
}
