import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Page, PageContent, PageHeader } from '../templates/page'
import { Col, Collapse, Empty, Row, Spin, Typography } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form'
import { Input } from '../atoms/input'
import XhrPending from '../atoms/xhr-pending'
import ExecutionHeader from '../organism/tool-execution-header'
import ExecutionDrawer from '../organism/tool-execution-drawer'
import Header from '../atoms/header'
import ArtifactsPanel from '../organism/artifact-panel'
import LogsPanel from '../organism/logs-panel'
import Panel from '../organism/panel'
import Title from 'antd/lib/typography/Title'
import { useQueryParam } from '../../hooks/useQueryParam'
import { ProjectContext } from '../../context/project-context'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useUsedTools } from '../../hooks/tool/useTools'
import {
  useCreateToolExecution,
  useToolExecutions,
} from '../../hooks/tool/useToolExecution'
import {
  useToolExecutionFormFields,
  useRequiredPlanLevelBySection,
} from '../../hooks/tool/useToolExecutionFormFields'
import { Tool, ToolExecution } from '../../types/tool'
import Breadcrumb from '../molecules/breadcrumb'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import useDebounce from '../../hooks/useDebounce'
import Spacer from '../atoms/spacer'
import { useBoolean } from '../../hooks/useBoolean'
import ProjectSelector from '../organism/project-selector'

const ToolPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`
const ToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`
const ToolCard = styled(Panel)<{ selected: boolean }>`
  cursor: pointer;
  padding: 12px;
  border: ${props => (props.selected ? '1px solid #8996da' : 'unset')};
  background: ${props =>
    props.selected ? 'rgba(137, 150, 218, 0.25)' : 'unset'};
  :hover {
    background: rgba(137, 150, 218, 0.25);
  }
`

const ExecutionsPanel = styled(Collapse.Panel)`
  border-bottom: 1px solid #242735 !important;
  &.selected {
    border-radius: 10px;
    background: #1d2031;
  }
`

const ExecutionOutputPanel = styled(Collapse.Panel)`
  .ant-collapse-content {
    background: #242735 !important;
    border-radius: 10px !important;
  }
`

const NoTools = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

const ToolExecutionsActivity: React.FC = () => {
  useDocumentTitle('Toolbox - Activity')
  const { projects, project, setProjectId } = useContext(ProjectContext)
  const { push } = useHistory()

  //  Tools
  const [toolsAfter, setToolsAfter] = useState<number>()
  const [tools, setTools] = useState<Tool[]>([])
  const [toolsQuery, setToolsQuery] = useState<string>('')
  const debouncedToolsQuery = useDebounce<string>(toolsQuery, 300)
  const { loading: toolsLoading, page: toolsPage } = useUsedTools({
    after: toolsAfter,
    query: debouncedToolsQuery,
  })
  const toolsHasNextPage = (toolsPage?.next ?? 0) > 0
  const [toolIdQuery, setToolIdQuery] = useQueryParam<string>(
    'too',
    undefined,
    true,
  )
  const [toolId, setToolId] = useState<string>(toolIdQuery)
  const [currentTool, setCurrentTool] = useState<Tool>(
    tools.find(tool => tool.id === toolId)!,
  )

  useEffect(() => {
    setToolExecutionId(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolId])

  useEffect(() => {
    if (toolsPage) {
      const data = toolsPage?.data ?? []
      setTools(prev => (toolsAfter ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolsPage])

  useEffect(() => {
    if (!toolId && tools.length > 0) {
      setToolId(tools[0].id)
    }

    if (!currentTool && tools.length > 0) {
      setCurrentTool(tools.find(tool => tool.id === toolId)!)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tools, toolId])

  const [sentryRefTools] = useInfiniteScroll({
    loading: toolsLoading,
    hasNextPage: toolsHasNextPage,
    onLoadMore: () => setToolsAfter(toolsPage?.next),
    disabled: !toolsLoading && !toolsPage,
    rootMargin: '0px 0px 400px 0px',
  })

  // ToolExecutions
  const [executionsAfter, setExecutionsAfter] = useState<number>()
  const [toolExecutions, setToolExecutions] = useState<ToolExecution[]>([])
  const [executionsQuery, setExecutionsQuery] = useState<string>('')
  const debouncedExecutionsQuery = useDebounce<string>(executionsQuery, 300)
  const {
    loading: executionsLoading,
    page: executionsPage,
    refetch: refetchToolExecutions,
  } = useToolExecutions(toolId, {
    after: executionsAfter,
    query: debouncedExecutionsQuery,
  })
  const executionsHasNextPage = (executionsPage?.next ?? 0) > 0
  const [toolExecutionId, setToolExecutionId] = useState<string | string[]>()
  const createExecution = useCreateToolExecution()
  const [xhrPending, toggleXhrPending] = useBoolean()

  // Fork
  const [executingTool, setExecutingTool] = useState<Tool>()
  const [formState, setFormState] = useState<Record<string, unknown>>({})
  const {
    formFields,
    loading: formFieldsLoading,
    resetFormFields,
  } = useToolExecutionFormFields(currentTool?.identifier, formState)
  const {
    loading: requiredSectionsLoading,
    requiredPlanSections,
    resetRequiredPlanLevels,
  } = useRequiredPlanLevelBySection(currentTool?.identifier)
  const formRef = useRef<FormInstance>(null)
  const [executionName, setExecutionName] = useState<string>('')
  const [forkedExecutionId, setForkedExecutionId] = useState<string>()

  useEffect(() => {
    if (executionsPage) {
      const data = executionsPage?.data ?? []
      setToolExecutions(prev => (executionsAfter ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executionsPage])

  useEffect(() => {
    if (!toolExecutionId && toolExecutions.length > 0) {
      setToolExecutionId(toolExecutions[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolExecutions])

  const [sentryRefExecutions] = useInfiniteScroll({
    loading: executionsLoading,
    hasNextPage: executionsHasNextPage,
    onLoadMore: () => setExecutionsAfter(executionsPage?.next),
    disabled: !executionsLoading && !executionsPage,
    rootMargin: '0px 0px 400px 0px',
  })

  const runExecution = (values: Record<string, unknown>) => {
    toggleXhrPending()
    createExecution(currentTool!.id, values, executionName, forkedExecutionId)
      .then(result => {
        push(
          `/tools/activity?pro=${project?.id}&too=${currentTool?.id}&tei=${result.id}`,
        )
      })
      .finally(toggleXhrPending)
  }

  const reRunExecution = (execution: ToolExecution) => {
    setExecutingTool(currentTool)
    setForkedExecutionId(execution.id)

    const inputObj = JSON.parse(execution.input as string)
    const result: Record<string, unknown> = {}

    formFields.forEach(field => {
      result[field.id] =
        inputObj[field.id] !== undefined ? inputObj[field.id] : null
    })

    setFormState(result)
  }

  const closeExecutionForm = () => {
    refetchToolExecutions()
    setExecutingTool(undefined)
    setFormState({})
    resetFormFields()
    resetRequiredPlanLevels()
  }

  return (
    <Page>
      <PageHeader>
        <Breadcrumb
          routes={[
            { path: '../', breadcrumbName: 'Toolbox' },
            { path: '/', breadcrumbName: 'Activity' },
          ]}
        />
        <Col span={6}>
          <ProjectSelector
            projects={projects}
            project={project}
            onProjectChange={setProjectId}
          />
        </Col>
      </PageHeader>
      <PageContent>
        <XhrPending pending={xhrPending}>
          <Row justify="space-between" gutter={24}>
            {/* tools */}
            <Col span={6}>
              <ToolPanel>
                <Input
                  prefix={<SearchOutlined />}
                  value={toolsQuery}
                  onChange={e => setToolsQuery(e.target.value)}
                  placeholder="Search your used tools..."
                  allowClear
                />
                <ToolsContainer>
                  {!toolsLoading && tools.length === 0 && NoTools}
                  {tools.map(tool => (
                    <ToolCard
                      key={tool.id}
                      onClick={() => {
                        setCurrentTool(tool)
                        setToolId(tool.id)
                        setToolIdQuery(tool.id)
                      }}
                      selected={toolId === tool.id}
                    >
                      <Typography.Text>{tool.name}</Typography.Text>
                    </ToolCard>
                  ))}
                  {toolsHasNextPage && <div ref={sentryRefTools} />}
                </ToolsContainer>
              </ToolPanel>
            </Col>

            {/* executions */}
            <Col span={18}>
              <Panel>
                <Header>
                  <Col>
                    <Title level={4}>Executions</Title>
                  </Col>
                  <Col>
                    <Input
                      prefix={<SearchOutlined />}
                      value={executionsQuery}
                      onChange={e => setExecutionsQuery(e.target.value)}
                      placeholder="Search executions..."
                      allowClear
                    />
                  </Col>
                </Header>
                {!tools.length ||
                (!executionsLoading && toolExecutions.length === 0) ? (
                  NoTools
                ) : (
                  <Collapse
                    accordion
                    ghost
                    activeKey={toolExecutionId}
                    onChange={setToolExecutionId}
                  >
                    {toolExecutions.map(execution => {
                      const output = JSON.parse(execution.output as string)
                      return (
                        <ExecutionsPanel
                          key={execution.id}
                          header={
                            <ExecutionHeader
                              execution={execution}
                              onClickRerun={reRunExecution}
                            />
                          }
                          showArrow={false}
                          className={`execution-panel ${
                            toolExecutionId === execution.id ? 'selected' : ''
                          }`}
                        >
                          <Collapse ghost>
                            <ExecutionOutputPanel
                              key="logs"
                              header={<Title level={4}>Logs</Title>}
                            >
                              <LogsPanel logs={output?.logs ?? []} />
                            </ExecutionOutputPanel>
                            <ExecutionOutputPanel
                              key="artifacts"
                              header={<Title level={4}>Artifacts</Title>}
                            >
                              <ArtifactsPanel
                                artifacts={output?.artifacts ?? []}
                              />
                            </ExecutionOutputPanel>
                          </Collapse>
                        </ExecutionsPanel>
                      )
                    })}
                    {toolsHasNextPage && <div ref={sentryRefExecutions} />}
                    {executionsLoading && toolExecutions.length > 0 && (
                      <Spin spinning>
                        <Spacer />
                      </Spin>
                    )}
                  </Collapse>
                )}
              </Panel>
            </Col>
          </Row>
          {!!executingTool && (
            <ExecutionDrawer
              executingTool={executingTool}
              executionName={executionName}
              onExecutionNameChange={setExecutionName}
              onExecutionFormSubmit={() => formRef.current?.submit()}
              onCloseExecutionForm={closeExecutionForm}
              xhrPending={xhrPending}
              loading={formFieldsLoading || requiredSectionsLoading}
              formFields={formFields}
              formRef={formRef}
              requiredPlanSections={requiredPlanSections}
              onClickRunExecution={runExecution}
              formState={formState}
              onFormStateChange={setFormState}
            />
          )}
        </XhrPending>
      </PageContent>
    </Page>
  )
}

export default ToolExecutionsActivity
