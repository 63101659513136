import { Select as AntSelect } from 'antd'
import styled from 'styled-components'

const Select = styled(AntSelect)`
  > .ant-select-selector {
    padding: 8px 16px !important;
    border-radius: 10px !important;
    height: unset !important;
  }
`
export default Select
