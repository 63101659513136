import { useEffect, useState } from 'react'

const useDebounce = <T>(value: T, delayMilliseconds: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delayMilliseconds)

    return () => {
      clearTimeout(handler)
    }
  }, [delayMilliseconds, value])
  return debouncedValue
}

export default useDebounce
