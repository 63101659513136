import React, {
  Fragment,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react'
import {
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import {
  FormField,
  FormFieldType,
  InfoLevel,
  Information,
  NumberInput,
  RadioInput,
  SelectInput,
  TextInput,
  TextInputKind,
} from '../../types/form-field'
import Loading from '../atoms/loading'
import Button from '../atoms/button'
import { WarningIcon, LockIcon } from '../atoms/icon'
import _ from 'lodash'
import Spacer from '../atoms/spacer'
import { FormInstance } from 'antd/lib/form'
import Panel from './panel'
import DeleteButton from '../atoms/delete-button'
import { Textarea } from '../atoms/input'
import { OrganizationContext } from '../../context/organization-context'
import { LabelTooltipType } from 'antd/lib/form/FormItemLabel'
import { Trans } from 'react-i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { t } from 'i18next'

const { Option } = Select

const InfoIcons: Record<InfoLevel, React.ReactNode> = {
  WARNING: <WarningIcon />,
}

type DynamicFormProps = {
  formId: string
  fields: FormField[]
  onSubmit: (values: Record<string, unknown>) => void
  onChange: (values: Record<string, unknown>) => void
  submitLabel?: string
  loading?: boolean
  initialValues?: Record<string, unknown>
  formRef?: React.RefObject<FormInstance>
  requiredPlanSections: string
}

const toFormItem = (
  field: FormField,
  namePath: string[],
  planLevel: number,
  overrideDisabled?: boolean,
) => {
  const disabled =
    overrideDisabled ||
    (!!field.requiredPlanLevel && planLevel < field.requiredPlanLevel)
  const tooltip: LabelTooltipType = {
    title: field.description ? (
      <Row gutter={[0, 6]}>
        <Col>{field.description}</Col>
        {disabled && (
          <Col>
            <Typography.Title level={5}>
              <Trans
                i18nKey="upgrade_plan"
                components={{
                  1: <Typography.Link href="/organization/billing?plan=team" />,
                }}
              />
            </Typography.Title>
          </Col>
        )}
      </Row>
    ) : (
      disabled && (
        <Trans
          i18nKey="upgrade_plan"
          components={{
            1: <Typography.Link href="/organization/billing?plan=team" />,
          }}
        />
      )
    ),
    placement: 'right',
    icon: disabled ? <LockIcon /> : undefined,
  }

  switch (field.type) {
    case FormFieldType.Text:
      const textInput = field as TextInput
      return (
        <Form.Item
          key={field.id}
          name={namePath}
          label={field.label}
          tooltip={(field.description || disabled) && tooltip}
          rules={[
            {
              required: field.required,
              message: `${field.label} is required`,
            },
            {
              pattern: new RegExp(textInput.regexValidation || ''),
              message: 'Invalid format',
            },
          ]}
        >
          {textInput.kind === TextInputKind.Password ? (
            <Input.Password disabled={disabled} />
          ) : textInput.kind === TextInputKind.TextArea ? (
            <Textarea
              disabled={disabled}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          ) : (
            <Input disabled={disabled} type={textInput.kind || 'text'} />
          )}
        </Form.Item>
      )
    case FormFieldType.Number:
      const numberField = field as NumberInput
      return (
        <Form.Item
          key={field.id}
          name={namePath}
          label={field.label}
          tooltip={(field.description || disabled) && tooltip}
          rules={[
            {
              required: field.required,
              message: `${field.label} is required`,
            },
          ]}
        >
          <InputNumber
            disabled={disabled}
            min={numberField.min}
            max={numberField.max}
          />
        </Form.Item>
      )
    case FormFieldType.Select:
      return (
        <Form.Item
          key={field.id}
          name={namePath}
          label={field.label}
          tooltip={(field.description || disabled) && tooltip}
          rules={[
            {
              required: field.required,
              message: `${field.label} is required`,
            },
          ]}
        >
          <Select allowClear={!field.required} disabled={disabled}>
            {(field as SelectInput<unknown>).options.map((option, index) => (
              <Option key={index} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )

    case FormFieldType.MultiSelect:
      return (
        <Form.Item
          key={field.id}
          name={namePath}
          label={field.label}
          tooltip={(field.description || disabled) && tooltip}
          rules={[
            {
              required: field.required,
              message: `${field.label} is required`,
            },
          ]}
        >
          <Select
            mode="multiple"
            showArrow
            allowClear={!field.required}
            disabled={disabled}
          >
            {(field as SelectInput<unknown>).options.map((option, index) => (
              <Option key={index} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )

    case FormFieldType.Information:
      const info = field as Information
      return (
        <Form.Item key={info.id}>
          <Space direction="horizontal" size="middle">
            {info.informationLevel ? InfoIcons[info.informationLevel] : null}
            <Typography.Text>{info.description}</Typography.Text>
          </Space>
        </Form.Item>
      )

    case FormFieldType.Radio:
      return (
        <Form.Item
          key={field.id}
          name={namePath}
          label={field.label}
          tooltip={(field.description || disabled) && tooltip}
          rules={[
            {
              required: field.required,
              message: `${field.label} is required`,
            },
          ]}
        >
          <Radio.Group disabled={disabled}>
            {(field as RadioInput<unknown>).options.map((option, index) => (
              <Radio key={index} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      )
    case FormFieldType.Checkbox:
      return (
        <Form.Item
          key={field.id}
          name={namePath}
          valuePropName="checked"
          initialValue={field.value?.toLowerCase() === 'true'}
        >
          <Checkbox disabled={disabled}>{field.label}</Checkbox>
          <Tooltip
            title={(field.description || disabled) && tooltip.title}
            placement={tooltip.placement}
          >
            {disabled ? (
              <LockIcon style={{ color: 'rgba(255, 255, 255, 0.45)' }} />
            ) : (
              field.description && (
                <QuestionCircleOutlined
                  style={{ color: 'rgba(255, 255, 255, 0.45)' }}
                />
              )
            )}
          </Tooltip>
        </Form.Item>
      )

    default:
      return null
  }
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  formId,
  loading,
  fields,
  onSubmit,
  onChange,
  initialValues,
  submitLabel = 'Submit',
  formRef,
  requiredPlanSections,
}) => {
  const { membership } = useContext(OrganizationContext)
  const [form] = Form.useForm()
  const [activeKeys, setActiveKeys] = useState<string | string[]>([])
  const fieldsBySection: Record<string, FormField[]> = useMemo(
    () =>
      _.groupBy(
        fields.filter(({ section }) => !!section),
        'section',
      ),
    [fields],
  )
  const planSections: Record<string, number> = JSON.parse(requiredPlanSections)

  useEffect(() => {
    if (!activeKeys.length) {
      const firstKey = _.keysIn(fieldsBySection)[0]
      if (firstKey) setActiveKeys([firstKey])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])

  // initialize repeatable groups
  useEffect(() => {
    const repeatableGroupNames = _.uniq(
      fields
        .filter(field => field.repeatableGroup)
        .map(field => field.repeatableGroup),
    )

    const updatedInitialValues: Record<string, unknown> = { ...initialValues }

    repeatableGroupNames.forEach(group => {
      if (!group) return
      if (!initialValues?.[group] || !Array.isArray(initialValues?.[group])) {
        const initialGroupValue: any = {}

        fields
          .filter(field => field.repeatableGroup === group)
          .forEach(field => {
            if (field.value !== undefined) {
              initialGroupValue[field.id] = field.value
            }
          })

        updatedInitialValues[group] = [initialGroupValue]
      }
    })

    form.setFieldsValue(updatedInitialValues)
  }, [fields, form, initialValues])

  return loading ? (
    <Loading />
  ) : (
    <Form
      id={formId}
      form={form}
      layout="vertical"
      onValuesChange={(_, values) => onChange(values)}
      onFinish={onSubmit}
      initialValues={initialValues}
      ref={formRef}
    >
      {/* Non-repeatable and Non-section fields */}
      {fields
        .filter(({ section, repeatableGroup }) => !section && !repeatableGroup)
        .map(field =>
          toFormItem(field, [field.id], membership!.organization.plan.level),
        )}

      {/* Section fields including repeatable groups */}
      <Collapse
        activeKey={activeKeys}
        onChange={key => setActiveKeys(key as string[])}
      >
        {Object.entries(fieldsBySection).map(([section, sectionFields]) => {
          const disabled =
            !!planSections[section] &&
            membership!.organization.plan.level < planSections[section]
          return (
            <Collapse.Panel
              key={section}
              header={
                <Row align="middle" gutter={6}>
                  <Col>{section}</Col>
                  {disabled && (
                    <Col>
                      <LockIcon
                        style={{ color: 'rgba(255, 255, 255, 0.45)' }}
                      />
                    </Col>
                  )}
                </Row>
              }
            >
              {disabled && (
                <>
                  <Typography.Title level={5}>
                    <div>{t('premium_perk')}</div>
                    <Trans
                      i18nKey="unlock_module"
                      components={{
                        1: (
                          <Typography.Link href="/organization/billing?plan=team" />
                        ),
                      }}
                    />
                  </Typography.Title>
                  <Spacer />
                </>
              )}

              {/* Non-repeatable fields in this section */}
              {sectionFields
                .filter(field => !field.repeatableGroup)
                .map(field =>
                  toFormItem(
                    field,
                    [field.id],
                    membership!.organization.plan.level,
                    disabled,
                  ),
                )}

              {/* Repeatable groups in this section */}
              {_.uniq(sectionFields.map(field => field.repeatableGroup))
                .filter(g => !!g)
                .map(group => (
                  <Form.List key={group!} name={group!}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name }) => (
                          <Fragment key={key}>
                            <Panel>
                              <Row justify="end">
                                <Col>
                                  <DeleteButton onClick={() => remove(name)} />
                                </Col>
                              </Row>
                              <div>
                                {sectionFields
                                  .filter(
                                    field => field.repeatableGroup === group,
                                  )
                                  .map(field =>
                                    // @ts-ignore
                                    toFormItem(
                                      field,
                                      [name.toString(), field.id],
                                      membership!.organization.plan.level,
                                      disabled,
                                    ),
                                  )}
                              </div>
                            </Panel>
                            <Spacer />
                          </Fragment>
                        ))}
                        <Button
                          analyticsId={`${formId}_add_${group}`}
                          label="Add"
                          type="dashed"
                          onClick={() => add()}
                        />
                      </>
                    )}
                  </Form.List>
                ))}
            </Collapse.Panel>
          )
        })}
      </Collapse>

      <Spacer size="large" />
      {!formRef && (
        <Form.Item>
          <Button analyticsId={`launch_${formId}`} label={submitLabel} />
        </Form.Item>
      )}
    </Form>
  )
}

export default DynamicForm
