import React from 'react'
import { Page, PageContent, PageHeader } from '../templates/page'
import { Typography } from 'antd'
import RouteAwareTabs from '../organism/route-aware-tabs'
import { OrganizationSettingsSubRoutes } from '../../routes/main-routes'

const { Title } = Typography

const OrganizationSettingsPage = () => (
  <Page>
    <PageHeader>
      <Title level={2}>Organization settings</Title>
    </PageHeader>
    <PageContent>
      <RouteAwareTabs routes={OrganizationSettingsSubRoutes} />
    </PageContent>
  </Page>
)

export default OrganizationSettingsPage
