import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Panel from '../organism/panel'
import { useBoolean } from '../../hooks/useBoolean'
import { Page, PageContent, PageHeader } from '../templates/page'
import useFileUpload from '../../hooks/useFileUpload'
import ImageUpload from '../molecules/img-upload'
import { Alert, Col, Dropdown, Row, Space, Typography } from 'antd'
import { Input, InputLabelContainer, Textarea } from '../atoms/input'
import { EditableText } from '../atoms/typography'
import { GlobeIcon } from '../atoms/icon'
import _, { isEqual } from 'lodash'
import { extensionFrom, omitUndefined } from '../../utils/utils'
import Button from '../atoms/button'
import moment from 'moment-timezone'
import { Media, MediaCategories } from '../../types/media'
import { Domain } from '../../types/organization'
import PalettesPanel from '../organism/palettes-panel'
import MarketPanel from '../organism/market-panel'
import ProjectCompetitorsPanel from '../organism/project-competitors-panel'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import {
  TwoColumnsContainer,
  TwoColumnsLeftContainer,
  TwoColumnsRightContainer,
} from '../molecules/two-columns'
import {
  useCreateProjectIterationFromPrompt,
  useEditPalette,
  useEditProject,
  usePalettes,
  useProjectById,
  useRestoreIterationMutation,
} from '../../hooks/project/useProject'
import useCompetitors from '../../hooks/organization/useCompetitors'
import { useHistory, useParams } from 'react-router-dom'
import { IconPrefixedContainer } from '../molecules/socials-panel'
import useInput from '../../hooks/useInput'
import ChatBar from '../organism/chat-bar'
import Breadcrumb from '../molecules/breadcrumb'
import {
  Palette,
  PalettePartialChanges,
  PartialChanges,
  Project,
  ProjectIteration,
  ProjectStatuses,
} from '../../types/project'
import ProjectActionsPanel from '../organism/project-actions-panel'
import ProjectHistoryDrawer from '../organism/project-history-drawer'
import Spacer from '../atoms/spacer'
import IterationLabel, {
  AcceptButton,
  RejectButton,
} from '../molecules/iteration-label'
import { t } from 'i18next'
import { Competitor } from '../../types/competitor'

const Container = styled(PageContent)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  &::after {
    content: '';
    height: 24px;
    flex-shrink: 0; // To make sure it doesn't shrink
  }
`

const DomainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const iterationAsProject = (
  iteration?: ProjectIteration,
): Project | undefined =>
  iteration
    ? {
        ...iteration,
        id: iteration.id.projectId,
        currentVersion: iteration.id.version,
        status: ProjectStatuses.Active,
      }
    : undefined

const OrganizationProjectEdit = () => {
  const { push } = useHistory()
  // Project
  const { id } = useParams<{ id: string }>()
  const { data: currentProject, refetch } = useProjectById(id)
  const [xhrPending, toggleXhrPending] = useBoolean()
  const [name, setName] = useInput<string>()
  const [headline, setHeadline] = useInput<string>()
  const [description, setDescription] = useInput<string>()
  const [avatar, setAvatar] = useState<string>()
  const [avatarFile, setAvatarFile] = useState<File>()
  const [problem, setProblem] = useInput<string>()
  const [solution, setSolution] = useInput<string>()
  const [website, setWebsite] = useState<string>()
  const [partialChanges, setPartialChanges] = useState<PartialChanges>()
  const { upload } = useFileUpload()
  const edit = useEditProject()
  const [historyDrawerOpened, toggleHistoryDrawer] = useBoolean(false)
  useDocumentTitle(`Settings - ${currentProject?.name}`, {
    skip: !currentProject,
  })

  // Project Iteration
  const restore = useRestoreIterationMutation()
  const createIteration = useCreateProjectIterationFromPrompt()
  const [viewingIteration, setViewingIteration] = useState<ProjectIteration>()
  const inPreviewMode = useMemo(() => !!viewingIteration, [viewingIteration])
  const project = useMemo(
    () => iterationAsProject(viewingIteration) ?? currentProject,
    [viewingIteration, currentProject],
  )

  // Palette
  const {
    loading,
    page: palettesPage,
    refetch: refetchPalette,
  } = usePalettes({
    projectId: id,
    projectVersion: currentProject?.currentVersion ?? 0,
  })
  const { page: viewingPalettePage, refetch: refetchViewingPalettes } =
    usePalettes({
      projectId: id,
      projectVersion: project?.currentVersion ?? 0,
    })
  const [viewingPalettes, setViewingPalettes] = useState<Palette[]>()
  const [partialDarkModeChanges, setPartialDarkModeChanges] =
    useState<PalettePartialChanges>()
  const [partialLightModeChanges, setPartialLightModeChanges] =
    useState<PalettePartialChanges>()
  const editPalette = useEditPalette()
  const [darkMode, toggleDarkMode] = useBoolean(true)
  const [paletteHasChanged, setPaletteHasChanged] = useState<Boolean>()
  const [paletteValid, setPaletteValid] = useState<Boolean>()
  const [primary, setPrimary] = useState<string>('')
  const [accent, setAccent] = useState<string>('')
  const [background, setBackground] = useState<string>('')
  const activePalette = (palettesPage?.data ?? []).find(
    p => p.id.name === (darkMode ? 'dark' : 'light'),
  )

  const validColorRegex =
    /^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6}|[a-fA-F0-9]{8})$|^rgb(a)?\((\d{1,3}%?,\s*){2}\d{1,3}%?\s*(,\s*\d{1,}(.\d{1,})?)?\)$/

  // Competitors
  const { competitors } = useCompetitors(id, project?.currentVersion ?? 0)
  const [viewingCompetitors, setViewingCompetitors] = useState<Competitor[]>()

  useEffect(() => {
    if (competitors) {
      setViewingCompetitors(competitors)
    }
  }, [competitors])

  const restoreIteration = (iteration: ProjectIteration) => {
    toggleXhrPending()
    restore({
      projectId: id,
      version: iteration.id.version,
    })
      .then(refetch)
      .then(refetchViewingPalettes)
      .finally(() => {
        setViewingIteration(undefined)
        toggleXhrPending()
      })
  }

  const applyPartialChanges = () => {
    toggleXhrPending()
    edit(
      {
        id: currentProject!!.id,
        avatar: partialChanges?.avatar ?? currentProject?.avatar,
        name: partialChanges?.name ?? currentProject?.name,
        headline: partialChanges?.headline ?? currentProject?.headline,
        description: partialChanges?.description ?? currentProject?.description,
        problem: partialChanges?.problem ?? currentProject?.problem,
        solution: partialChanges?.solution ?? currentProject?.solution,
        marketType: partialChanges?.marketType ?? currentProject?.marketType,
        marketIndustry:
          partialChanges?.marketIndustry ?? currentProject?.marketIndustry,
        marketDetails:
          partialChanges?.marketDetails ?? currentProject?.marketDetails,
        marketCustomerSegmentation:
          partialChanges?.marketCustomerSegmentation ??
          currentProject?.marketCustomerSegmentation,
        marketTrend: partialChanges?.marketTrend ?? currentProject?.marketTrend,
        marketGrowth:
          partialChanges?.marketGrowth ?? currentProject?.marketGrowth,
        marketTam: partialChanges?.marketTam ?? currentProject?.marketTam,
      },
      partialChanges?.competitors
        ? partialChanges?.competitors[0].projectVersion
        : undefined,
    )
      .then(refetch)
      .then(p => {
        if (partialDarkModeChanges) {
          const darkPalette = palettesPage?.data.find(x => x.id.name === 'dark')
          editPalette({
            name: 'dark',
            projectId: p.data.projectById.id,
            projectVersion: p.data.projectById.currentVersion,
            primary: partialDarkModeChanges.primary ?? darkPalette?.primary,
            accent: partialDarkModeChanges.accent ?? darkPalette?.accent,
            background:
              partialDarkModeChanges.background ?? darkPalette?.background,
            createIteration: false,
          })
        }
        return p
      })
      .then(p => {
        if (partialLightModeChanges) {
          const lightPalette = palettesPage?.data.find(
            x => x.id.name === 'light',
          )
          editPalette({
            name: 'light',
            projectId: p.data.projectById.id,
            projectVersion: p.data.projectById.currentVersion,
            primary: partialLightModeChanges.primary ?? lightPalette?.primary,
            accent: partialLightModeChanges.accent ?? lightPalette?.accent,
            background:
              partialLightModeChanges.background ?? lightPalette?.background,
            createIteration: false,
          })
        }
      })
      .then(refetchPalette)
      .finally(() => {
        setViewingIteration(undefined)
        setPartialChanges(undefined)
        setPartialDarkModeChanges(undefined)
        setPartialLightModeChanges(undefined)
        toggleXhrPending()
      })
  }

  const viewIteration = (iteration: ProjectIteration) => {
    setViewingIteration(iteration)
    toggleHistoryDrawer()
  }

  const createIterationFromPrompt = (prompt: string) => {
    if (!!project && !!prompt) {
      toggleXhrPending()
      createIteration(id, project.currentVersion, prompt)
        .then(setViewingIteration)
        .finally(toggleXhrPending)
    }
  }

  useEffect(() => {
    if (viewingPalettePage) {
      setViewingPalettes(viewingPalettePage?.data)
    }
  }, [viewingPalettePage])

  useEffect(() => {
    if (activePalette) {
      const colors = {
        primary: primary || activePalette?.primary,
        accent: accent || activePalette?.accent,
        background: background || activePalette?.background,
      }
      setPaletteHasChanged(
        !_.isEqual(colors, {
          primary: activePalette.primary,
          accent: activePalette.accent,
          background: activePalette.background,
        }),
      )
      setPaletteValid(
        Object.values(colors).filter(c => validColorRegex.test(c!)).length ===
          3,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePalette, primary, accent, background])

  const domain = JSON.parse(project?.domain ?? '{}') as Domain

  const updatable = {
    ...project,
    ...omitUndefined({
      name,
      avatar,
      description,
      website,
      headline,
      problem,
      solution,
    }),
  }
  const projectHasChanged = !isEqual(updatable, project) || avatarFile
  const canSave =
    !xhrPending &&
    !inPreviewMode &&
    (projectHasChanged || (paletteHasChanged && paletteValid))

  const save = () => {
    if (!canSave) return

    // build optional update promises
    const uploadNewLogoIfAny = () =>
      avatarFile
        ? upload(
            avatarFile,
            `${project?.id}_logo_${moment.now()}.${extensionFrom(avatarFile)}`,
            MediaCategories.Avatar,
          )
        : Promise.resolve({ url: project?.avatar } as Media)

    const updatePaletteIfNeeded = () =>
      paletteHasChanged && !!activePalette
        ? editPalette({
            projectId: id,
            projectVersion: project?.currentVersion ?? 0,
            name: activePalette.id.name,
            primary: primary || activePalette?.primary,
            accent: accent || activePalette?.accent,
            background: background || activePalette?.background,
            createIteration: true,
          })
            .then(refetchPalette)
            .then(() => {
              setPrimary('')
              setAccent('')
              setBackground('')
            })
        : Promise.resolve()

    toggleXhrPending()

    // trigger mutations
    updatePaletteIfNeeded()
      .then(uploadNewLogoIfAny)
      .then(media =>
        projectHasChanged || avatarFile
          ? edit({
              id,
              avatar: media.url,
              name,
              description,
              website,
              headline,
              problem,
              solution,
            })
          : (Promise.resolve() as Promise<any>),
      )
      .then(() => {
        if (avatarFile) {
          setAvatar(undefined)
          setAvatarFile(undefined)
        }
      })
      .then(refetch)
      .finally(toggleXhrPending)
  }

  const isIterationNew =
    viewingIteration &&
    viewingIteration?.id.version > currentProject!!.currentVersion

  const canApplyAvatar =
    viewingIteration &&
    !partialChanges?.avatar &&
    viewingIteration.avatar !== currentProject?.avatar

  const canApplyName =
    viewingIteration &&
    !partialChanges?.name &&
    viewingIteration.name !== currentProject?.name

  const canApplyHeadline =
    viewingIteration &&
    !partialChanges?.headline &&
    viewingIteration.headline !== currentProject?.headline

  const canApplyDescription =
    viewingIteration &&
    !partialChanges?.description &&
    viewingIteration.description !== currentProject?.description

  const canApplyProblem =
    viewingIteration &&
    !partialChanges?.problem &&
    viewingIteration.problem !== currentProject?.problem

  const canApplySolution =
    viewingIteration &&
    !partialChanges?.solution &&
    viewingIteration.solution !== currentProject?.solution

  return (
    <>
      <Page xhrPending={xhrPending}>
        <PageHeader>
          <Breadcrumb
            routes={[
              { path: '../../organization', breadcrumbName: 'Organization' },
              { path: '/projects', breadcrumbName: 'Projects' },
              { path: '', breadcrumbName: project?.name ?? '' },
            ]}
          />
          {!inPreviewMode && (
            <Space direction="horizontal" align="end" size="large">
              <Button
                analyticsId="update_project"
                label="save"
                onClick={save}
                disabled={!canSave}
              />
            </Space>
          )}
        </PageHeader>
        {inPreviewMode && (
          <Alert
            message={t(
              isIterationNew
                ? 'project_iteration_new'
                : 'project_iteration_old',
            )}
            type="info"
            action={
              <Row align="middle" gutter={2}>
                {isIterationNew ? (
                  !partialChanges &&
                  !partialDarkModeChanges &&
                  !partialLightModeChanges ? (
                    <>
                      <Col>
                        <Button
                          analyticsId="apply_all_iteration_changes"
                          label="accept all"
                          noBackground
                          onClick={() => restoreIteration(viewingIteration!)}
                        />
                      </Col>
                      <Col>
                        <Button
                          analyticsId="reject_all_iteration_changes"
                          label="reject all"
                          noBackground
                          onClick={() => setViewingIteration(undefined)}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col>
                        <Button
                          analyticsId="apply_iteration_changes"
                          label="apply"
                          noBackground
                          onClick={applyPartialChanges}
                        />
                      </Col>
                      <Col>
                        <Button
                          analyticsId="cancel_iteration_changes"
                          label="cancel"
                          noBackground
                          onClick={() => {
                            setViewingIteration(undefined)
                            setPartialChanges(undefined)
                            setPartialDarkModeChanges(undefined)
                            setPartialLightModeChanges(undefined)
                          }}
                        />
                      </Col>
                    </>
                  )
                ) : (
                  <>
                    <Col>
                      <Button
                        analyticsId="restore_iteration_changes"
                        label="restore"
                        noBackground
                        onClick={() => restoreIteration(viewingIteration!)}
                      />
                    </Col>
                    <Col>
                      <Button
                        analyticsId="close_iteration_changes"
                        label="close"
                        noBackground
                        onClick={() => setViewingIteration(undefined)}
                      />
                    </Col>
                  </>
                )}
              </Row>
            }
            style={{
              width: '100%',
              margin: '24px 0',
              background: 'rgba(137, 150, 218, 0.35)',
            }}
          />
        )}
        <Container>
          <TwoColumnsContainer>
            <TwoColumnsLeftContainer>
              <Panel>
                <Row>
                  <Col flex="auto">
                    <ImageUpload
                      url={partialChanges?.avatar ?? avatar ?? project?.avatar}
                      label="Logo"
                      name="project-logo"
                      onUrlChange={setAvatar}
                      onFileChange={setAvatarFile}
                      disabled={inPreviewMode}
                    />
                    {isIterationNew && canApplyAvatar ? (
                      <Space>
                        <AcceptButton
                          onClick={() =>
                            setPartialChanges({
                              ...partialChanges,
                              avatar: viewingIteration?.avatar,
                            })
                          }
                        />
                        <RejectButton
                          onClick={() =>
                            setViewingIteration({
                              ...viewingIteration!!,
                              avatar: currentProject?.avatar,
                            })
                          }
                        />
                      </Space>
                    ) : null}
                  </Col>
                  <Col span={20}>
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{ display: 'flex' }}
                    >
                      <InputLabelContainer>
                        <IterationLabel
                          title="Name"
                          valueUpdated={canApplyName && isIterationNew}
                          onAccept={() => {
                            setPartialChanges({
                              ...partialChanges,
                              name: viewingIteration?.name,
                            })
                          }}
                          onCancel={() =>
                            setViewingIteration({
                              ...viewingIteration!!,
                              name: currentProject!!.name,
                            })
                          }
                        />
                        <Input
                          value={partialChanges?.name ?? name ?? project?.name}
                          onChange={setName}
                          readOnly={inPreviewMode}
                          $highlight={canApplyName}
                          maxLength={32}
                          showCount
                          allowClear
                          required
                        />
                      </InputLabelContainer>
                      <InputLabelContainer>
                        <IterationLabel
                          title="Headline"
                          valueUpdated={canApplyHeadline && isIterationNew}
                          onAccept={() => {
                            setPartialChanges({
                              ...partialChanges,
                              headline: viewingIteration?.headline,
                            })
                          }}
                          onCancel={() =>
                            setViewingIteration({
                              ...viewingIteration!!,
                              headline: currentProject?.headline,
                            })
                          }
                        />
                        <Input
                          value={
                            partialChanges?.headline ??
                            headline ??
                            project?.headline
                          }
                          placeholder="Your one liner"
                          onChange={setHeadline}
                          readOnly={inPreviewMode}
                          $highlight={canApplyHeadline}
                          allowClear
                          required
                        />
                      </InputLabelContainer>
                      <InputLabelContainer>
                        <IterationLabel
                          title="Description"
                          valueUpdated={canApplyDescription && isIterationNew}
                          onAccept={() => {
                            setPartialChanges({
                              ...partialChanges,
                              description: viewingIteration?.description,
                            })
                          }}
                          onCancel={() =>
                            setViewingIteration({
                              ...viewingIteration!!,
                              description: currentProject?.description,
                            })
                          }
                        />
                        <Textarea
                          value={
                            partialChanges?.description ??
                            description ??
                            project?.description
                          }
                          placeholder="A story, problem and/or solution statement"
                          onChange={setDescription}
                          $highlight={canApplyDescription}
                          readOnly={inPreviewMode}
                          autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                      </InputLabelContainer>
                      <Row justify="space-between" gutter={12}>
                        <Col flex="auto">
                          <InputLabelContainer>
                            <IterationLabel
                              title="Problem"
                              valueUpdated={canApplyProblem && isIterationNew}
                              onAccept={() => {
                                setPartialChanges({
                                  ...partialChanges,
                                  problem: viewingIteration?.problem,
                                })
                              }}
                              onCancel={() =>
                                setViewingIteration({
                                  ...viewingIteration!!,
                                  problem: currentProject?.problem,
                                })
                              }
                            />
                            <Textarea
                              value={
                                partialChanges?.problem ??
                                problem ??
                                project?.problem
                              }
                              placeholder="problem statement"
                              onChange={setProblem}
                              $highlight={canApplyProblem}
                              readOnly={inPreviewMode}
                              autoSize={{ minRows: 5, maxRows: 5 }}
                            />
                          </InputLabelContainer>
                        </Col>
                        <Col flex="auto">
                          <InputLabelContainer>
                            <IterationLabel
                              title="Solution"
                              valueUpdated={canApplySolution && isIterationNew}
                              onAccept={() => {
                                setPartialChanges({
                                  ...partialChanges,
                                  solution: viewingIteration?.solution,
                                })
                              }}
                              onCancel={() =>
                                setViewingIteration({
                                  ...viewingIteration!!,
                                  solution: currentProject?.solution,
                                })
                              }
                            />
                            <Textarea
                              value={
                                partialChanges?.solution ??
                                solution ??
                                project?.solution
                              }
                              placeholder="A solution statement"
                              onChange={setSolution}
                              $highlight={canApplySolution}
                              readOnly={inPreviewMode}
                              autoSize={{ minRows: 5, maxRows: 5 }}
                            />
                          </InputLabelContainer>
                        </Col>
                      </Row>
                    </Space>
                  </Col>
                </Row>
              </Panel>
            </TwoColumnsLeftContainer>
            <TwoColumnsRightContainer>
              <Panel>
                <IconPrefixedContainer>
                  <GlobeIcon />
                  {website ? (
                    <EditableText
                      onChangeText={setWebsite}
                      placeholder="Add your website"
                    >
                      {website ?? project?.website}
                    </EditableText>
                  ) : (
                    <DomainContainer>
                      <Typography.Text>{domain.name}</Typography.Text>
                      <Dropdown
                        menu={{
                          items: (domain?.providers ?? []).map(provider => ({
                            key: provider.name,
                            label: (
                              <a
                                key={provider.name}
                                href={provider.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {provider.name}
                              </a>
                            ),
                          })),
                        }}
                      >
                        <span>buy</span>
                      </Dropdown>
                    </DomainContainer>
                  )}
                </IconPrefixedContainer>
              </Panel>
              <PalettesPanel
                loading={loading}
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                inReadOnlyMode={inPreviewMode}
                primary={primary || activePalette?.primary || ''}
                accent={accent || activePalette?.accent || ''}
                background={background || activePalette?.background || ''}
                onPrimaryChange={setPrimary}
                onAccentChange={setAccent}
                onBackgroundChange={setBackground}
                viewingPalettes={viewingPalettes}
                setViewingPalettes={setViewingPalettes}
                partialChanges={
                  darkMode ? partialDarkModeChanges : partialLightModeChanges
                }
                setPartialChanges={
                  darkMode
                    ? setPartialDarkModeChanges
                    : setPartialLightModeChanges
                }
                isIterationNew={isIterationNew}
              />
            </TwoColumnsRightContainer>
          </TwoColumnsContainer>
          <MarketPanel
            project={project!}
            currentProject={currentProject!}
            partialChanges={partialChanges}
            setPartialChanges={setPartialChanges}
            viewingIteration={viewingIteration}
            setViewingIteration={setViewingIteration}
          />
          <ProjectCompetitorsPanel
            projectId={id}
            projectVersion={
              (inPreviewMode
                ? currentProject?.currentVersion
                : project?.currentVersion) ?? 0
            }
            viewingCompetitors={inPreviewMode ? viewingCompetitors : undefined}
            onAcceptChanges={() => {
              setPartialChanges({
                ...partialChanges,
                competitors: viewingCompetitors,
              })
            }}
            onRejectChanges={() => setViewingCompetitors(undefined)}
            isIterationNew={isIterationNew}
            partialChanges={partialChanges?.competitors}
          />
          <ProjectActionsPanel
            onClickLaunchMvp={() => push(`/tools?pro=${id}&ki=ENGINEERING`)}
            onClickGeneratePitchDeck={() =>
              push(`/tools?pro=${id}&eti=pitch-deck-generator`)
            }
            onClickInviteMember={() => push(`/organization/members?new=true`)}
          />
          <Spacer />
        </Container>
      </Page>
      <ChatBar
        onClickSubmitPrompt={createIterationFromPrompt}
        onClickHistory={toggleHistoryDrawer}
        disabled={inPreviewMode || xhrPending}
      />
      {historyDrawerOpened && (
        <ProjectHistoryDrawer
          projectId={id}
          currentProjectVersion={currentProject?.currentVersion ?? 0}
          activeVersion={viewingIteration?.id.version}
          onClose={toggleHistoryDrawer}
          onClickRestore={p => {
            toggleHistoryDrawer()
            restoreIteration(p)
          }}
          onClickView={viewIteration}
        />
      )}
    </>
  )
}

export default OrganizationProjectEdit
