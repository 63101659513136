import React, { useContext, useEffect, useState } from 'react'
import useDebounce from '../../hooks/useDebounce'
import { useQueryParam } from '../../hooks/useQueryParam'
import {
  OrganizationMember,
  OrganizationMemberRole,
  OrganizationMemberRoles,
  OrganizationMemberStatus,
  OrganizationMemberStatuses,
} from '../../types/organization'
import {
  useInviteMember,
  useOrganizationMembers,
} from '../../hooks/organization/useOrganization'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { OrganizationContext } from '../../context/organization-context'
import Spacer from '../atoms/spacer'
import Loading from '../atoms/loading'
import Button from '../atoms/button'
import { Col, Form, Modal, Row, Space, Typography } from 'antd'
import Title from 'antd/lib/typography/Title'
import { Input, InputLabel, InputLabelContainer } from '../atoms/input'
import { SearchOutlined } from '@ant-design/icons'
import Select from '../molecules/select'
import { humanize } from '../../utils/utils'
import Table from '../organism/table'
import { SocialItemContainer, SocialsContainer } from '../molecules/socials'
import { SocialNetworkIcons, WarningIcon } from '../atoms/icon'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useHistory } from 'react-router-dom'
import { useBoolean } from '../../hooks/useBoolean'
import { Label } from '../atoms/typography'
import Header from '../atoms/header'

const roleOptions = Object.values(OrganizationMemberRoles).map(role => {
  return { value: role, label: role }
})

interface InviteFormValues {
  email: string
}

const OrganizationMembers = () => {
  useDocumentTitle('Settings - Members')
  const { push } = useHistory()
  const [form] = Form.useForm()
  const org = useContext(OrganizationContext)!.membership!.organization
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce<string>(query, 300)
  const [after, setAfter] = useState<number>()
  const [status, setStatus] = useQueryParam<OrganizationMemberStatus>('sta')
  const [role, setRole] = useQueryParam<OrganizationMemberRole>('rol')
  const [inviting, setInviting] = useQueryParam<any>('new', undefined, true)
  const [members, setMembers] = useState<OrganizationMember[]>([])
  const [modalOpened, toggleModal] = useBoolean(!!inviting)
  const [inviteRole, setInviteRole] = useState(OrganizationMemberRoles.User)
  const [xhrPending, toggleXhrPending] = useBoolean()
  const inviteMember = useInviteMember()

  const { loading, page, refetch } = useOrganizationMembers({
    orgId: org.id,
    query: debouncedQuery,
    status,
    role,
    after,
  })
  const hasNextPage = (page?.next ?? 0) > 0
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setAfter(page?.next),
    disabled: !loading && !page,
    rootMargin: '0px 0px 400px 0px',
  })

  const sendInvite = (values: InviteFormValues) => {
    toggleXhrPending()
    inviteMember({
      identifier: values.email,
      orgId: org.id,
      role: inviteRole,
    })
      .then(result => {
        if (result) refetch()
      })
      .then(toggleModal)
      .finally(toggleXhrPending)
  }

  const submitInviteForm = () => {
    form.validateFields().then((values: InviteFormValues) => {
      sendInvite(values)
    })
  }

  useEffect(() => {
    if (page) {
      const data = page?.data ?? []
      setMembers(prev => (after ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    setAfter(undefined)
  }, [debouncedQuery, status, role])

  useEffect(() => {
    if (!modalOpened) setInviting(undefined)
    // eslint-disable-next-line
  }, [modalOpened])

  return (
    <>
      <Header>
        <Space direction="horizontal" align="center">
          <Input
            prefix={<SearchOutlined />}
            value={query}
            onChange={event => setQuery(event.target.value)}
            allowClear
          />
          <Select
            placeholder="Status"
            value={status}
            allowClear
            onChange={val => setStatus(val as OrganizationMemberStatus)}
            options={Object.values(OrganizationMemberStatuses).map(value => ({
              label: humanize(value),
              value,
            }))}
          />
          <Select
            placeholder="Role"
            value={role}
            allowClear
            onChange={val => setRole(val as OrganizationMemberRole)}
            options={Object.values(OrganizationMemberRoles).map(value => ({
              label: humanize(value),
              value,
            }))}
          />
        </Space>
        <Button
          analyticsId="invite_organization_member"
          label="+ invite"
          onClick={toggleModal}
        />
      </Header>
      <Spacer size="large" />
      <Table
        rowKey={item => (item as OrganizationMember).id.identifier}
        columns={[
          { title: 'Identifier', dataIndex: 'identifier' },
          { title: 'Title', dataIndex: 'title' },
          {
            title: 'Bio',
            // @ts-ignore
            render: (_, item: OrganizationMember) => (
              <>
                <Typography.Paragraph>{item.bio}</Typography.Paragraph>
                <SocialsContainer>
                  <SocialItemContainer
                    href={item.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {SocialNetworkIcons.LinkedIn}
                  </SocialItemContainer>
                  <SocialItemContainer
                    href={item.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {SocialNetworkIcons.Twitter}
                  </SocialItemContainer>
                </SocialsContainer>
              </>
            ),
          },
          { title: 'Role', dataIndex: 'role' },
          { title: 'Status', dataIndex: 'status' },
        ]}
        dataSource={members.map(member => ({
          ...member,
          identifier: member.id.identifier,
          link: `/organization/members/${member.id.identifier}`,
        }))}
        onRow={(record: any) => ({
          style: { cursor: 'pointer' },
          onClick: () => push(record.link),
        })}
        pagination={false}
      />
      <Modal
        open={modalOpened}
        onCancel={toggleModal}
        cancelText="cancel"
        footer={null}
      >
        <Title level={2}>Invite team member</Title>
        <Spacer />
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Space direction="horizontal" size="middle">
            <WarningIcon />
            <Typography.Text>
              To enhance the user experience, we support registrations and
              logins exclusively with <Label strong>Gmail</Label> or{' '}
              <Label strong>Google Workspace accounts</Label>.
            </Typography.Text>
          </Space>
          <Form form={form} onFinish={submitInviteForm}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please enter an email' },
                { whitespace: true, message: 'Email cannot be empty' },
              ]}
            >
              <InputLabelContainer>
                <InputLabel>Email</InputLabel>
                <Input
                  placeholder="eg. jdoe@cogo.com"
                  onPressEnter={submitInviteForm}
                  required
                />
              </InputLabelContainer>
            </Form.Item>
            <Form.Item
              name="role"
              initialValue={inviteRole}
              rules={[
                {
                  required: true,
                  message: 'Please select a role for this member',
                },
              ]}
            >
              <InputLabelContainer>
                <InputLabel>Role</InputLabel>
                <Select
                  value={inviteRole}
                  options={roleOptions}
                  onChange={val =>
                    setInviteRole(val as OrganizationMemberRoles)
                  }
                />
              </InputLabelContainer>
            </Form.Item>
            <Row justify="end">
              <Col>
                <Form.Item>
                  <Button
                    analyticsId="send_member_invite"
                    label="send"
                    onClick={submitInviteForm}
                    loading={xhrPending}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Space>
      </Modal>

      {hasNextPage && <div ref={sentryRef} />}
      {loading && members.length > 0 && (
        <Spacer>
          <Loading />
        </Spacer>
      )}
    </>
  )
}

export default OrganizationMembers
