import styled from 'styled-components'
import React, { HTMLAttributes, PropsWithChildren } from 'react'
import XhrPending from '../atoms/xhr-pending'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  height: 100vh;
`

type PageProps = PropsWithChildren &
  HTMLAttributes<HTMLDivElement> & {
    xhrPending?: boolean
  }
export const Page: React.FC<PageProps> = ({
  children,
  xhrPending,
  ...props
}) => (
  <XhrPending pending={xhrPending ?? false}>
    <PageContainer {...props}>{children}</PageContainer>
  </XhrPending>
)

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-grow: 0;
`

export const PageContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 24px;
`

export const NewConnectionPageContainer = styled(PageContent)`
  height: auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  background: rgba(0, 0, 0, 0.25);
`
