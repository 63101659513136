import OnboardingContainer from '../templates/onboarding'
import Img from '../../assets/onboarding-img-login.svg'
import { useContext } from 'react'
import styled from 'styled-components'
import { SecurityContext } from '../../context/security-context'
import { GoogleSignIn } from '../atoms/google-sign-in-button'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

const ContentContainer = styled.div`
  margin-top: 28px;
  width: 50%;
`

const LoginPage = () => {
  useDocumentTitle('Cogo - Login')
  const { login } = useContext(SecurityContext)

  return (
    <OnboardingContainer image={Img} title="Welcome to Cogo">
      <ContentContainer>
        <GoogleSignIn onClick={login} />
      </ContentContainer>
    </OnboardingContainer>
  )
}

export default LoginPage
