import React from 'react'
import styled from 'styled-components'
import { Typography } from 'antd'
import { useBoolean } from '../../hooks/useBoolean'

type ButtonProps = {
  showMore: boolean
  onChange: () => void
}

type TextProps = {
  text: string
  charLimit?: number
}

const Button = styled.a`
  font-size: 12px;
`

const ShowMoreLess: React.FC<ButtonProps> = ({ showMore, onChange }) => (
  <Button onClick={() => onChange()}>Show {showMore ? 'less' : 'more'}</Button>
)

const ShowMoreLessText: React.FC<TextProps> = ({ text, charLimit = 225 }) => {
  const [isExpanded, toggleIsExpanded] = useBoolean(false)
  const shouldShowReadMore = text.length > charLimit

  return (
    <div>
      {shouldShowReadMore ? (
        <div>
          <Typography.Text>
            {isExpanded ? `${text} ` : `${text.slice(0, charLimit)}... `}
          </Typography.Text>
          <ShowMoreLess
            showMore={isExpanded}
            onChange={() => toggleIsExpanded()}
          />
        </div>
      ) : (
        <Typography.Text>{text}</Typography.Text>
      )}
    </div>
  )
}

export default ShowMoreLessText
