import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Drawer, Empty, Spin } from 'antd'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import Spacer from '../atoms/spacer'
import Title from 'antd/lib/typography/Title'
import { useProjectIterations } from '../../hooks/project/useProject'
import { ProjectIteration } from '../../types/project'
import ProjectIterationCard from './project-iteration-card'
import moment from 'moment-timezone'

type Props = {
  projectId: string
  currentProjectVersion: number
  activeVersion: number | undefined
  onClose: () => void
  onClickRestore: (iteration: ProjectIteration) => void
  onClickView: (iteration: ProjectIteration) => void
}

const NoHistory = (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description="No project history available"
  />
)

const ProjectIterationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`

const groupIterations = (iterations: ProjectIteration[]) =>
  iterations.reduce(
    (grouped: { [key: string]: ProjectIteration[] }, iteration) => {
      const momentKey = moment(iteration.createdAt).fromNow()
      grouped[momentKey] = (grouped[momentKey] || []).concat(iteration)
      return grouped
    },
    {},
  )

const ProjectHistoryDrawer: React.FC<Props> = ({
  onClose,
  projectId,
  currentProjectVersion,
  activeVersion,
  onClickRestore,
  onClickView,
}) => {
  const [iterations, setIterations] = useState<ProjectIteration[]>([])
  const [after, setAfter] = useState<number>()
  const { loading, page } = useProjectIterations({
    projectId,
    after,
  })

  const hasNextPage = (page?.next ?? 0) > 0
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setAfter(page?.next),
    disabled: !loading && !page,
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    if (page) {
      const data = page?.data ?? []
      setIterations(prev => (after ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const groupedIterations: { [key: string]: ProjectIteration[] } =
    groupIterations(iterations)

  return (
    <Drawer
      width="50vw"
      placement="right"
      zIndex={1040}
      closable={false}
      onClose={onClose}
      open
      title={<Title level={3}>Project History</Title>}
    >
      {!loading && iterations.length === 0 ? (
        NoHistory
      ) : (
        <>
          {Object.keys(groupedIterations).map((key, index) => (
            <ProjectIterationsContainer key={index}>
              <Title level={4}>{key}</Title>
              {groupedIterations[key].map((iteration, idx) => (
                <ProjectIterationCard
                  iteration={iteration}
                  isCurrentIteration={
                    currentProjectVersion === iteration.id.version
                  }
                  isActiveVersion={activeVersion === iteration.id.version}
                  onClickRestore={() => onClickRestore(iteration)}
                  onClickView={() => onClickView(iteration)}
                  key={idx}
                />
              ))}
              <Spacer size="small" />
            </ProjectIterationsContainer>
          ))}
        </>
      )}
      {hasNextPage && <div ref={sentryRef} />}
      {loading && iterations.length > 0 && (
        <Spin spinning>
          <Spacer />
        </Spin>
      )}
    </Drawer>
  )
}

export default ProjectHistoryDrawer
