import useQuery from '../useQuery'
import { Page } from '../../types/pageable'
import { gql } from '@apollo/client'
import { Tool, ToolFilter } from '../../types/tool'

const query = gql`
  query Tools($filter: ToolsFilterInput!) {
    tools(filter: $filter) {
      data {
        id
        identifier
        name
        description
        developer {
          name
          verified
        }
        status
        avgExecutionTimeMs
        executionsCount
        projectExecutionsCount
        tags
        requiredPlanLevel
        createdAt
        updatedAt
      }
      previous
      next
    }
  }
`

const usedTools = gql`
  query UsedTools($filter: ToolsFilterInput!) {
    usedTools(filter: $filter) {
      data {
        id
        identifier
        name
        description
        executionsCount
        projectExecutionsCount
      }
      previous
      next
    }
  }
`

const toolLanguagesQuery = gql`
  query ToolLanguages {
    toolLanguages
  }
`

export const useTools = (filter: ToolFilter = {}) => {
  const { loading, data, refetch } = useQuery<{ tools: Page<Tool> }>(query, {
    variables: { filter },
  })

  return { loading, page: data?.tools, refetch }
}

export const useUsedTools = (filter: ToolFilter = {}) => {
  const { loading, data, refetch } = useQuery<{ usedTools: Page<Tool> }>(
    usedTools,
    {
      variables: { filter },
    },
  )

  return { loading, page: data?.usedTools, refetch }
}

export const useToolLanguages = () => {
  const { loading, data } = useQuery<{
    toolLanguages: string[]
  }>(toolLanguagesQuery)

  return { loading, toolLanguages: data?.toolLanguages }
}
