/* eslint-disable */
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Col, Row, Space, Switch, Typography } from 'antd'
import Panel from './panel'
import { Input, InputLabelContainer } from '../atoms/input'
import { Palette, PalettePartialChanges } from '../../types/project'
import styled from 'styled-components'
import IterationLabel from '../molecules/iteration-label'

type Props = {
  loading: boolean
  darkMode: boolean
  toggleDarkMode: () => void
  inReadOnlyMode: boolean
  primary: string
  accent: string
  background: string
  onPrimaryChange: (value: string) => void
  onAccentChange: (value: string) => void
  onBackgroundChange: (value: string) => void
  viewingPalettes?: Palette[]
  setViewingPalettes: Dispatch<SetStateAction<Palette[] | undefined>>
  partialChanges?: PalettePartialChanges
  setPartialChanges: Dispatch<SetStateAction<PalettePartialChanges | undefined>>
  isIterationNew?: boolean
}

type PaletteColorInputProps = {
  label: string
  value: string
  onChange: (val: string) => void
  inReadOnlyMode: boolean
  valueUpdated?: boolean
  highlight?: boolean
  onAccept: () => void
  onReject: () => void
}

const PaletteColorSize = '1.5rem'

const PaletteColor = styled.div`
  height: ${PaletteColorSize};
  width: ${PaletteColorSize};
  border: 1px solid white;
  border-radius: calc(${PaletteColorSize} * 0.3);
  background-color: ${props => props.color};
  margin-right: 16px;
`

const PaletteColorInput: React.FC<PaletteColorInputProps> = ({
  label,
  value,
  onChange,
  inReadOnlyMode,
  valueUpdated,
  highlight,
  onAccept,
  onReject,
}) => (
  <Col flex="auto">
    <InputLabelContainer>
      <IterationLabel
        valueUpdated={valueUpdated}
        title={label}
        onAccept={onAccept}
        onCancel={onReject}
      />
      <Input
        prefix={<PaletteColor color={value} />}
        value={value}
        onChange={event => onChange(event.target.value)}
        readOnly={inReadOnlyMode}
        $highlight={highlight}
        allowClear
        required
      />
    </InputLabelContainer>
  </Col>
)

const PalettesPanel: React.FC<Props> = ({
  loading,
  darkMode,
  toggleDarkMode,
  inReadOnlyMode,
  primary,
  accent,
  background,
  onPrimaryChange,
  onAccentChange,
  onBackgroundChange,
  viewingPalettes,
  setViewingPalettes,
  partialChanges,
  setPartialChanges,
  isIterationNew,
}) => {
  const viewingPalette = viewingPalettes?.find(
    x => x.id.name === (darkMode ? 'dark' : 'light'),
  )

  const canApplyPrimary =
    inReadOnlyMode &&
    !partialChanges?.primary &&
    viewingPalette &&
    viewingPalette.primary !== primary

  const canApplyAccent =
    inReadOnlyMode &&
    !partialChanges?.accent &&
    viewingPalette &&
    viewingPalette.accent !== accent

  const canApplyBackground =
    inReadOnlyMode &&
    !partialChanges?.background &&
    viewingPalette &&
    viewingPalette.background !== background

  return (
    <Panel>
      <Row align="middle" justify="space-between" style={{ width: '100%' }}>
        <Typography.Title level={3}>Color palette</Typography.Title>
        <Switch
          loading={loading}
          checked={darkMode}
          checkedChildren="Dark"
          unCheckedChildren="Light"
          onChange={() => toggleDarkMode()}
        />
      </Row>
      <Space size="small" direction="vertical">
        <PaletteColorInput
          label="Primary"
          value={
            inReadOnlyMode
              ? partialChanges?.primary ?? viewingPalette?.primary ?? primary
              : primary
          }
          onChange={onPrimaryChange}
          inReadOnlyMode={inReadOnlyMode}
          valueUpdated={isIterationNew && canApplyPrimary}
          highlight={canApplyPrimary}
          onAccept={() =>
            setPartialChanges({
              ...partialChanges,
              primary: viewingPalette?.primary,
            })
          }
          onReject={() =>
            setViewingPalettes(
              viewingPalettes?.map(x => {
                if (x.id.name === (darkMode ? 'dark' : 'light')) {
                  return { ...x, primary }
                } else return x
              }),
            )
          }
        />
        <PaletteColorInput
          label="Accent"
          value={
            inReadOnlyMode
              ? partialChanges?.accent ?? viewingPalette?.accent ?? accent
              : accent
          }
          onChange={onAccentChange}
          inReadOnlyMode={inReadOnlyMode}
          valueUpdated={isIterationNew && canApplyAccent}
          highlight={canApplyAccent}
          onAccept={() =>
            setPartialChanges({
              ...partialChanges,
              accent: viewingPalette?.accent,
            })
          }
          onReject={() =>
            setViewingPalettes(
              viewingPalettes?.map(x => {
                if (x.id.name === (darkMode ? 'dark' : 'light')) {
                  return { ...x, accent }
                } else return x
              }),
            )
          }
        />
        <PaletteColorInput
          label="Background"
          value={
            inReadOnlyMode
              ? partialChanges?.background ??
                viewingPalette?.background ??
                background
              : background
          }
          onChange={onBackgroundChange}
          inReadOnlyMode={inReadOnlyMode}
          valueUpdated={isIterationNew && canApplyBackground}
          highlight={canApplyBackground}
          onAccept={() =>
            setPartialChanges({
              ...partialChanges,
              background: viewingPalette?.background,
            })
          }
          onReject={() =>
            setViewingPalettes(
              viewingPalettes?.map(x => {
                if (x.id.name === (darkMode ? 'dark' : 'light')) {
                  return { ...x, background }
                } else return x
              }),
            )
          }
        />
      </Space>
    </Panel>
  )
}

export default PalettesPanel
