import Panel from './panel'
import React from 'react'
import styled from 'styled-components'
import useCompetitors from '../../hooks/organization/useCompetitors'
import Table from './table'
import { Link } from 'react-router-dom'
import { Competitor } from '../../types/competitor'
import IterationLabel from '../molecules/iteration-label'

type Props = {
  projectId: string
  projectVersion: number
  viewingCompetitors?: Competitor[]
  isIterationNew?: boolean
  onAcceptChanges: () => void
  onRejectChanges: () => void
  partialChanges?: Competitor[] | undefined
}

const TableContainer = styled.div<{ isDiff: boolean }>`
  ${props =>
    props.isDiff &&
    `
    border: 1px solid #8996da;
    border-radius: 12px;
  `}
`

// Compares two lists of Competitor's by checking website URL's
const areCompetitorsDifferent = (
  currentCompetitors: Competitor[],
  viewingCompetitors: Competitor[],
): boolean => {
  if (currentCompetitors.length !== viewingCompetitors.length) {
    return true
  }

  for (let i = 0; i < currentCompetitors.length; i++) {
    if (currentCompetitors[i].website !== viewingCompetitors[i].website) {
      return true
    }
  }

  return false
}

const ProjectCompetitorsPanel: React.FC<Props> = ({
  projectId,
  projectVersion,
  viewingCompetitors,
  isIterationNew,
  onAcceptChanges,
  onRejectChanges,
  partialChanges,
}) => {
  const { loading, competitors } = useCompetitors(projectId, projectVersion)

  const canApplyCompetitors =
    viewingCompetitors &&
    !partialChanges &&
    areCompetitorsDifferent(competitors, viewingCompetitors)

  return (
    <TableContainer isDiff={canApplyCompetitors ?? false}>
      <Panel loading={loading}>
        <IterationLabel
          title="Competitors"
          valueUpdated={isIterationNew && canApplyCompetitors}
          onAccept={onAcceptChanges}
          onCancel={onRejectChanges}
          useLargeLabel
        />
        <Table
          columns={[
            { title: 'Name', dataIndex: 'name' },
            {
              title: 'Website',
              dataIndex: 'website',
              // @ts-ignore
              render: (_, { website }) => (
                <Link
                  to={{
                    pathname: !website
                      .replace(/^http:\/\//, '')
                      .startsWith('https://')
                      ? `https://${website}`
                      : website,
                  }}
                  target="_blank"
                >
                  {website}
                </Link>
              ),
            },
            { title: 'Key differentiator', dataIndex: 'keyDifferentiator' },
            {
              title: 'Competitive advantage',
              dataIndex: 'competitiveAdvantage',
            },
          ]}
          dataSource={(viewingCompetitors
            ? viewingCompetitors
            : competitors
          ).map(competitor => ({
            ...competitor,
            key: competitor.id,
          }))}
          pagination={false}
        />
      </Panel>
    </TableContainer>
  )
}

export default ProjectCompetitorsPanel
