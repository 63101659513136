import { Spin } from 'antd'
import React from 'react'

type Props = {
  size?: 'small' | 'default' | 'large'
}

const Loading: React.FC<Props> = ({ size }) => <Spin size={size} />

export default Loading
