import React from 'react'
import { Page, PageContent, PageHeader } from '../templates/page'
import Breadcrumb from '../molecules/breadcrumb'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useCreateProject } from '../../hooks/project/useProject'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useBoolean } from '../../hooks/useBoolean'
import { Checkbox, Col, Form, Row, Space, Typography } from 'antd'
import {
  Input,
  InputLabel,
  InputLabelContainer,
  Textarea,
} from '../atoms/input'
import Button from '../atoms/button'
import Loading from '../atoms/loading'
import { ArrowLeftOutlined } from '@ant-design/icons'

const Container = styled(PageContent)`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  background: rgba(0, 0, 0, 0.25);
`
const FormContainer = styled.div`
  width: 35%;
`

interface FormValues {
  id: string
  name: string
  description: string
  skipAnalysis: boolean
}

const OrganizationNewProjectPage = () => {
  useDocumentTitle('Settings - New project')
  const [form] = Form.useForm()
  const [xhrPending, toggleXhrPending] = useBoolean()
  const { replace } = useHistory()
  const createProject = useCreateProject()

  const submitForm = (values: FormValues) => {
    toggleXhrPending()
    createProject({
      id: values.id,
      name: values.name,
      description: values.description,
      autoAnalysis: !values.skipAnalysis,
    })
      .then(result => {
        if (result) {
          replace(`/organization/projects/${values.id}`)
        }
      })
      .finally(toggleXhrPending)
  }

  const create = () => {
    form.validateFields().then((values: FormValues) => {
      submitForm(values)
    })
  }

  return (
    <Page>
      <PageHeader>
        <Breadcrumb
          routes={[
            { path: '../../organization', breadcrumbName: 'Organization' },
            { path: '/projects', breadcrumbName: 'Projects' },
            { path: '/new', breadcrumbName: 'New' },
          ]}
        />
      </PageHeader>
      <Container>
        {xhrPending ? (
          <Space direction="vertical" size="middle" align="center">
            <Typography.Title level={2}>
              Building your new project...
            </Typography.Title>
            <Loading size="large" />
          </Space>
        ) : (
          <FormContainer>
            <Form form={form} onFinish={create}>
              <Form.Item
                name="id"
                rules={[
                  { required: true, message: 'Please enter an ID' },
                  {
                    pattern: /^[a-z0-9-]+$/,
                    message: 'ID must be lowercase alphanumeric with dashes',
                  },
                  { whitespace: true, message: 'ID cannot be empty' },
                ]}
              >
                <InputLabelContainer>
                  <InputLabel>ID</InputLabel>
                  <Input
                    placeholder="my-next-project"
                    maxLength={32}
                    showCount
                    allowClear
                    onPressEnter={create}
                  />
                </InputLabelContainer>
              </Form.Item>
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: 'Please enter a name' },
                  { whitespace: true, message: 'Name cannot be empty' },
                ]}
              >
                <InputLabelContainer>
                  <InputLabel>Name</InputLabel>
                  <Input
                    placeholder="Another project"
                    maxLength={32}
                    showCount
                    allowClear
                    onPressEnter={create}
                  />
                </InputLabelContainer>
              </Form.Item>
              <Form.Item
                name="description"
                rules={[
                  { required: true, message: 'Please enter a description' },
                  { whitespace: true, message: 'Description cannot be empty' },
                ]}
              >
                <InputLabelContainer>
                  <InputLabel>Description</InputLabel>
                  <Textarea
                    placeholder="Project description..."
                    autoSize={{ minRows: 5 }}
                    onPressEnter={create}
                  />
                </InputLabelContainer>
              </Form.Item>
              <Form.Item
                initialValue={false}
                name="skipAnalysis"
                valuePropName="checked"
              >
                <Checkbox>Skip auto analysis</Checkbox>
              </Form.Item>
              <Row justify="space-between">
                <Col>
                  <Button
                    analyticsId="cancel_create_project"
                    icon={<ArrowLeftOutlined style={{ color: 'inherit' }} />}
                    type="default"
                    label="cancel"
                    href="/organization/projects"
                  />
                </Col>
                <Col>
                  <Button
                    analyticsId="create_project_submit"
                    label="create"
                    onClick={create}
                  />
                </Col>
              </Row>
            </Form>
          </FormContainer>
        )}
      </Container>
    </Page>
  )
}

export default OrganizationNewProjectPage
