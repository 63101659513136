import { Auditable } from './auditable'
import { Developer } from './developer'
import { PageableRequestFilter } from './pageable'

export type Tool = {
  id: string
  identifier: string
  name: string
  description?: string
  developer: Developer
  price: number
  tags: string[]
  status: ToolStatus
  avgExecutionTimeMs: number
  executionsCount: number
  projectExecutionsCount: number
  requiredPlanLevel: number
} & Auditable

export enum ToolStatus {
  ACTIVE = 'ACTIVE',
  BETA = 'BETA',
  COMING_SOON = 'COMING_SOON',
}

export type ToolFilter = PageableRequestFilter & {
  price?: number
  tag?: string
  kind?: ToolKind
  stackLevels?: StackLevel[]
  languages?: string[]
}

export type ToolExecutionFilter = PageableRequestFilter

export type ToolExecutionStatus =
  | 'QUEUED'
  | 'RUNNING'
  | 'COMPLETED'
  | 'FAILED'
  | 'CANCELLED'

export enum ToolExecutionStatuses {
  Queued = 'QUEUED',
  Running = 'RUNNING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED',
}

export type ToolExecution = {
  id: string
  name: String
  toolId: String
  tool: Tool
  status: ToolExecutionStatus
  input: String
  output?: String
  parentToolExecutionId?: String
  createdBy: String
} & Auditable

export type ToolExecutionArtifact = {
  name: string
  url: string
  type: ToolExecutionArtifactType
}

export type ToolExecutionArtifactType =
  | 'REPOSITORY'
  | 'PRESENTATION'
  | 'EXTERNAL_LINK'

export enum ArtifactTypes {
  Repository = 'REPOSITORY',
  Presentation = 'PRESENTATION',
  External = 'EXTERNAL_LINK',
}

export type ToolExecutionLogEntry = {
  timestamp: string
  level: string
  message: string
}

export enum ToolKind {
  ANY = 'ANY',
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  ENGINEERING = 'ENGINEERING',
}

export enum StackLevel {
  ALL = 'ALL',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  BACKEND = 'BACKEND',
  API = 'API',
  FRONTEND = 'FRONTEND',
}
