import Loading from '../atoms/loading'
import FullScreenLayout from '../templates/full-screen-layout'
import styled from 'styled-components'

const LoadingContainer = styled.div`
  margin: auto auto;
`

const LoadingPage = () => (
  <FullScreenLayout>
    <LoadingContainer>
      <Loading size="large" />
    </LoadingContainer>
  </FullScreenLayout>
)

export default LoadingPage
