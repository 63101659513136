import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  from,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const cache = new InMemoryCache()

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (networkError) {
    console.log(
      `[Network error] - [${operation.operationName}]: ${networkError}`,
    )
  }
  graphQLErrors?.forEach(({ message, locations, path }) =>
    console.log(
      `[GraphQL error] - [${operation.operationName}]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ),
  )
})

const httpLink = createHttpLink({ uri: '/graphql' })

const link = from([errorLink, httpLink])

const defaultOptions: DefaultOptions = {
  watchQuery: { fetchPolicy: 'no-cache' },
  query: { fetchPolicy: 'no-cache' },
  mutate: { fetchPolicy: 'no-cache' },
}

const client = new ApolloClient({
  cache,
  link,
  defaultOptions,
})

export default client
