import { useEffect } from 'react'

export const useDocumentTitle = (
  title: string,
  { skip = false }: { skip?: boolean } = {},
) =>
  useEffect(() => {
    if (skip) return
    document.title = title
  }, [title, skip])
