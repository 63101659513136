import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import LoadingPage from '../components/pages/loading'
import { OrganizationMember } from '../types/organization'
import useMemberships from '../hooks/organization/useMemberships'
import OrganizationCreatePage from '../components/pages/organization-create'
import { useQueryParam } from '../hooks/useQueryParam'
import { noop } from '../utils/utils'

type OrganizationContextProps = {
  memberships: OrganizationMember[]
  membership?: OrganizationMember
  refetch: () => Promise<void>
  setOrgId: (value: string | undefined) => void
}

export const OrganizationContext =
  React.createContext<OrganizationContextProps>({
    memberships: [],
    refetch: Promise.reject,
    setOrgId: noop,
  })

export const OrganizationContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [orgId, setOrgId] = useQueryParam<string>('org')
  const { loading, memberships, refetch } = useMemberships()
  const [membership, setMembership] = useState<OrganizationMember>()
  const hasOrg = memberships?.length > 0

  const membershipByOrgId: Record<string, OrganizationMember> = useMemo(
    () =>
      memberships?.reduce(
        (byOrgId, item) => ({ ...byOrgId, [item.id.orgId]: item }),
        {},
      ),
    [memberships],
  )

  useEffect(() => {
    if (!membership && hasOrg)
      setMembership(
        localStorage.getItem('activeOrg') !== null
          ? membershipByOrgId[localStorage.getItem('activeOrg')!!]
          : membershipByOrgId[orgId] ??
              memberships.find(m => m.status === 'ACTIVE') ??
              memberships[0],
      )
    else if (membership) {
      setMembership(membershipByOrgId[membership.id.orgId])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberships])

  useEffect(() => {
    if (orgId) {
      refetch().then(() => {
        setMembership(membershipByOrgId[orgId])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId])

  return loading || (hasOrg && !membership) ? (
    <LoadingPage />
  ) : hasOrg ? (
    <OrganizationContext.Provider
      value={{ memberships, membership, refetch, setOrgId }}
    >
      {children}
    </OrganizationContext.Provider>
  ) : (
    <OrganizationCreatePage onSuccess={refetch} />
  )
}
