import React from 'react'
import { Result } from 'antd'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

export const UnauthorizedPage = () => {
  useDocumentTitle('Unauthorized')
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
    />
  )
}
