import { RouteGuard } from './guard/route-guard'
import React from 'react'
import Loading from '../components/atoms/loading'
import { Route, Switch } from 'react-router-dom'
import NotFoundPage from '../components/pages/not-found'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

export type RouteDefinition = {
  path: string
  component: React.FC
  icon?: React.FC<Partial<CustomIconComponentProps>>
  label?: string
  exact?: boolean
  nested?: boolean
  children?: RouteDefinition[]
  guard?: RouteGuard<any>
}

export const prefixChildrenPaths = (
  path: string,
  children: RouteDefinition[],
): RouteDefinition[] =>
  children.map(child => ({
    ...child,
    path: path + child.path,
    children: prefixChildrenPaths(child.path, child.children ?? []),
  }))

export const defaultGuard: RouteGuard<unknown> = {
  useFilter: () => ({
    authorized: true,
    fallback: undefined,
    loading: false,
  }),
}

const FilterableRoute: React.FC<RouteDefinition> = ({
  path,
  component,
  children,
  exact,
  guard = defaultGuard,
}) => {
  const { params, useFilter } = guard
  const { authorized, fallback = NotFoundPage, loading } = useFilter(params)

  if (loading) {
    return <Loading />
  }

  const defaultOrChildren =
    !children || children.length === 0
      ? component
      : () =>
          toSwitch(
            [
              ...prefixChildrenPaths(path, children),
              { path, component, exact },
            ],
            path,
          )

  return (
    <Route
      path={path}
      component={authorized ? defaultOrChildren : fallback}
      exact={exact}
    />
  )
}

export const toSwitch = (routes: RouteDefinition[], key?: string) => (
  <Switch key={key}>
    {[
      ...routes,
      {
        path: '',
        component: NotFoundPage,
      } as RouteDefinition,
    ].map(({ path, component, exact, children, guard }) => (
      <FilterableRoute
        key={path}
        path={path}
        exact={exact}
        component={component}
        children={children}
        guard={guard}
      />
    ))}
  </Switch>
)
