import React, { PropsWithChildren, useContext, useState } from 'react'
import styled from 'styled-components'
import { Avatar, Dropdown, Layout, Menu, Modal, Space } from 'antd'
import Title from 'antd/lib/typography/Title'
import { UserOutlined } from '@ant-design/icons'
import Logo from '../atoms/logo'
import { defaultGuard, RouteDefinition } from '../../routes/route-helper'
import { useHistory } from 'react-router-dom'
import { SecurityContext } from '../../context/security-context'
import Spacer from '../atoms/spacer'
import { OrganizationContext } from '../../context/organization-context'
import { useBoolean } from '../../hooks/useBoolean'
import { UnauthorizedPage } from '../pages/unauthorized'

const { Content } = Layout

const iconSize = 19
const paddingLeft = 16
export const SideMenuSize = 80

type MainLayoutProps = PropsWithChildren & {
  routes: RouteDefinition[]
}

const SideMenuContainer = styled.div`
  width: ${SideMenuSize}px;
  height: 100vh;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px 16px;
  background: #161825;
`

const NavContainer = styled(Menu)`
  border-right: none;
  margin-top: 64px;
`
const MenuItem = styled(Menu.Item)`
  border-radius: 10px;
  width: 45px !important;
  height: 45px !important;
  padding: 0 !important;
  text-align: center !important;
  margin-bottom: 16px !important;
  > span {
    color: white;
  }
`
type OrganizationContainerProps = {
  active: boolean
}

const OrganizationContainer = styled.div<OrganizationContainerProps>`
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: #161825;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
    border: 1px solid #8996da;
  `}
`

const SideMenuItem: React.FC<{
  route: RouteDefinition
  onClick: () => void
  selected: boolean
}> = ({ route, onClick, selected }) => {
  const { guard = defaultGuard } = route
  const { params, useFilter } = guard
  const { authorized } = useFilter(params)

  return authorized ? (
    <MenuItem
      key={route.path}
      onClick={onClick}
      className={selected ? 'ant-menu-item-selected' : ''}
    >
      {
        // @ts-ignore
        <route.icon style={{ fontSize: iconSize, color: 'white' }} />
      }
    </MenuItem>
  ) : null
}

const MainLayout: React.FC<MainLayoutProps> = ({ routes, children }) => {
  const { location, push } = useHistory()
  const { account, logout } = useContext(SecurityContext)
  const { membership, memberships, setOrgId } = useContext(OrganizationContext)
  const [modalOpened, toggleModal] = useBoolean()
  const route =
    routes.find(({ path }) => location.pathname.startsWith(path)) ?? routes[0]

  const [selectedRoute, setSelectedRoute] = useState(route)
  const navigateTo = (route: RouteDefinition) => () => {
    setSelectedRoute(route)
    push(route.path)
  }

  const profileMenu = (
    <Menu>
      <Menu.Item key="sign-out" onClick={logout}>
        Sign out
      </Menu.Item>
      <Menu.Item key="switch" onClick={toggleModal}>
        Switch organization
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout hasSider>
      <SideMenuContainer>
        <div>
          <Logo size="50px" />
          <NavContainer
            mode="inline"
            inlineIndent={paddingLeft}
            selectedKeys={[selectedRoute.path]}
          >
            {routes
              .filter(({ icon }) => !!icon)
              .map(route => (
                <SideMenuItem
                  key={route.path}
                  route={route}
                  onClick={navigateTo(route)}
                  selected={selectedRoute.path === route.path}
                />
              ))
              .filter(item => !!item)}
          </NavContainer>
        </div>

        <Dropdown overlay={profileMenu} placement="topLeft">
          <Avatar size="large" src={account?.avatar} icon={<UserOutlined />} />
        </Dropdown>
        <Modal open={modalOpened} onCancel={toggleModal} footer={null} centered>
          <Title level={2}>Your organizations</Title>
          <Spacer />
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            {memberships.map(m => (
              <OrganizationContainer
                key={m.id.orgId}
                active={m.id.orgId === membership?.id.orgId}
                onClick={() => {
                  if (m.id.orgId !== membership?.id.orgId) {
                    localStorage.setItem('activeOrg', m.id.orgId)
                    setOrgId(m.id.orgId)
                  }
                }}
              >
                <Title level={3}>{m.organization.name}</Title>
              </OrganizationContainer>
            ))}
          </Space>
        </Modal>
      </SideMenuContainer>
      <Layout style={{ marginLeft: SideMenuSize }}>
        {membership?.status === 'ACTIVE' ? (
          <Content>{children}</Content>
        ) : (
          <UnauthorizedPage />
        )}
      </Layout>
    </Layout>
  )
}

export default MainLayout
