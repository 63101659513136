import { Auditable } from './auditable'
import { PageableRequestFilter } from './pageable'
import { Plan } from './plan'

export type Organization = {
  id: string
  name: string
  logo?: string
  description?: string
  plan: Plan
} & Auditable

export type NewOrganization = {
  name: string
}

export type OrganizationUpdate = {
  id: string
  name?: string
  logo?: string
  description?: string
}
export type OrganizationMemberRole = 'ADMIN' | 'OWNER' | 'USER' | 'VIEWER'
export enum OrganizationMemberRoles {
  Admin = 'ADMIN',
  Owner = 'OWNER',
  User = 'USER',
  Viewer = 'VIEWER',
}
export type OrganizationMemberStatus =
  | 'ACTIVE'
  | 'DEACTIVATED'
  | 'DECLINED'
  | 'INVITED'
export enum OrganizationMemberStatuses {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Declined = 'DECLINED',
  Invited = 'INVITED',
}
export type OrganizationMember = {
  id: { orgId: string; identifier: string }
  name: string
  avatar?: string
  role: OrganizationMemberRole
  status: OrganizationMemberStatus
  title?: string
  bio?: string
  linkedin?: string
  twitter?: string
  organization: Organization
} & Auditable

export type OrganizationMemberUpdate = {
  orgId: string
  identifier: string
  name?: string
  avatar?: string
  role?: OrganizationMemberRole
  status?: OrganizationMemberStatus
  title?: string
  bio?: string
  linkedin?: string
  twitter?: string
}

export type OrganizationMembersFilter = PageableRequestFilter & {
  orgId: string
  role?: OrganizationMemberRole
  status?: OrganizationMemberStatus
}

export type DomainProvider = {
  name: string
  link: string
  price: string
}
export type Domain = {
  name: string
  providers: DomainProvider[]
}

export type NewOrganizationMemberInput = {
  orgId: string
  identifier: string
  role: OrganizationMemberRole
  status?: OrganizationMemberStatus
}

export type NewAccountInput = {
  identifier: string
  name: string
  email: string
  avatar?: string
}
