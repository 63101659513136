import React, { useContext } from 'react'
import {
  AuthenticationRoutes,
  InvitationsRoute,
  MainRoutes,
} from './main-routes'
import Layout from '../components/templates/main-layout'
import { prefixChildrenPaths, RouteDefinition, toSwitch } from './route-helper'
import { AlertManagerProvider } from '../context/alert-manager'
import { SecurityContext } from '../context/security-context'
import { OrganizationContextProvider } from '../context/organization-context'
import { ProjectContextProvider } from '../context/project-context'
import { useHistory } from 'react-router-dom'
import NotWhitelistedPage from '../components/pages/not-whitelisted'

const MainRouter = () => {
  const { location } = useHistory()
  const { account } = useContext(SecurityContext)

  const filterOutNestedRoutes = (
    routes: RouteDefinition[],
  ): RouteDefinition[] =>
    routes
      .flatMap(({ path, nested, children, ...rest }) =>
        nested && (children ?? []).length > 0
          ? prefixChildrenPaths(path, filterOutNestedRoutes(children!))
          : [
              {
                path,
                nested,
                children: filterOutNestedRoutes(children ?? []),
                ...rest,
              },
            ],
      )
      .filter(({ nested }) => !nested)

  if (location.pathname.startsWith(InvitationsRoute.path)) {
    return toSwitch([InvitationsRoute])
  }

  return account && account.whitelisted ? (
    <OrganizationContextProvider>
      <ProjectContextProvider>
        <Layout
          routes={MainRoutes.filter(({ path, label }) => !!path && !!label)}
        >
          <AlertManagerProvider>
            {toSwitch(filterOutNestedRoutes(MainRoutes))}
          </AlertManagerProvider>
        </Layout>
      </ProjectContextProvider>
    </OrganizationContextProvider>
  ) : account && !account.whitelisted ? (
    <NotWhitelistedPage />
  ) : (
    toSwitch(AuthenticationRoutes)
  )
}

export default MainRouter
