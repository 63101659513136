// eslint-disable-next-line import/no-anonymous-default-export
export default {
  campaign_objective_awareness: 'Awareness',
  campaign_objective_traffic: 'Traffic',
  campaign_objective_engagement: 'Engagement',
  campaign_objective_leads: 'Leads',
  campaign_objective_app_promotion: 'App Promotion',
  campaign_objective_sales: 'Sales',
  invitations_invalid_expired:
    'The link you followed appears to be invalid or expired.',
  invitations_use_correct:
    "Please ensure you're using the correct invitation link, or request a new invitation from the sender to proceed.",
  invitations_logout: 'Kindly log out to continue with the invited profile.',
  invitations_signin: 'Sign in to continue',
  connection_provider_AWS: 'Amazon Web Services',
  connection_provider_AZURE: 'Azure',
  connection_provider_GCP: 'Google Cloud Platform',
  connection_provider_SALESFORCE: 'Salesforce',
  connection_provider_FACEBOOK: 'Facebook',
  connection_provider_GOOGLEADS: 'Google Ads',
  connection_provider_GOOGLE_ANALYTICS: 'Google Analytics',
  connection_provider_GOOGLE_SEARCH_CONSOLE: 'Google Search Console',
  connection_provider_LINKEDIN: 'LinkedIn',
  connection_provider_TWITTER: 'Twitter',
  connection_provider_REDSHIFT: 'Redshift',
  connection_provider_HEROKU: 'Heroku',
  connection_provider_GITHUB: 'GitHub',
  connection_provider_GITLAB: 'GitLab',
  connection_provider_BITBUCKET: 'Bitbucket',
  connection_provider_DIGITALOCEAN: 'DigitalOcean',
  connection_provider_DATABRICKS: 'Databricks',
  connection_provider_SNOWFLAKE: 'Snowflake',
  aws_new_step_one:
    '1. Access or create your account on the AWS Management Console',
  aws_new_signup:
    "If you don't have an AWS account, you can create one by visiting the <1>AWS sign-up page</1>.",
  aws_new_management_console:
    'Once you have an account, log in to the <1>AWS Management Console</1> using your AWS account credentials.',
  aws_new_step_two: '2. Create a new user for Cogo to act on your behalf',
  aws_new_iam: 'Navigate to the <1>IAM Dashboard</1>',
  aws_new_navigate_users: 'Click on "Users" in the navigation pane.',
  aws_new_add_user: 'Click the "Create user" button.',
  aws_new_username:
    'Provide a user name for the service account (e.g. "cogo-service-account").',
  aws_new_permissions: 'Click "Next: Permissions."',
  aws_new_add_permissions:
    'On the "Set permissions" page, select "Attach existing policies directly."',
  aws_new_admin_access:
    'Select and attach the "AdministratorAccess" policy or custom policies with specific permissions allowing us to:',
  aws_new_admin_provision: 'provision and manage AWS resources',
  aws_new_admin_deploy: 'deploy your applications',
  aws_new_tags: 'Click "Next." You can add tags on this page if needed.',
  aws_new_create_user: 'Click "Create user."',
  aws_new_step_three: '3. Access Key Creation',
  aws_new_account:
    'Back on the "Users" page, click on the account you just created.',
  aws_new_security_credentials: 'Click on the "Security credentials" tab.',
  aws_new_access_key:
    'Under "Access keys", click on the "Create access key" button.',
  aws_new_access_key_practices:
    'On "Access key best practices & alternatives" page, click "Other" and click "Next."',
  aws_new_access_key_tags:
    'Add description tags if needed and then click the "Create access key" button.',
  aws_new_access_key_confirmation:
    'The access key and secret access will be shown to you.',
  aws_new_download_access_key:
    'You can also download the access key file, which contains the access key ID and secret access key. Keep these credentials secure, as they provide programmatic access to your AWS account.',
  gcp_new_step_one: '1. Creating a Google Cloud Project',
  gcp_new_sign_in:
    'Go to the <1>Google Cloud Console</1> and sign in with your Google account.',
  gcp_new_project_selection:
    'If you have multiple projects, select the project where you want to create the service account. If not, you can create a new project.',
  gcp_new_project_creation:
    'To create a new project, click on the project drop-down (near the top left corner) and select "New Project."',
  gcp_new_project_details:
    "Provide a name for your project and optionally customize the project ID. You can also choose an organization if you're working within one.",
  gcp_new_billing_setup:
    "<1>Associate a billing account</1> with your project. This is necessary to use the resources within the project. If you haven't set up billing, you'll be prompted to do so.",
  gcp_new_dashboard:
    "Once the project is created, you'll be directed to the project dashboard.",
  gcp_new_step_two: '2. Enable the necessary APIs',
  gcp_enable_resource_manager_api:
    'Navigate to the <1>Cloud Resource Manager API</1> page and press "Enable" to activate the API on your project.',
  gcp_enable_service_usage_api:
    'Navigate to the <1>Service Usage API</1> page and press "Enable" to activate the API on your project.',
  gcp_new_step_three:
    '3. Create a Google Cloud Service Account for Cogo to act on your behalf',
  gcp_new_navigate_service_account:
    'Navigate to <1>Service accounts</1> under "IAM & Admin." If required, select your project to view the page.',
  gcp_new_create_service_account:
    'Click the "+ Create Service Account" button.',
  gcp_new_service_account_name:
    'Provide a user name for the service account (e.g. "cogo-service-account").',
  gcp_new_create_continue: 'Click "Create and Continue."',
  gcp_new_grant_role:
    'Grant the service account the "Editor" role and the "Security Admin" role.',
  gcp_new_grant_user_access:
    'Optionally, you can grant user access to this service account.',
  gcp_new_done: 'Click "Done" to create the new service account.',
  gcp_new_step_four: '4. Creating an Access Key',
  gcp_new_select_account:
    'Back on the "Services account" page, click on the service account you just created.',
  gcp_new_keys_tab: 'Click on the "Keys" tab.',
  gcp_new_add_key: 'Click "Add Key" then "Create new key."',
  gcp_new_key_json:
    'Select the key type to JSON and click "Create" to download the private key JSON file. Keep this file secure, as it provides access to your Google Cloud resources.',
  tool_kind_ANY: 'Any',
  tool_kind_ADMINISTRATIVE: 'Administrative',
  tool_kind_ENGINEERING: 'Engineering',
  stack_level_ALL: 'All',
  stack_level_INFRASTRUCTURE: 'Infrastructure',
  stack_level_BACKEND: 'Backend',
  stack_level_API: 'API',
  stack_level_FRONTEND: 'Frontend',
  programming_language_ALL: 'All',
  programming_language_JAVA: 'Java',
  programming_language_Kotlin: 'Kotlin',
  programming_language_JAVASCRIPT: 'JavaScript',
  programming_language_TYPESCRIPT: 'TypeScript',
  programming_language_PYTHON: 'Python',
  programming_language_HCL: 'HCL (Terraform)',
  programming_language_KOTLIN: 'Kotlin',
  programming_language_SWIFT: 'Swift',
  not_whitelisted_welcome: 'Welcome Aboard!',
  not_whitelisted_waiting_list: "You're on Our Exclusive Waiting List",
  not_whitelisted_notify:
    "Excited to see you here! We'll notify you when your spot opens up.",
  not_whitelist_thanks: 'Thanks for signing up!',
  project_iteration_old:
    "You're viewing a previous version. You can restore this version without loosing your recent changes.",
  project_iteration_new:
    "You're viewing a newer version. You can partially accept or reject individual changes to create new iteration.",
  tool_status_BETA: 'beta',
  tool_status_COMING_SOON: 'coming soon',
  upgrade_plan: '<1>Upgrade your plan</1> to unlock this tool! 🌟',
  premium_perk: 'Psst, this is a premium perk',
  unlock_module: '<1>Upgrade your plan</1> to unlock this module! 🌟',
}
