import * as React from 'react'
import { useContext, useEffect } from 'react'
import {
  useBillingSession,
  usePlanUsage,
} from '../../hooks/organization/useOrganization'
import Loading from '../atoms/loading'
import { OrganizationContext } from '../../context/organization-context'
import {
  TwoColumnsContainer,
  TwoColumnsLeftContainer,
  TwoColumnsRightContainer,
} from '../molecules/two-columns'
import Panel from '../organism/panel'
import { Label, LabelValueContainer } from '../atoms/typography'
import { Col, Progress, Row, Typography } from 'antd'
import _ from 'lodash'
import Button from '../atoms/button'
import { useQueryParam } from '../../hooks/useQueryParam'

const OrganizationBilling = () => {
  const [planQueryParam] = useQueryParam<string>('plan')
  const { membership } = useContext(OrganizationContext)
  const { loading: loadingSession, data: session } = useBillingSession()
  const { loading: loadingUsage, data: usage } = usePlanUsage()
  const plan = membership?.organization?.plan
  const loading = loadingUsage || loadingSession || !plan

  useEffect(() => {
    if (
      !!session &&
      !!planQueryParam &&
      !!plan &&
      plan.name.toLowerCase() !== planQueryParam.toLowerCase()
    ) {
      window.location.href = session
    }
  }, [session, planQueryParam, plan])

  return loading ? (
    <Loading />
  ) : (
    <TwoColumnsContainer>
      <TwoColumnsLeftContainer>
        <Panel title="Usage this month">
          <LabelValueContainer>
            <Row
              align="middle"
              justify="space-between"
              style={{ width: '100%' }}
            >
              <Col span={12}>
                <Label>Project Analysis updates</Label>
              </Col>
              <Col span={12}>
                <Row justify="end">
                  <Typography.Text color="secondary">
                    {usage?.projectAnalysisUpdates} /
                    {plan?.monthlyProjectAnalysisUpdates}
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
            <Progress
              percent={
                _.divide(
                  usage?.projectAnalysisUpdates ?? 0,
                  plan?.monthlyProjectAnalysisUpdates ?? 1,
                ) * 100
              }
              showInfo={false}
            />
          </LabelValueContainer>
          <LabelValueContainer>
            <Row
              align="middle"
              justify="space-between"
              style={{ width: '100%' }}
            >
              <Col span={12}>
                <Label>Tool executions</Label>
              </Col>
              <Col span={12}>
                <Row justify="end">
                  <Typography.Text color="secondary">
                    {usage?.toolExecutions} /{plan?.monthlyToolExecutions}
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
            <Progress
              percent={
                _.divide(
                  usage?.toolExecutions ?? 0,
                  plan?.monthlyToolExecutions ?? 1,
                ) * 100
              }
              showInfo={false}
            />
          </LabelValueContainer>
        </Panel>
      </TwoColumnsLeftContainer>
      <TwoColumnsRightContainer>
        <Panel title={plan?.name + ' Plan'}>
          <LabelValueContainer>
            <Label>Seats</Label>
            <Typography.Text color="secondary">
              {usage?.seats} out of {plan?.seats} seats
            </Typography.Text>
          </LabelValueContainer>
          <LabelValueContainer>
            <Label>Projects</Label>
            <Typography.Text color="secondary">
              {usage?.projects} out of {plan?.projects} projects
            </Typography.Text>
          </LabelValueContainer>
          <LabelValueContainer>
            <Label>GitHub Connections</Label>
            <Typography.Text color="secondary">
              {usage?.githubConnections} out of {plan?.githubConnections}{' '}
              connections
            </Typography.Text>
          </LabelValueContainer>
          <LabelValueContainer>
            <Label>Cloud Connections</Label>
            <Typography.Text color="secondary">
              {usage?.cloudConnections} out of {plan?.cloudConnections}{' '}
              connections
            </Typography.Text>
          </LabelValueContainer>

          <Button
            analyticsId="manage_billing"
            label={'Manage billing'}
            href={session}
          />
        </Panel>
      </TwoColumnsRightContainer>
    </TwoColumnsContainer>
  )
}

export default OrganizationBilling
