import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { OrganizationContext } from '../../context/organization-context'
import Img from '../../assets/onboarding-img-organization-seed.svg'
import OnboardingContainer from '../templates/onboarding'
import { useCreateProject } from '../../hooks/project/useProject'
import { Col, Form, Row, Space, Typography } from 'antd'
import { Input, InputLabel, Textarea } from '../atoms/input'
import Button from '../atoms/button'
import { useBoolean } from '../../hooks/useBoolean'
import Loading from '../atoms/loading'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import styled from 'styled-components'
import { capitalize } from '../../utils/utils'

type Props = {
  onSuccess: () => Promise<void>
}

const FormContainer = styled(Form)`
  padding-top: 1.5rem;
  width: 77%;
`

interface FormValues {
  id: string
  name: string
  description: string
  skipAnalysis: boolean
}

const ProjectSeedPage: React.FC<Props> = ({ onSuccess }) => {
  useDocumentTitle('Onboarding - New project')
  const { membership } = useContext(OrganizationContext)
  const [form] = Form.useForm()
  const [xhrPending, toggleXhrPending] = useBoolean()
  const { replace } = useHistory()
  const createProject = useCreateProject()

  const submitForm = (values: FormValues) => {
    toggleXhrPending()
    createProject({
      id: values.id,
      name: values.name,
      description: values.description,
      autoAnalysis: true,
    })
      .then(onSuccess)
      .then(() => replace(`/organization/projects/${values.id}`))
      .finally(toggleXhrPending)
  }

  const create = () => {
    form.validateFields().then(submitForm)
  }

  return xhrPending ? (
    <OnboardingContainer title="Building your new project" image={Img}>
      <Loading />
    </OnboardingContainer>
  ) : (
    <OnboardingContainer title="Describe your new project" image={Img}>
      {xhrPending ? (
        <Space direction="vertical" size="middle" align="center">
          <Typography.Title level={2}>
            Building your new project...
          </Typography.Title>
          <Loading size="large" />
        </Space>
      ) : (
        <FormContainer
          form={form}
          layout="vertical"
          initialValues={
            {
              id: membership!.organization.name.toLowerCase().replace(' ', '-'),
              name: capitalize(membership!.organization.name),
              skipAnalysis: false,
            } as FormValues
          }
          onFinish={create}
        >
          <Form.Item
            name="id"
            label={<InputLabel>ID</InputLabel>}
            rules={[
              { required: true, message: 'Please enter an ID' },
              {
                pattern: /^[a-z0-9-]+$/,
                message: 'ID must be lowercase alphanumeric with dashes',
              },
              { whitespace: true, message: 'ID cannot be empty' },
            ]}
          >
            <Input
              placeholder="my-next-project"
              maxLength={32}
              showCount
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="name"
            label={<InputLabel>Name</InputLabel>}
            rules={[
              { required: true, message: 'Please enter a name' },
              { whitespace: true, message: 'Name cannot be empty' },
            ]}
          >
            <Input
              placeholder="Another project"
              allowClear
              maxLength={32}
              showCount
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={<InputLabel>Description</InputLabel>}
            rules={[
              { required: true, message: 'Please enter a description' },
              { whitespace: true, message: 'Description cannot be empty' },
            ]}
          >
            <Textarea
              placeholder="What's the problem, the solution and/or mission statement?"
              autoSize={{ minRows: 5, maxRows: 8 }}
              autoFocus
            />
          </Form.Item>
          <Row justify="space-between">
            <Col />

            <Col>
              <Form.Item>
                <Button analyticsId="create_project_submit" label="create" />
              </Form.Item>
            </Col>
          </Row>
        </FormContainer>
      )}
    </OnboardingContainer>
  )
}

export default ProjectSeedPage
