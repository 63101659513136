import React, { useEffect, useState } from 'react'
import useDebounce from '../../hooks/useDebounce'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import Spacer from '../atoms/spacer'
import Loading from '../atoms/loading'
import Button from '../atoms/button'
import { Avatar, Space, Typography } from 'antd'
import { Input } from '../atoms/input'
import { FolderOutlined, SearchOutlined } from '@ant-design/icons'
import Table from '../organism/table'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useHistory } from 'react-router-dom'
import { useOrganizationProjects } from '../../hooks/project/useProject'
import moment from 'moment-timezone'
import Header from '../atoms/header'
import { Project } from '../../types/project'

const OrganizationProjects = () => {
  useDocumentTitle('Settings - Connections')
  const { replace } = useHistory()
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce<string>(query, 300)
  const [after, setAfter] = useState<number>()
  const [projects, setProjects] = useState<Project[]>([])

  const { loading, page } = useOrganizationProjects({
    query: debouncedQuery,
    after,
  })
  const hasNextPage = (page?.next ?? 0) > 0
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setAfter(page?.next),
    disabled: !loading && !page,
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    if (page) {
      const data = page?.data ?? []
      setProjects(prev => (after ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    setAfter(undefined)
  }, [debouncedQuery])

  return (
    <>
      <Header>
        <Space direction="horizontal" align="center">
          <Input
            prefix={<SearchOutlined />}
            value={query}
            onChange={event => setQuery(event.target.value)}
            allowClear
          />
        </Space>
        <Button
          analyticsId="create_project"
          label="+ New project"
          href="/organization/projects/new"
        />
      </Header>
      <Spacer size="large" />
      {/* @ts-ignore*/}
      <Table
        rowKey={item => (item as Project).id}
        columns={[
          {
            key: 'avatar',
            title: '',
            render: (_, item: Project) => (
              <Avatar src={item?.avatar} icon={<FolderOutlined />} />
            ),
          },
          { key: 'name', title: 'Name', dataIndex: 'name' },
          {
            key: 'since',
            title: 'Since',
            // @ts-ignore
            render: (_, item: Project) => (
              <Typography.Paragraph>
                {moment(item.updatedAt).fromNow()}
              </Typography.Paragraph>
            ),
          },
        ]}
        dataSource={projects}
        onRow={({ id }: any) => ({
          style: { cursor: 'pointer' },
          onClick: () => {
            replace(`/organization/projects/${id}`)
          },
        })}
        pagination={false}
      />

      {hasNextPage && <div ref={sentryRef} />}
      {loading && projects.length > 0 && (
        <Spacer>
          <Loading />
        </Spacer>
      )}
    </>
  )
}

export default OrganizationProjects
