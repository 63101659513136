import styled from 'styled-components'
import { TrashIcon } from './icon'

const DeleteButtonContainer = styled.div`
  cursor: pointer;
  svg {
    font-size: 20px;
    color: #f5766d;
  }
`

type Props = {
  onClick: () => void
}

// TODO: refactor to be more generic and reusable for other icons
const DeleteButton: React.FC<Props> = ({ onClick }) => (
  <DeleteButtonContainer onClick={onClick}>
    <TrashIcon />
  </DeleteButtonContainer>
)

export default DeleteButton
