import Panel from './panel'
import React, { Dispatch, SetStateAction } from 'react'
import { LabelValueContainer } from '../atoms/typography'
import styled from 'styled-components'
import { PartialChanges, Project, ProjectIteration } from '../../types/project'
import { Textarea, Input } from '../atoms/input'
import IterationLabel from '../molecules/iteration-label'

type Props = {
  project: Project
  currentProject: Project
  partialChanges?: PartialChanges
  setPartialChanges: Dispatch<SetStateAction<PartialChanges | undefined>>
  viewingIteration?: ProjectIteration
  setViewingIteration: Dispatch<SetStateAction<ProjectIteration | undefined>>
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 48px;
  > div {
    width: 33%;
  }
`
const TypeIndustryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`

const MarketInput = styled(Input)`
  ${props =>
    props.$highlight
      ? `border: 1px solid #8996da;`
      : `
  border: 0;
  padding: 0;
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
  `}
`

const MarketTextarea = styled(Textarea)`
  ${props =>
    props.$highlight
      ? `border: 1px solid #8996da;`
      : `
  border: 0;
  padding: 0;
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
`}
`

const MarketPanel: React.FC<Props> = ({
  project,
  currentProject,
  partialChanges,
  setPartialChanges,
  viewingIteration,
  setViewingIteration,
}) => {
  const isIterationNew =
    viewingIteration &&
    viewingIteration?.id.version > currentProject.currentVersion

  const canApplyType =
    viewingIteration &&
    !partialChanges?.marketType &&
    viewingIteration.marketType !== currentProject.marketType

  const canApplyIndustry =
    viewingIteration &&
    !partialChanges?.marketIndustry &&
    viewingIteration.marketIndustry !== currentProject.marketIndustry

  const canApplyGeneralInfo =
    viewingIteration &&
    !partialChanges?.marketDetails &&
    viewingIteration.marketDetails !== currentProject.marketDetails

  const canApplyCustomerSegmentation =
    viewingIteration &&
    !partialChanges?.marketCustomerSegmentation &&
    viewingIteration.marketCustomerSegmentation !==
      currentProject.marketCustomerSegmentation

  const canApplyCustomerTrend =
    viewingIteration &&
    !partialChanges?.marketTrend &&
    viewingIteration.marketTrend !== currentProject.marketTrend

  const canApplyMarketGrowth =
    viewingIteration &&
    !partialChanges?.marketGrowth &&
    viewingIteration.marketGrowth !== currentProject.marketGrowth

  const canApplyMarketTam =
    viewingIteration &&
    !partialChanges?.marketTam &&
    viewingIteration.marketTam !== currentProject.marketTam

  return (
    <Panel title="Market">
      <Row>
        <TypeIndustryContainer>
          <LabelValueContainer>
            <IterationLabel
              title="Type"
              valueUpdated={canApplyType && isIterationNew}
              onAccept={() => {
                setPartialChanges({
                  ...partialChanges,
                  marketType: viewingIteration?.marketType,
                })
              }}
              onCancel={() =>
                setViewingIteration({
                  ...viewingIteration!!,
                  marketType: currentProject!!.marketType,
                })
              }
            />
            <MarketInput
              readOnly
              $highlight={canApplyType}
              value={partialChanges?.marketType ?? project?.marketType}
            />
          </LabelValueContainer>
          <LabelValueContainer>
            <IterationLabel
              title="Industry"
              valueUpdated={canApplyIndustry && isIterationNew}
              onAccept={() => {
                setPartialChanges({
                  ...partialChanges,
                  marketIndustry: viewingIteration?.marketIndustry,
                })
              }}
              onCancel={() =>
                setViewingIteration({
                  ...viewingIteration!!,
                  marketIndustry: currentProject!!.marketIndustry,
                })
              }
            />
            <MarketInput
              readOnly
              $highlight={canApplyIndustry}
              value={partialChanges?.marketIndustry ?? project?.marketIndustry}
            />
          </LabelValueContainer>
        </TypeIndustryContainer>
        <LabelValueContainer>
          <IterationLabel
            title="General Information"
            valueUpdated={canApplyGeneralInfo && isIterationNew}
            onAccept={() => {
              setPartialChanges({
                ...partialChanges,
                marketDetails: viewingIteration?.marketDetails,
              })
            }}
            onCancel={() =>
              setViewingIteration({
                ...viewingIteration!!,
                marketDetails: currentProject!!.marketDetails,
              })
            }
          />
          <MarketTextarea
            autoSize={{ minRows: 4, maxRows: 6 }}
            readOnly
            $highlight={canApplyGeneralInfo}
            value={partialChanges?.marketDetails ?? project?.marketDetails}
          />
        </LabelValueContainer>
        <LabelValueContainer>
          <IterationLabel
            title="Customer segmentation"
            valueUpdated={canApplyCustomerSegmentation && isIterationNew}
            onAccept={() => {
              setPartialChanges({
                ...partialChanges,
                marketCustomerSegmentation:
                  viewingIteration?.marketCustomerSegmentation,
              })
            }}
            onCancel={() =>
              setViewingIteration({
                ...viewingIteration!!,
                marketCustomerSegmentation:
                  currentProject!!.marketCustomerSegmentation,
              })
            }
          />
          <MarketTextarea
            autoSize={{ minRows: 4, maxRows: 6 }}
            readOnly
            $highlight={canApplyCustomerSegmentation}
            value={
              partialChanges?.marketCustomerSegmentation ??
              project?.marketCustomerSegmentation
            }
          />
        </LabelValueContainer>
      </Row>
      <Row>
        <LabelValueContainer>
          <IterationLabel
            title="Trend"
            valueUpdated={canApplyCustomerTrend && isIterationNew}
            onAccept={() => {
              setPartialChanges({
                ...partialChanges,
                marketTrend: viewingIteration?.marketTrend,
              })
            }}
            onCancel={() =>
              setViewingIteration({
                ...viewingIteration!!,
                marketTrend: currentProject!!.marketTrend,
              })
            }
          />
          <MarketTextarea
            readOnly
            $highlight={canApplyCustomerTrend}
            autoSize={{ minRows: 2 }}
            value={partialChanges?.marketTrend ?? project?.marketTrend}
          />
        </LabelValueContainer>
        <LabelValueContainer>
          <IterationLabel
            title="Growth"
            valueUpdated={canApplyMarketGrowth && isIterationNew}
            onAccept={() => {
              setPartialChanges({
                ...partialChanges,
                marketGrowth: viewingIteration?.marketGrowth,
              })
            }}
            onCancel={() =>
              setViewingIteration({
                ...viewingIteration!!,
                marketGrowth: currentProject!!.marketGrowth,
              })
            }
          />
          <MarketInput
            readOnly
            $highlight={canApplyMarketGrowth}
            value={partialChanges?.marketGrowth ?? project?.marketGrowth}
          />
        </LabelValueContainer>
        <LabelValueContainer>
          <IterationLabel
            title="Total Addressable Market (estimated)"
            valueUpdated={canApplyMarketTam && isIterationNew}
            onAccept={() => {
              setPartialChanges({
                ...partialChanges,
                marketTam: viewingIteration?.marketTam,
              })
            }}
            onCancel={() =>
              setViewingIteration({
                ...viewingIteration!!,
                marketTam: currentProject!!.marketTam,
              })
            }
          />
          <MarketInput
            readOnly
            $highlight={canApplyMarketTam}
            value={partialChanges?.marketTam ?? project?.marketTam}
          />
        </LabelValueContainer>
      </Row>
    </Panel>
  )
}

export default MarketPanel
