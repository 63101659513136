import { gql, useMutation } from '@apollo/client'
import { Account } from '../../types/account'

const registerFirebaseQuery = gql`
  mutation RegisterAccount($token: String!) {
    exchangeFirebaseToken(token: $token) {
      identifier
      name
      email
      avatar
      whitelisted
    }
  }
`

const useAccountRegistration = () => {
  const [mutate] = useMutation<{ exchangeFirebaseToken: Account }>(
    registerFirebaseQuery,
  )

  const exchangeFirebaseToken = (token: string) =>
    mutate({ variables: { token } }).then(
      ({ data }) => data!.exchangeFirebaseToken,
    )

  return { exchangeFirebaseToken }
}

export default useAccountRegistration
