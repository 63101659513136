import React, { useState } from 'react'
import Img from '../../assets/onboarding-img-organization.svg'
import OnboardingContainer from '../templates/onboarding'
import { useCreateOrganization } from '../../hooks/organization/useOrganization'
import { Space } from 'antd'
import { Input, InputLabel, InputLabelContainer } from '../atoms/input'
import Button from '../atoms/button'
import { useBoolean } from '../../hooks/useBoolean'
import Spacer from '../atoms/spacer'
import XhrPending from '../atoms/xhr-pending'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'

type Props = {
  onSuccess: () => Promise<void>
}

const OrganizationCreatePage: React.FC<Props> = ({ onSuccess }) => {
  useDocumentTitle('Create your organization')

  const [name, setName] = useState<string>()
  const [xhrPending, toggleXhrPending] = useBoolean()
  const createOrganization = useCreateOrganization()

  const canCreate = !!name

  const create = () => {
    if (canCreate) {
      toggleXhrPending()
      createOrganization({ name }).then(onSuccess).finally(toggleXhrPending)
    }
  }

  return (
    <XhrPending pending={xhrPending}>
      <OnboardingContainer title="Create your organization" image={Img}>
        <Spacer size="large" />
        <Space direction="vertical" size="large" style={{ width: '70%' }}>
          <InputLabelContainer>
            <InputLabel>Name</InputLabel>
            <Input
              placeholder="What's your company's name?"
              value={name}
              onChange={event => setName(event.target.value)}
              onPressEnter={create}
              maxLength={32}
              showCount
              allowClear
            />
          </InputLabelContainer>
          <Button
            analyticsId="create_organization"
            label="submit"
            onClick={create}
            disabled={!canCreate}
          />
        </Space>
      </OnboardingContainer>
    </XhrPending>
  )
}

export default OrganizationCreatePage
