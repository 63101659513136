import { Page, PageContent, PageHeader } from '../templates/page'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean } from '../../hooks/useBoolean'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { Alert, Col, Radio, Row, Select, Space, Spin } from 'antd'
import styled from 'styled-components'
import Spacer from '../atoms/spacer'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { StackLevel, Tool, ToolKind } from '../../types/tool'
import { useToolLanguages, useTools } from '../../hooks/tool/useTools'
import ToolCard from '../organism/tool-card'
import ExecutionDrawer from '../organism/tool-execution-drawer'
import ToolExecutionList from '../organism/tool-execution-list'
import {
  useToolExecutionFormFields,
  useRequiredPlanLevelBySection,
} from '../../hooks/tool/useToolExecutionFormFields'
import { useCreateToolExecution } from '../../hooks/tool/useToolExecution'
import { useOrganizationConnections } from '../../hooks/organization/useConnections'
import { ConnectionStatuses } from '../../types/connection'
import { ProjectContext } from '../../context/project-context'
import { OrganizationContext } from '../../context/organization-context'
import Title from 'antd/lib/typography/Title'
import Button from '../atoms/button'
import { useHistory } from 'react-router-dom'
import { FormInstance } from 'antd/lib/form'
import ProjectSelector from '../organism/project-selector'
import { InputLabelContainer } from '../atoms/input'
import { t } from 'i18next'
import { useQueryParam } from '../../hooks/useQueryParam'

const TopPadding = 32
const HeaderHeight = 46
const HeaderGap = 24

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  &::after {
    content: '';
    height: 24px;
    flex-shrink: 0; // To make sure it doesn't shrink
  }
`

const FixedHeader = styled.div`
  position: sticky;
  width: 100%;
  top: ${TopPadding}px;
  height: calc(${HeaderHeight}px + ${HeaderGap}px);
`

const FixedCol = styled(Col)`
  position: sticky;
  top: calc(${TopPadding}px + ${HeaderHeight}px + ${HeaderGap}px);
  height: calc(100vh - ${TopPadding}px - ${HeaderHeight}px - ${HeaderGap}px);
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
`

const ToolsPage = () => {
  useDocumentTitle('Toolbox')
  const [after, setAfter] = useQueryParam<number>('af')
  const [kind, setKind] = useQueryParam<ToolKind>('ki', ToolKind.ANY)
  const [stackLevels, setStackLevels] = useQueryParam<StackLevel[]>('sl', [
    StackLevel.ALL,
  ])
  const [languages, setLanguages] = useQueryParam<string[]>('pl', ['ALL'])
  const [executingToolId, setExecutingToolId] = useQueryParam<string>(
    'eti',
    undefined,
    true,
  )
  const [xhrPending, toggleXhrPending] = useBoolean()
  const [tools, setTools] = useState<Tool[]>([])
  const { loading, page } = useTools({
    kind: kind === 'ANY' ? undefined : kind,
    stackLevels: stackLevels.includes(StackLevel.ALL) ? undefined : stackLevels,
    languages: languages.includes('ALL') ? undefined : languages,
    after,
  })
  const { toolLanguages } = useToolLanguages()
  const hasNextPage = (page?.next ?? 0) > 0
  const { loading: loadingConnections, page: connections } =
    useOrganizationConnections({
      status: ConnectionStatuses.Active,
    })
  const { projects, project, setProjectId } = useContext(ProjectContext)
  const { membership } = useContext(OrganizationContext)
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setAfter(page?.next),
    disabled: !loading && !page,
    rootMargin: '0px 0px 400px 0px',
  })
  const [executingTool, setExecutingTool] = useState<Tool>()
  const [formState, setFormState] = useState<Record<string, unknown>>({})
  const {
    formFields,
    loading: formFieldsLoading,
    resetFormFields,
  } = useToolExecutionFormFields(executingTool?.identifier, formState)
  const {
    loading: requiredSectionsLoading,
    requiredPlanSections,
    resetRequiredPlanLevels,
  } = useRequiredPlanLevelBySection(executingTool?.identifier)
  const createExecution = useCreateToolExecution()
  const { push } = useHistory()
  const formRef = useRef<FormInstance>(null)
  const [executionName, setExecutionName] = useState<string>('')
  const hasConnections = useMemo(
    () => connections && connections.data.length > 0,
    [connections],
  )

  useEffect(() => {
    if (page) {
      const data = page?.data ?? []
      setTools(prev => (after ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (executingToolId) {
      const tool = tools.find(t => t.identifier === executingToolId)
      if (tool && canLaunch(tool)) {
        setExecutingTool(tool)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executingToolId, tools])

  useEffect(() => {
    if (executingTool && executingTool.identifier !== executingToolId) {
      setExecutingToolId(executingTool.identifier)
    } else if (!executingTool) {
      setExecutingToolId(undefined)
    }
    // eslint-disable-next-line
  }, [executingTool])

  const canLaunch = (tool: Tool) =>
    !!membership && membership.organization.plan.level >= tool.requiredPlanLevel

  const launch = (tool: Tool) => {
    if (canLaunch(tool)) setExecutingTool(tool)
  }
  const closeExecutionForm = () => {
    setExecutingTool(undefined)
    setFormState({})
    resetFormFields()
    resetRequiredPlanLevels()
  }

  const runExecution = (values: Record<string, unknown>) => {
    toggleXhrPending()
    createExecution(executingTool!.id, values, executionName)
      .then(result => {
        push(
          `/tools/activity?pro=${project?.id}&too=${executingTool?.id}&tei=${result.id}`,
        )
      })
      .finally(toggleXhrPending)
  }

  return (
    <Page>
      <FixedHeader>
        <PageHeader>
          <Title level={2}>Toolbox</Title>
          <Col span={6}>
            <ProjectSelector
              projects={projects}
              project={project}
              onProjectChange={setProjectId}
            />
          </Col>
        </PageHeader>
      </FixedHeader>
      {!loadingConnections && !hasConnections && (
        <Alert
          message="create a connection to deploy a tool"
          type="info"
          action={
            <Button
              analyticsId="create_connection"
              label="create"
              type="text"
              href="/organization/connections/new"
            />
          }
          style={{ width: '100%', margin: '24px 0' }}
        />
      )}
      <PageContent>
        <Row justify="space-between">
          {/* Left filters */}
          <FixedCol span={5}>
            <FilterContainer>
              <InputLabelContainer>
                <Title level={4}>Kind</Title>
                <Radio.Group
                  value={kind}
                  onChange={e => setKind(e.target.value)}
                >
                  <Space direction="vertical">
                    {Object.values(ToolKind).map(value => (
                      <Radio key={value} value={value}>
                        {t(`tool_kind_${value}`)}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </InputLabelContainer>
              {kind !== ToolKind.ADMINISTRATIVE && (
                <>
                  <InputLabelContainer>
                    <Title level={4}>Stack Level</Title>
                    <Select
                      mode="multiple"
                      showArrow
                      allowClear
                      value={stackLevels}
                      onChange={val => {
                        setStackLevels(prev => {
                          if (
                            prev.includes(StackLevel.ALL) &&
                            val.includes(StackLevel.ALL) &&
                            val.length > 1
                          ) {
                            return val.filter(v => v !== StackLevel.ALL)
                          }
                          // if new value contains ALL, remove all other values
                          if (val.includes(StackLevel.ALL)) {
                            return [StackLevel.ALL]
                          }
                          return val
                        })
                      }}
                      options={Object.values(StackLevel).map(value => ({
                        label: t(`stack_level_${value}`),
                        value,
                      }))}
                    />
                  </InputLabelContainer>
                  <InputLabelContainer>
                    <Title level={4}>Programming Languages</Title>
                    <Select
                      mode="multiple"
                      showArrow
                      allowClear
                      value={languages}
                      onChange={val => {
                        setLanguages(prev => {
                          if (
                            prev.includes('ALL') &&
                            val.includes('ALL') &&
                            val.length > 1
                          ) {
                            return val.filter(v => v !== 'ALL')
                          }
                          // if new value contains ALL, remove all other values
                          if (val.includes('ALL')) {
                            return ['ALL']
                          }
                          return val
                        })
                      }}
                      options={['ALL', ...(toolLanguages ?? [])].map(value => ({
                        label: t(`programming_language_${value}`),
                        value,
                      }))}
                    />
                  </InputLabelContainer>
                </>
              )}
            </FilterContainer>
          </FixedCol>
          {/* centered results */}
          <Col span={11}>
            <CardsContainer>
              {tools.map(tool => (
                <ToolCard
                  tool={tool}
                  onClickLaunch={launch}
                  key={tool.id}
                  atPlanLevel={canLaunch(tool)}
                />
              ))}
            </CardsContainer>
            {hasNextPage && <div ref={sentryRef} />}
            {loading && tools.length > 0 && (
              <Spin spinning>
                <Spacer />
              </Spin>
            )}
          </Col>
          {/* Right panel */}
          <FixedCol span={6}>
            <ToolExecutionList />
          </FixedCol>
        </Row>
        <ExecutionDrawer
          executingTool={executingTool}
          executionName={executionName}
          onExecutionNameChange={setExecutionName}
          onExecutionFormSubmit={() => formRef.current?.submit()}
          onCloseExecutionForm={closeExecutionForm}
          xhrPending={xhrPending}
          loading={formFieldsLoading || requiredSectionsLoading}
          formFields={formFields}
          formRef={formRef}
          requiredPlanSections={requiredPlanSections}
          onClickRunExecution={runExecution}
          formState={formState}
          onFormStateChange={setFormState}
        />
      </PageContent>
    </Page>
  )
}
export default ToolsPage
