import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithRedirect,
  User as FirebaseUser,
} from 'firebase/auth'
import { noop } from '../utils/utils'
import { AppContext } from './application-context'
import LoadingPage from '../components/pages/loading'
import useAccountRegistration from '../hooks/account/useAccount'
import { Account } from '../types/account'

type SecurityContextProps = {
  account?: Account
  token?: string
  login: () => void
  logout: () => void
}

export const SecurityContext = React.createContext<SecurityContextProps>({
  login: noop,
  logout: noop,
})

export const SecurityContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { firebase, google } = useContext(AppContext)
  const auth = getAuth(firebase)
  const [account, setAccount] = useState<Account>()
  const [token, setToken] = useState<string>()
  const [loading, setLoading] = useState(true)
  const { exchangeFirebaseToken } = useAccountRegistration()

  useEffect(() => {
    getRedirectResult(auth)
      .then(result =>
        result
          ? exchangeFirebaseToken(
              GoogleAuthProvider.credentialFromResult(result)?.idToken!!,
            )
          : Promise.resolve(undefined),
      )
      .then(setAccount)

    auth.onIdTokenChanged((u: FirebaseUser | null) => {
      if (u) {
        u.getIdToken()
          .then(t => {
            setToken(t)
            return !!account && !!token
              ? Promise.resolve()
              : exchangeFirebaseToken(t).then(setAccount)
          })
          .then(() => setLoading(false))
      } else setLoading(false)
    }, console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const login = () => signInWithRedirect(auth, google!.auth)
  const logout = () => {
    auth.signOut().then(() => setAccount(undefined))
  }

  return (
    <SecurityContext.Provider
      value={{ account: account, token, login, logout }}
    >
      {loading ? <LoadingPage /> : children}
    </SecurityContext.Provider>
  )
}
