import { Auditable } from './auditable'
import { PageableRequestFilter } from './pageable'

export type ConnectionProvider = 'GITHUB' | 'GCP' | 'AWS'

export enum ConnectionProviders {
  Github = 'GITHUB',
  Gcp = 'GCP',
  Aws = 'AWS',
}

export type ConnectionStatus = 'ACTIVE' | 'ARCHIVED'

export enum ConnectionStatuses {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export type OrganizationConnection = {
  id: string
  orgId: string
  name: string
  credentials: string
  provider: ConnectionProvider
  status: ConnectionStatus
} & Auditable

export type NewConnection = {
  name: string
  credentials: string
  provider: ConnectionProvider
  status?: ConnectionStatus
}

export type ConnectionsFilter = PageableRequestFilter & {
  provider?: ConnectionProvider
  status?: ConnectionStatus
}

export type ConnectionUpdate = {
  id: string
  name?: string
  credentials?: string
  provider?: ConnectionProvider
  status?: ConnectionStatus
}

export type AwsCredentials = {
  region: string
  accessKey: string
  secretAccessKey: string
}
