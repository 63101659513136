import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import Button from '../atoms/button'
import { HistoryIcon, SendIcon } from '../atoms/icon'
import { Input } from '../atoms/input'
import useInput from '../../hooks/useInput'

type Props = {
  onClickSubmitPrompt: (prompt: string) => void
  onClickHistory: () => void
  disabled: boolean
}

const Container = styled(Row)`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #8996da;
  padding: 4px;
`

const StyledButton = styled(Button)`
  background: #161825;
  border: 1px solid #ffc107;
  text-align: center;
  max-height: 100% !important;
  padding: 10px;
`

const StyledInput = styled(Input)`
  padding: 7px 22px;
  border-color: #ffc107;
  background: #161825;
  font-size: 14px;
`

const ChatBar: React.FC<Props> = ({
  onClickSubmitPrompt,
  onClickHistory,
  disabled,
}) => {
  const [query, handleQueryChange, setQueryValue] = useInput<string>()

  const submitPrompt = () => {
    if (query) {
      onClickSubmitPrompt(query)
      setQueryValue('')
    }
  }

  return (
    <Container align="middle" justify="center" gutter={12}>
      <Col span={8}>
        <StyledInput
          value={query}
          onChange={handleQueryChange}
          placeholder="Need a tweak or dive deeper? Guide us here..."
          allowClear
          onPressEnter={submitPrompt}
          disabled={disabled}
        />
      </Col>
      <Col>
        <StyledButton
          analyticsId="chat_bar_send_prompt"
          icon={<SendIcon style={{ fontSize: 18, color: 'white' }} />}
          onClick={submitPrompt}
        />
      </Col>
      <Col>
        <StyledButton
          darkLabel
          analyticsId="chat_bar_open_history"
          icon={<HistoryIcon style={{ fontSize: 20, color: 'white' }} />}
          onClick={onClickHistory}
        />
      </Col>
    </Container>
  )
}

export default ChatBar
