import { useContext } from 'react'
import { SecurityContext } from '../../context/security-context'
import OnboardingContainer from '../templates/onboarding'
import Img from '../../assets/onboarding-img-login.svg'
import Button from '../atoms/button'
import Title from 'antd/lib/typography/Title'
import { Space, Typography } from 'antd'
import { t } from 'i18next'

const NotWhitelistedPage = () => {
  const { logout } = useContext(SecurityContext)

  return (
    <OnboardingContainer image={Img} title={t('not_whitelisted_welcome')}>
      <Space direction="vertical" size="large">
        <Title>{t('not_whitelisted_waiting_list')}</Title>
        <Typography.Text>{t('not_whitelisted_notify')}</Typography.Text>
        <Typography.Text>{t('not_whitelist_thanks')}</Typography.Text>
        <Button
          analyticsId="logout_not_whitelisted"
          onClick={logout}
          label="logout"
        />
      </Space>
    </OnboardingContainer>
  )
}

export default NotWhitelistedPage
