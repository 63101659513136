import Configuration from '../types/configuration'
import { gql, useQuery } from '@apollo/client'
import { useErrorHandler } from 'react-error-boundary'

const query = gql`
  query Configuration {
    configuration {
      firebase {
        apiKey
        appId
        authDomain
        messagingSenderId
        projectId
        storageBucket
      }
      googleAuth {
        scopes
      }
    }
  }
`

const useConfiguration = () => {
  const { loading, data, error } = useQuery<{ configuration: Configuration }>(
    query,
  )
  useErrorHandler(error?.graphQLErrors[0])
  return { loading, ...data?.configuration }
}

export default useConfiguration
