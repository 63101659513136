import { RouteGuard, RouteGuardProps } from './route-guard'
import { UnauthorizedPage } from '../../components/pages/unauthorized'
import { Role } from '../../types/role'
import useRoleChecker from '../../hooks/useRoleChecker'

export const mustHave = (...roles: Role[]): RouteGuard<Role[]> => ({
  params: roles,
  useFilter: useStrictRoleAwareGuard,
})

export const shouldHaveOneOf = (...roles: Role[]): RouteGuard<Role[]> => ({
  params: roles,
  useFilter: useRoleAwareGuard,
})

const useStrictRoleAwareGuard = (allowedRoles: Role[]): RouteGuardProps => {
  const authorized = useRoleChecker(true, ...allowedRoles)

  return { authorized, fallback: UnauthorizedPage }
}

const useRoleAwareGuard = (allowedRoles: Role[]): RouteGuardProps => {
  const authorized = useRoleChecker(false, ...allowedRoles)

  return { authorized, fallback: UnauthorizedPage }
}
