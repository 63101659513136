import React from 'react'
import styled from 'styled-components'
import { ToolExecution, ToolExecutionStatuses } from '../../types/tool'
import { Col, Popover, Row } from 'antd'
import { StopWatchIcon, VerticalDotsIcon } from '../atoms/icon'
import { iconByStatus } from '../organism/tool-execution-list'
import Title from 'antd/lib/typography/Title'
import TimeLabel from '../molecules/time-label'
import ExecutionPopupContent from './tool-execution-popup-content'
import moment from 'moment-timezone'

type Props = {
  execution: ToolExecution
  onClickRerun: (execution: ToolExecution) => void
}

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ExecutionHeader: React.FC<Props> = ({ execution, onClickRerun }) => {
  return (
    <Row justify="space-between" align="middle">
      <Col span={8}>
        <Row gutter={16} align="top">
          <Col>{iconByStatus[execution.status]}</Col>
          <Col>
            <Title level={4}>{execution.name}</Title>
            <TimeLabel
              timestamp={execution.updatedAt}
              executorName={execution.createdBy as string}
            />
          </Col>
        </Row>
      </Col>
      <TimeContainer>
        {execution.status !== ToolExecutionStatuses.Running && (
          <StopWatchIcon style={{ fontSize: 20 }} />
        )}
        <Title level={5}>
          {moment
            .duration(
              moment(execution.updatedAt).diff(moment(execution.createdAt)),
            )
            .humanize()}
        </Title>
      </TimeContainer>
      <Popover
        placement="bottomRight"
        content={
          <ExecutionPopupContent
            execution={execution}
            onClickRerun={onClickRerun}
          />
        }
        trigger="click"
        color="#1d2031"
      >
        <div
          onClick={event => {
            event.stopPropagation()
          }}
        >
          <VerticalDotsIcon />
        </div>
      </Popover>
    </Row>
  )
}

export default ExecutionHeader
